import { styled, Tab, Tabs } from "@mui/material"
import { colors } from "../../../../utils"

export const TabsMenu = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${colors.black};
    border-radius: 4px;
  }
`

export const TabOption = styled(Tab)`
  border-radius: 4px;
  min-width: 80px;
  color: ${colors.black3};
  font-size: 14px;

  &.Mui-selected {
    color: ${colors.black};
    font-weight: 600;
  }
`
