import JSZip from "jszip";

export async function fetchFile(link: string) {
    const response = await fetch(link);
    const blob = await response.blob();
    const zipFile = await JSZip.loadAsync(blob);

    return zipFile;
};

export async function initFieldMap(zipFile: JSZip) {
    const xmlFile = await zipFile.file("word/document.xml")?.async("text");
    const map: Record<string, string | null> = {}
    if (xmlFile) {
        // Regex to match parts that begin with < or {{ and end with > or }}
        const regexParts = /&lt;(.*?)&gt;|{{(.*?)}}/g
        // Regex to find and remove everything between </w:t> and <w:t>, including nested tags
        const regexRemoveBetween = /<\/w:t>.*?<w:t>/g
    
        let match;
        while ((match = regexParts.exec(xmlFile)) !== null) {
            const part = match[1] !== undefined ? match[1] : match[2];
    
            if(part !== undefined) {
                const cleanedPart = part.replace(regexRemoveBetween, "<w:t>")
                const extractedText = cleanedPart.replace(/<\/?w:t>/g, "").trim()
        
                map[extractedText] = ""
            }
        }
    }
    
    return map;
}
