import { Box, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IContainerProps {
  expanded: boolean
}

export const ContainerBox = styled(Box)<IContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${colors.gray13};
`
