import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Box,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Collapse,
  IconButton,
  TableFooter,
  Button,
  TableSortLabel,
} from "@mui/material"
import { CalendarMonth } from "@mui/icons-material"
import { useQuery } from "@tanstack/react-query"
import { getWorkflowDefinitionAPI, getWorkflowsAPI } from "../../../../services"
import { colors, getComparator, stableSort } from "../../../../utils"
import dayjs from "dayjs"
import { WorkflowStatusChip } from "../../../../components"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined"
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined"
import { useNavigate } from "react-router-dom"
import { type Node, useEdgesState, useNodesState } from "reactflow"
import {
  CollapsibleRow,
  CustomRow,
  CustomRowHeader,
  EmptyRow,
  EnhancedTable,
  EnhancedTableContainer,
  NoDataRow,
} from "./styled"
import { useTranslation } from "react-i18next"
import { type IFilters } from "../../utils"
import {
  CanvasMap,
  ExecutionLogsTable,
} from "../../../WorkflowPreview/components"
import {
  createEdges,
  getTransformedLayout,
  transformStepToNode,
} from "../../../WorkflowPreview/utils"

interface IProps {
  searchKey: string
  sort?: string
  filters: IFilters
}

type TTab = "execution_logs" | "workflow_preview"

export const WorkflowsTable = (props: IProps) => {
  const { searchKey, filters } = props
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [expandedWorkflow, setExpandedWorkflow] = useState<string | undefined>()
  const [selectedTab, setSelectedTab] = useState<TTab>("execution_logs")
  const navigate = useNavigate()
  const [definitionId, setDefinitionId] = useState("")
  const [workflowId, setWorkflowId] = useState("")
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [sortBy, setSortBy] = useState("createTime")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

  const {
    data: workflows,
    isPending: isWorkflowsPending,
    isRefetching: isWorkflowsRefetching,
  } = useQuery({
    queryKey: ["workflows-listing"],
    queryFn: () => getWorkflowsAPI(),
  })

  const isDataLoading = isWorkflowsPending || isWorkflowsRefetching

  const filteredData = useMemo(() => {
    if (workflows) {
      const filtered = workflows.filter(
        (w) =>
          !(
            (filters.name &&
              !w.workflowDefinitionId
                ?.toLowerCase()
                ?.includes(filters.name?.toLowerCase())) ||
            (filters.caseNumber &&
              !w.data?.CaseNumber?.toLowerCase()?.includes(
                filters.caseNumber?.toLowerCase(),
              )) ||
            (filters.createdBy &&
              !w.workflowDefinitionId
                ?.toLowerCase()
                ?.includes(filters.createdBy?.toLowerCase())) ||
            (filters.createdStartDate &&
              filters.createdEndDate &&
              (dayjs(w.createTime).isBefore(dayjs(filters.createdStartDate)) ||
                dayjs(w.createTime).isAfter(dayjs(filters.createdEndDate)))) ||
            (filters.createdStartDate &&
              !filters.createdEndDate &&
              !dayjs(w.createTime).isAfter(dayjs(filters.createdStartDate))) ||
            (filters.createdEndDate &&
              !filters.createdStartDate &&
              !dayjs(w.createTime).isBefore(dayjs(filters.createdEndDate))) ||
            (filters.status.length > 0 &&
              !filters.status.some((s) => w.status === s)) ||
            (searchKey &&
              !w.workflowDefinitionId
                ?.toLowerCase()
                ?.includes(searchKey?.toLowerCase()) &&
              !w.data?.CaseNumber?.toLowerCase()?.includes(
                searchKey?.toLowerCase(),
              ))
          ),
      )

      return stableSort(
        filtered,
        getComparator(sortOrder, sortBy || "createTime"),
      )
    }
    return []
  }, [workflows, searchKey, sortOrder, sortBy, filters, page, rowsPerPage])

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage)
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onRowClick = (workflow: any) => {
    setExpandedWorkflow(
      expandedWorkflow === workflow.id ? undefined : workflow.id,
    )
    setDefinitionId(workflow.workflowDefinitionId)
    setWorkflowId(workflow.id)
  }

  const onTabClick = (tab: TTab) => setSelectedTab(tab)

  const collapseRow = useCallback(() => {
    setExpandedWorkflow(undefined)
  }, [])

  const { data: workflowDefinitionData } = useQuery({
    queryKey: ["workflow-instance-definition", definitionId],
    queryFn: () => getWorkflowDefinitionAPI(definitionId),
    enabled: !!definitionId,
    refetchOnMount: true,
  })

  const initialNodes = useMemo(() => {
    const nodes: Node[] = []

    const workflow = workflows?.find((value) => value.id === workflowId)

    const executionPointers = workflow?.executionPointers

    workflowDefinitionData?.steps?.forEach((step) => {
      nodes.push(...transformStepToNode(step, executionPointers))
    })

    return nodes
  }, [workflowDefinitionData, workflows, workflowId])

  const initialEdges = useMemo(() => {
    if (workflowDefinitionData?.steps)
      return createEdges(workflowDefinitionData?.steps)
  }, [workflowDefinitionData])

  const handleSort = useCallback(
    (column: string) => {
      setSortBy(column)
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    },
    [sortOrder],
  )

  useEffect(() => {
    if (initialNodes && initialEdges) {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getTransformedLayout(initialNodes, initialEdges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  return (
    <Box paddingBottom="24px" display="grid">
      <EnhancedTableContainer>
        <EnhancedTable>
          <TableHead onClick={collapseRow}>
            <CustomRowHeader>
              <TableCell width="15%">
                <TableSortLabel
                  active={sortBy === "createTime"}
                  direction={sortOrder}
                  onClick={() => handleSort("createTime")}
                >
                  {t("date")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="15%">{t("time")}</TableCell>
              <TableCell width="20%">
                <TableSortLabel
                  active={sortBy === "data.CaseNumber"}
                  direction={sortOrder}
                  onClick={() => handleSort("data.CaseNumber")}
                >
                  {t("caseNumber")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="20%">
                <TableSortLabel
                  active={sortBy === "workflowDefinitionId"}
                  direction={sortOrder}
                  onClick={() => handleSort("workflowDefinitionId")}
                >
                  {t("workflowId")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="5%">
                <TableSortLabel
                  active={sortBy === "version"}
                  direction={sortOrder}
                  onClick={() => handleSort("version")}
                >
                  {t("version")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="20%">
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sortOrder}
                  onClick={() => handleSort("status")}
                >
                  {t("status")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="5%">{t("duration")}</TableCell>
            </CustomRowHeader>
          </TableHead>
          <TableBody>
            {isDataLoading ? (
              <CustomRow open={false} className="disabled">
                {Array.from({ length: 7 }).map((_, index) => (
                  <TableCell key={index}>
                    <Box padding="8px">
                      <Skeleton />
                    </Box>
                  </TableCell>
                ))}
              </CustomRow>
            ) : filteredData.length === 0 ? (
              <NoDataRow>
                <TableCell align="center" colSpan={7}>
                  <Typography>{t("noData")}</Typography>
                </TableCell>
              </NoDataRow>
            ) : (
              <>
                <EmptyRow>
                  <TableCell colSpan={7} height="16px" />
                </EmptyRow>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((workflow: any, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CustomRow
                          open={expandedWorkflow === workflow.id}
                          onClick={() => onRowClick(workflow)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>
                            <Box padding="8px 6px">
                              <IconButton>
                                <CalendarMonth />
                              </IconButton>
                              {new Date(workflow.createTime).toLocaleDateString(
                                "de-DE",
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box padding="8px 6px">
                              {new Date(workflow.createTime).toLocaleTimeString(
                                "en-US",
                                { hour12: true },
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box padding="8px 6px">
                              {workflow.data?.CaseNumber || "--"}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box padding="8px 6px">
                              {workflow.workflowDefinitionId}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box padding="8px 6px">{workflow.version}</Box>
                          </TableCell>
                          <TableCell>
                            <Box padding="8px 6px" minWidth="80%" width="60%">
                              <WorkflowStatusChip status={workflow.status} />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box padding="8px 6px">
                              {workflow.completeTime
                                ? `${
                                    (new Date(workflow.completeTime).getTime() -
                                      new Date(workflow.createTime).getTime()) /
                                    1000
                                  } sec`
                                : "--"}
                            </Box>
                          </TableCell>
                        </CustomRow>

                        <CollapsibleRow open={expandedWorkflow === workflow.id}>
                          <TableCell
                            colSpan={7}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse
                              in={expandedWorkflow === workflow.id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <Box
                                  marginBottom="24px"
                                  paddingBottom="16px"
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                  borderBottom={`1px solid ${colors.gray4}`}
                                >
                                  <Typography
                                    flex={1}
                                    paddingRight="24px"
                                    variant="regularSemiBold"
                                    color={colors.black4}
                                  >
                                    {selectedTab === "execution_logs"
                                      ? t("executionLogs")
                                      : t("stepsPreview")}
                                  </Typography>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="12px"
                                  >
                                    <IconButton
                                      style={{ padding: "4px" }}
                                      onClick={() =>
                                        onTabClick("execution_logs")
                                      }
                                    >
                                      {selectedTab === "execution_logs" ? (
                                        <ListAltOutlinedIcon color="primary" />
                                      ) : (
                                        <ListAltOutlinedIcon />
                                      )}
                                    </IconButton>
                                    <IconButton
                                      style={{ padding: "4px" }}
                                      onClick={() =>
                                        onTabClick("workflow_preview")
                                      }
                                    >
                                      {selectedTab === "workflow_preview" ? (
                                        <AccountTreeOutlinedIcon color="primary" />
                                      ) : (
                                        <AccountTreeOutlinedIcon />
                                      )}
                                    </IconButton>

                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/workflow-preview/${workflow.id}/${workflow.workflowDefinitionId}`,
                                        )
                                      }
                                    >
                                      <LaunchOutlinedIcon />
                                    </IconButton>

                                    <Box marginLeft="24px" />
                                  </Box>
                                  <Button onClick={collapseRow} size="small">
                                    {t("close")}
                                  </Button>
                                </Box>

                                {selectedTab === "execution_logs" && (
                                  <ExecutionLogsTable
                                    executionPointers={
                                      workflow.executionPointers
                                    }
                                  />
                                )}

                                {selectedTab === "workflow_preview" && (
                                  <Box width="100%" height="400px">
                                    <CanvasMap
                                      nodeOptions={{
                                        nodes,
                                        onNodesChange,
                                        setNodes,
                                      }}
                                      edgeOptions={{
                                        edges,
                                        onEdgesChange,
                                        setEdges,
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </CollapsibleRow>

                        <EmptyRow>
                          <TableCell colSpan={7} height="16px" />
                        </EmptyRow>
                      </React.Fragment>
                    )
                  })}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                onClick={collapseRow}
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </EnhancedTable>
      </EnhancedTableContainer>
    </Box>
  )
}
