import { styled, Box } from "@mui/material"

export const FormsContainer = styled(Box)`
  display: flex;
  overflow: auto;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`
