import { colors } from "../../../../utils"

export const getExecutionStatusName = (status: number) => {
  switch (status) {
    case 0:
      return "legacy"
    case 1:
      return "pending"
    case 2:
      return "running"
    case 3:
      return "completed"
    case 4:
      return "sleeping"
    case 5:
      return "waitingForEvent"
    case 6:
      return "failed"
    case 7:
      return "compensated"
    case 8:
      return "canceled"
    case 9:
      return "pendingPredecessor"
    default:
      return ""
  }
}

export const getExecutionPointerStatusBackground = (status: number) => {
  switch (status) {
    case 0:
      return colors.gray2
    case 1:
      return colors.yellow4
    case 2:
      return colors.blue6
    case 3:
      return colors.green3
    case 4:
      return colors.blue2
    case 5:
      return colors.blue6
    case 6:
      return colors.red3
    case 7:
      return colors.gray
    case 8:
      return colors.yellow4
    default:
      return colors.gray4
  }
}
export const getExecutionPointerStatusColor = (status: number) => {
  switch (status) {
    case 0:
      return colors.blue4
    case 1:
      return colors.yellow2
    case 2:
      return colors.primary
    case 3:
      return colors.greenDot
    case 4:
      return colors.blue4
    case 5:
      return colors.primary
    case 6:
      return colors.red
    case 7:
      return colors.grayDot
    case 8:
      return colors.red2
    case 9:
      return colors.gray3
    default:
      return colors.gray3
  }
}
