import {
  Autocomplete,
  Dialog,
  DialogContent,
  styled,
  TextField,
} from "@mui/material"

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
    max-width: 100%;
    height: 90%;
    max-height: 90%;

    @media (max-width: 600px) {
      max-height: 100%;
    }
  }
`

export const CustomDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-height: 100%;
  padding: 12px;
`

export const FullHeightAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    max-height: 50px;
  }

  .MuiInputBase-root {
    max-height: 50px;
    padding: 0;
  }

  .MuiOutlinedInput-input {
    padding: 10px;
  }
`

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    height: "30px",
  },
  "& .MuiFilledInput-input": {
    paddingTop: "4px",
    fontSize: "16px",
  },
}))
