import { Box, Collapse, Radio, Tooltip, Typography } from "@mui/material"
import { colors, getNestedProperty } from "../../../../utils"
import { ContainerBox } from "./styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { SubsectionItem } from "../SubsectionItem"
import { type ISectionItemProps } from "../../types"
import { AddSubsectionItem } from "../AddSubsectionItem"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mui/material/Icon"
import dayjs from "dayjs"

export const SectionItem = (props: ISectionItemProps) => {
  const {
    claim,
    formMethods,
    subsectionsAdded,
    section,
    selectedSection,
    disabled,
    onSectionClick,
    onAddSubsectionClick,
  } = props
  const { t } = useTranslation()

  const isExpanded = selectedSection?.id === section?.id

  const hasError = useCallback(
    (subsection: IClaimTemplateSection) =>
      !!subsection?.metadatas?.some(
        (m) => !!getNestedProperty(formMethods.formState.errors, m.controlName),
      ),
    [formMethods?.formState?.errors],
  )

  const shouldDisable = useMemo(
    () => !!section?.isDocumentSection && !formMethods?.formState?.isValid,
    [section?.isDocumentSection, formMethods?.formState?.isValid],
  )

  const mapNestedValue = useCallback(
    (control: string) => {
      const value = getNestedProperty(claim?.claimDetails, control)
      return value != null ?
      !!value && typeof value === "object"
        ? Object.values(value)?.join(", ")
        : dayjs(value).isValid()
        ? dayjs(value).format("DD.MM.YYYY")
        : value
        : null
    },
    [claim?.claimDetails, getNestedProperty],
  )

  const infoItems = useMemo(
    () =>
      section?.info
        ?.map((i) => {
          let value = ""
          if (i?.controlName?.includes(";")) {
            const controls = i.controlName.split(";")
            value = controls?.map((c) => mapNestedValue(c))?.join(" ")
          } else {
            value = mapNestedValue(i?.controlName)
          }
          return { ...i, value }
        })
        ?.filter((i) => !!i?.value?.trim()),
    [section, mapNestedValue],
  )

  return (
    <Tooltip title={shouldDisable ? t("disabledStepMessage") : undefined}>
      <Box component="span">
        <ContainerBox
          expanded={isExpanded}
          className={shouldDisable ? "pointer-events-none" : ""}
        >
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            borderRadius="8px 8px 0px 0px"
            bgcolor={
              shouldDisable
                ? colors.secondary
                : isExpanded
                ? colors.gray8
                : colors.transparent
            }
            padding="24px"
            className={shouldDisable ? "disabled" : "pointer"}
            onClick={() => onSectionClick?.(section)}
          >
            <Radio size="small" checked={isExpanded} />
            <Box
              display="flex"
              alignItems="center"
              paddingLeft="24px"
              gap="24px"
              flex={1}
            >
              <Box flex={0.3}>
                <Typography variant="regularMedium">{section.name}</Typography>
              </Box>
              {!!infoItems?.length && (
                <Box
                  flex={0.7}
                  display="flex"
                  flexWrap="wrap"
                  gap="15%"
                  paddingX="24px"
                >
                  {infoItems?.map((i, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="4px"
                    >
                      <Icon sx={{ color: colors.black2 }} fontSize="small">
                        {i.icon}
                      </Icon>
                      <Typography variant="regularMedium">{i.value}</Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>

          <Collapse
            in={isExpanded}
            timeout="auto"
            // unmountOnExit
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              padding="24px"
            >
              {section.subSections.map((s) => {
                const isAdded = subsectionsAdded?.includes(s.id)
                return (
                  <Box id={s.id} key={s.id}>
                    <Box display={!isAdded ? "block" : "none"}>
                      <AddSubsectionItem
                        key={s.id}
                        subsection={s}
                        onClick={onAddSubsectionClick}
                        disabled={disabled}
                        error={hasError(s)}
                      />
                    </Box>
                    <Box display={isAdded ? "block" : "none"}>
                      <SubsectionItem key={s.id} {...props} subsection={s} />
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Collapse>
        </ContainerBox>
      </Box>
    </Tooltip>
  )
}
