import { Tooltip, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { ContainerBox } from "./styled"
import { colors } from "../../../../utils"

interface IProps {
  subsection: IClaimTemplateSection
  onClick?: (subsection: IClaimTemplateSection) => void
  disabled?: boolean
  error?: boolean
}

export const AddSubsectionItem = (props: IProps) => {
  const { subsection, onClick, disabled, error } = props

  return (
    <ContainerBox
      onClick={() => onClick?.(subsection)}
      disabled={disabled}
      error={error}
    >
      <AddIcon color={disabled ? "disabled" : "primary"} />
      <Typography paddingLeft="16px" flex={1} variant="regularMedium">
        {subsection.name}
      </Typography>
      {!!subsection?.description && (
        <Tooltip title={subsection.description}>
          <InfoOutlinedIcon fontSize="small" htmlColor={colors.gray3} />
        </Tooltip>
      )}
    </ContainerBox>
  )
}
