import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"
import { TabMenu, TabPanel } from "./components"
import { useCallback, useState } from "react"

export const GlobalInboxPage = () => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState("global")

  const handleTabChange = useCallback((newTab: string) => {
    setActiveTab(newTab)
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        justifySelf="center"
      >
        <Box
          display="flex"
          alignItems="center"
          marginBottom="12px"
          padding="0 24px"
          paddingTop="24px"
          gap="12px"
        >
          <Typography variant="h4" marginRight="auto">
            {t("globalInbox")}
          </Typography>
          <Button onClick={() => {}}>{t("export")}</Button>
        </Box>
        <TabMenu activeTab={activeTab} onTabChange={handleTabChange} />
        <TabPanel activeTab={activeTab} />
      </Box>
    </Box>
  )
}
