import { useEffect, useMemo, useState } from "react"
import { Controller, type UseFormReturn, type Control, type FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { fetchFile, initFieldMap } from "./utils"
import { Box, Button, Typography } from "@mui/material"
import { Description } from "@mui/icons-material"
import { WordTemplateModal } from "./WordTemplateModal"
import MenuItemFileUpload from "./MenuItemFileUpload"
import { useToast } from "../../../../../../contexts"
import { getTemplateList } from "../../../../../../services/workflow"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { colors } from "../../../../../../utils"
import { getGetDataStepData } from "../../utils"
import { type Node } from "reactflow"

interface ITemplateProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string | Record<any, any>,
    key: string,
    onChange: (input: any) => void,
  ) => void
  control: Control<FieldValues, any>
  selectedAction: INodeItem
  nodes: Node[]
  methods: UseFormReturn<FieldValues, any, undefined>
}

export const TemplateConfig = (props: ITemplateProps) => {
  const { handleChange, control, selectedAction, nodes } = props
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const [fieldMap, setFieldMap] = useState<Record<string, string | null>>({})
  const [changedFieldMap, setChangedFieldMap] = useState<Record<string, string | null>>({})
  const toast = useToast()

  const BASE_TEMPLATE_URL = "https://westdevelopment.blob.core.windows.net/workflow-templates/"

  const getStepData = getGetDataStepData(nodes).inputs

  const dummyFields: Record<string, boolean> = useMemo(() => {
      let selFields = getStepData?.SelectedFields
      let dummyFields = getStepData?.JSONResponseSchema

      selFields = Object.keys(selFields || {}).reduce(
        (acc: Record<string, boolean>, key: string) => {
          acc[key] = selFields[key] === "true" || selFields[key] === true
          return acc
        },
        {},
      )
      if (selFields && dummyFields) {
        const filteredFields: Record<string, object> = {}
        Object.keys(dummyFields).forEach(key => {
          if (selFields[key]) {
              filteredFields[key] = dummyFields[key]
          }
        })
        
        if (Object.keys(filteredFields).length > 0) 
          dummyFields = filteredFields

        return dummyFields
      }
  }, [nodes])

  const fileUrl = useMemo(
    () => {
      return getStepData?.TemplateFileUrl?.slice(1, -1)?.replace(/'/g, "") ?? ""
    },[nodes])

  const templateFields = useMemo(
    () =>
      getStepData?.TemplateFields
        ? typeof(getStepData?.TemplateFields) === "string" ? JSON.parse(getStepData?.TemplateFields?.slice(1, -1)) : getStepData?.TemplateFields : {}
    ,[nodes])

  const [templateUrl, setTemplateUrl] = useState(fileUrl)
  const [document, updateDocument] = useState([{ uri: templateUrl }])
  const [templateName, setTemplateName] = useState(
    templateUrl.split("/").pop() || "",
  )

  const {
    data: templates,
    // isLoading: areTemplatesLoading,
    // refetch: refetchTemplates,
  } = useQuery({
    queryKey: ["workflow-file-templates"],
    queryFn: () => getTemplateList(),
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    fetchFile(templateUrl)
      .then((zip) => {
        initFieldMap(zip)
          .then((newMap) => {
            setFieldMap(newMap)
            setChangedFieldMap(newMap)
            updateDocument([{ uri: templateUrl }])
            setTemplateName(templateUrl.split("/").pop() || "")
          })
          .catch((error) => {
            toast.show(`Failed to extract placeholders: ${error}`, "error")
          })
      })
      .catch((error) => {
        toast.show(error.message || t("failedLoadingFile"), "error")
      })
  }, [templateUrl])

  const handleSave = () => {
    handleChange(
      selectedAction,
      "input",
      changedFieldMap,
      "TemplateFields",
      () => {},
    )
  }

  const handleUpload = (link: string) => {
    handleChange(
      selectedAction,
      "input",
      "'" + link + "'",
      "TemplateFileUrl",
      () => {},
    )
    setTemplateName(link.split("/").pop() || "")
    setTemplateUrl(link)
  }

  const handleTemplateSelect = (name: string) => {
    handleUpload(BASE_TEMPLATE_URL.concat(name))
  }

  const handleFileUpload = (link: string) => {
    const name = link.split("/").pop() || ""
    uploadMutation.mutate(decodeURIComponent(name))
  }

  const uploadMutation = useMutation({
    mutationFn: (name: string) => {
      return new Promise<string>((resolve) => {
        handleUpload(name)
        resolve(name)
      })
    },
    onSuccess: (newName) => {
      queryClient.setQueryData(
        ["workflow-file-templates"],
        (oldTemplates: any[] = []) => {
          return [newName, ...oldTemplates]
        },
      )
    },
  })

  return (
    <>
      {Object.keys(fieldMap).length === 0 && (
        <Typography color="error">{t("noTemplateError")}</Typography>
      )}
      {!dummyFields && (
        <Typography color="error">{t("noDummyDataError")}</Typography>
      )}
      <Typography sx={{ textAlign: "center" }} variant="largeMedium">
        {t("selectTemplateType")}
      </Typography>
      <Controller
        control={control}
        name="TemplateType"
        render={() => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3.5,
              justifyContent: "start",
              padding: 2,
            }}
          >
            <Box
              sx={{
                padding: 0,
                borderRadius: "8px",
                textTransform: "none",
                transition: "transform 0.2s, background-color 0.2s",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "180px",
                height: "200px",
                border: "1px solid #ddd",
              }}
            >
              <MenuItemFileUpload
                sx={{ height: "100%" }}
                allowMultiple={false}
                allowedExtensions={[".docx"]}
                handleUpload={handleFileUpload}
              />
            </Box>
            {templates?.map((name: any, index: number) => (
              <Box key={index}>
                <Button
                  onClick={() => handleTemplateSelect(name)}
                  sx={{
                    padding: 0,
                    borderRadius: "8px",
                    textTransform: "none",
                    backgroundColor: templateName.includes(name)
                      ? colors.blue6
                      : "#f9f9f9",
                    transition: "transform 0.2s, background-color 0.2s",
                    "&:hover": {
                      transform: "scale(1.009)",
                      backgroundColor: templateName.includes(name)
                        ? colors.blue7
                        : "#f0f0f0",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "180px",
                    height: "200px",
                    border: "1px solid #ddd",
                  }}
                >
                  <Description
                    style={{ fontSize: "70px", color: colors.primary }}
                  />
                  <Typography
                    variant="smallBold"
                    sx={{ marginTop: 1, wordWrap: "break-word" }}
                  >
                    {name}
                  </Typography>
                </Button>
                {templateName.includes(name) && (
                  <Button
                    size="small"
                    sx={{ marginTop: 1 }}
                    onClick={() => setShowDialog(!showDialog)}
                    fullWidth
                    disabled={Object.keys(fieldMap).length === 0 || !dummyFields}
                  >
                    {t("editTemplateFields")}
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        )}
      />
      {showDialog && (
        <WordTemplateModal
          open={showDialog}
          data={{
            changedFieldMap,
            templateFields,
            document,
            dummyFields,
            setChangedFieldMap,
          }}
          onUpdateFields={handleSave}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  )
}
