import { isNumber } from "lodash"
import { formatNumber } from "../../utils/functions"
import { CustomNumberField } from "./styled"

interface IProps {
  value: string
  allowNegative?: boolean
  onChange: (value: string) => void
}

export const NumberFieldInput = (props: IProps) => {
  const { value, allowNegative = false, onChange } = props

  const formatInput = (input: string) => {
    if (!input) {
      return ""
    }

    let numericValue = input.toString().replace(/[^\d,-]/g, "")

    if (allowNegative) {
      numericValue = numericValue.replace(/(?!^-)-/g, "")
    } else {
      numericValue = numericValue.replace(/-/g, "")
    }

    const commaCount = (numericValue.match(/,/g) || []).length
    if (commaCount > 1) {
      numericValue = numericValue.replace(/,/g, (match, offset) =>
        offset === numericValue.indexOf(",") ? match : "",
      )
    }

    const formattedValue = numericValue
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      .replace(/,(\d{2})\d+/g, ",$1")

    return formattedValue
  }

  return (
    <CustomNumberField
      value={
        isNumber(value) ? formatNumber(parseFloat(value)) : formatInput(value)
      }
      onChange={(event) => {
        const newValue = formatInput(event.target.value)
        onChange(newValue)
      }}
    />
  )
}
