import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  type MutableRefObject,
} from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { type Node, useEdgesState, useNodesState } from "reactflow"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { DiscardModal } from "./components/DiscardModal"

import {
  DndContext,
  rectIntersection,
  type DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  transformStepToNode,
  transformNodeToStep,
  transformDraggedItem,
  createNewIds,
  addRegularNode,
  addBranchedNode,
  createEdges,
  getLayoutedElements,
} from "./utils"
import {
  getWorkflowDefinitionAPI,
  postWorkflowDefinitionAPI,
} from "../../services"
import { ArrowBackIosNew, Search } from "@mui/icons-material"
import {
  ActionConfigPanel,
  CanvasMap,
  CreateDefinitionModal,
  CustomActionsTabPanel,
  WorkflowCards,
} from "./components"
import {
  ButtonsBox,
  CustomTab,
  Header,
  InformationBox,
  SideColumn,
  SideColumnContentBox,
  TabsWrapper,
  Wrapper,
} from "./styled"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"
import { useToast } from "../../contexts"
import { LoadingButton } from "@mui/lab"

export const WorkflowConfigurator = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const toast = useToast()

  const iterator = useRef<number>(0)
  const initialNodesRef = useRef<Node[] | null>(null)
  const isNewDefinition = pathname.endsWith(
    "/workflow-configurator/newDefinition",
  )

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [isCreateDefinitionModalOpen, setIsCreateDefinitionModalOpen] =
    useState(false)
  const [selectedActionId, setSelectedActionId] = useState<string | undefined>()
  const [ids, setIds] = useState<Record<string, string>>({
    ChannelId: "''",
    ApiSetupId: "''",
    ApiSetupDetailsId: "''",
  })
  const [activeTab, setActiveTab] = useState<"builder">("builder")
  const [activeMethodsTab, setActiveMethodsTab] = useState<
    "triggers" | "actions" | "core"
  >("triggers")

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data, isPending, isRefetching, refetch } = useQuery({
    queryKey: ["workflow-definition"],
    queryFn: () =>
      getWorkflowDefinitionAPI(id!).then((res: IWorkflowDefinition) => {
        return res
      }),
    enabled: !!id && !isNewDefinition,
  })

  const { mutate, isPending: isCreating } = useMutation({
    mutationFn: (body: IWorkflowDefinition) => postWorkflowDefinitionAPI(body),
    onSuccess: () => {
      toast.show(t("New workflow definition is created!"), "success")
      navigate("/workflow-definitions")
    },
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: "builder") => {
      setActiveTab(newValue)
    },
    [],
  )

  const handleCardTabChange = useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: "triggers" | "actions" | "core",
    ) => {
      setActiveMethodsTab(newValue)
    },
    [],
  )

  const updateNodesAndEdges = (
    over: any,
    transformedItem: Partial<IMethodItem>,
    newId: string,
  ) => {
    if (!over) return

    if (transformedItem.action === "If") {
      const { transformedNodes, transformedEdges } = addBranchedNode(
        nodes,
        edges,
        transformedItem,
        newId,
        over,
        2,
      )
      const { nodes: updatedNodes, edges: updatedEdges } = getLayoutedElements(
        transformedNodes,
        transformedEdges,
      )
      setNodes(updatedNodes)
      setEdges(updatedEdges)
    } else {
      const { transformedNodes, transformedEdges } = addRegularNode(
        nodes,
        edges,
        transformedItem,
        newId,
        over,
      )

      const { nodes: updatedNodes, edges: updatedEdges } = getLayoutedElements(
        transformedNodes,
        transformedEdges,
      )

      setNodes(updatedNodes)
      setEdges(updatedEdges)
    }
  }

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const transformedItem = transformDraggedItem(active)
      const newId = createNewIds(over, transformedItem, nodes.length)

      // If the first node is not a trigger node, raise error
      if (over?.id?.toString().includes("dropzone")) {
        if (
          nodes?.find((node) => node?.id === over?.id)?.data?.title ===
          "trigger"
        ) {
          if (transformedItem.type !== "trigger") {
            toast.show(t("mustAddTrigger"), "error")
            return
          }
        }
      }

      updateNodesAndEdges(over, transformedItem, newId)
    },
    [nodes, edges, setNodes, setEdges],
  )

  const handleCardClick = useCallback(
    (item: INodeItem | null) => setSelectedActionId(item?.id),
    [],
  )

  const handleUpdateAction = (
    action: INodeItem,
    type: "input" | "output" | "selectNext" | "do",
  ) => {
    if (type === "input") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  inputs: action?.inputs,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "output") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  outputs: action?.outputs,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "selectNext") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  selectNextStep: action?.selectNextStep,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "do") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  do: action?.do,
                },
              }
            : node,
        )
        return newNodes
      })
    }
  }

  const initialNodes = useMemo(() => {
    if (isNewDefinition)
      return [
        {
          id: "step01-dropzone",
          type: "dropzoneCard",
          data: {
            id: "step01-dropzone",
            title: "trigger",
          },
          position: { x: 0, y: 0 },
        },
      ]

    const nodes: Node[] = []

    data?.steps?.forEach((step) => {
      nodes.push(...transformStepToNode(step))
    })

    return nodes
  }, [data])

  const initialEdges = useMemo(() => {
    if (isNewDefinition) return []
    if (data?.steps) return createEdges(data?.steps)
  }, [data])

  useEffect(() => {
    if (!isNewDefinition) {
      void refetch()
    }

    return () => setNodes([])
  }, [initialNodes, initialEdges])

  useEffect(() => {
    if (initialNodes && initialEdges) {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(initialNodes, initialEdges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  const handleCreateNewDefinition = useCallback(
    (definitionDetails: { id: string; description: string }) => {
      if (nodes.length === 0) return

      const transformedSteps = transformNodeToStep(nodes, edges)
      const updatedSteps = updateInputsInSteps(transformedSteps)

      mutate({
        id: definitionDetails?.id,
        description: definitionDetails?.description,
        defaultErrorBehavior: 0,
        defaultErrorRetryInterval: "",
        triggeredByEvent: "",
        version: "1",
        steps: updatedSteps!,
      })
    },
    [nodes, edges, data],
  )

  const updateInputsInSteps: (
    steps: IWorkflowStep[] | undefined,
  ) => IWorkflowStep[] | undefined = (steps) => {
    return steps?.map((step) => {
      if (
        step.stepType === "ClaimProperty" ||
        step.stepType === "IncomingEmail"
      ) {
        return {
          ...step,
          inputs: {
            ChannelType: step.inputs?.ChannelType
              ? step.inputs.ChannelType
              : "''",
            ChannelId: step.inputs?.ChannelId ? step.inputs.ChannelId : "''",
            DocType: step.inputs?.DocType ? step.inputs.DocType : "''",
          },
        }
      } else if (step.stepType === "TemplateBuilderStep") {
        return {
          ...step,
          inputs: {
            ...step.inputs,
            TemplateFileUrl: step?.inputs?.TemplateFileUrl
              ? `'${step.inputs?.TemplateFileUrl.replaceAll("'", "")}'`
              : "''",
          },
        }
      } else if (step.stepType === "GetDataStep") {
        const i = step?.inputs
        return {
          ...step,
          inputs: {
            ChannelId: ids.ChannelId,
            ApiSetupDetailsId: ids.ApiSetupDetailsId,
            ApiSetupId: ids.ApiSetupId,
            JSONRequestSchema: i?.JSONRequestSchema ? i?.JSONRequestSchema : {},
            JSONResponseSchema: i?.JSONResponseSchema
              ? i?.JSONResponseSchema
              : {},
            SelectedFields: i?.SelectedFields ? i?.SelectedFields : {},
          },
        }
      } else return step
    })
  }

  const handleUpdateDefinition = useCallback(() => {
    if (nodes.length === 0) return
    const transformedSteps = transformNodeToStep(nodes, edges)

    const updatedSteps = updateInputsInSteps(transformedSteps)

    if (data) {
      mutate({
        ...data,
        steps: updatedSteps!,
      })
    }
  }, [nodes, edges, data])

  const handleCreateOrUpdateClick = useCallback(() => {
    isNewDefinition
      ? setIsCreateDefinitionModalOpen(true)
      : handleUpdateDefinition()
  }, [isNewDefinition, handleUpdateDefinition])

  const isLoading = isPending || isRefetching

  useEffect(() => {
    iterator.current = iterator.current + 1
    if (iterator.current === 3 || iterator.current === 4) {
      initialNodesRef.current = nodes
    }

    window.history.pushState({}, "", window.location.pathname)

    const handlePopState = (event: PopStateEvent) => {
      if (newChangesExist(initialNodesRef)) {
        event.preventDefault()

        const confirmNavigation = window.confirm(
          "All unsaved changes will be lost! Are you sure you want to continue?",
        )

        if (!confirmNavigation) {
          window.history.pushState({}, "", window.location.pathname)
        } else {
          navigate("/workflow-definitions")
        }
      } else {
        navigate("/workflow-definitions")
      }
    }

    const handleBeforeUnload: (event: BeforeUnloadEvent) => void = (event) => {
      if (newChangesExist(initialNodesRef)) {
        event.preventDefault()

        const confirmNavigation = window.confirm(
          "All unsaved changes will be lost! Are you sure you want to continue?",
        )

        if (!confirmNavigation) {
          window.history.pushState({}, "", window.location.pathname)
        }
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [nodes])

  const newChangesExist: (
    refNodes: MutableRefObject<Node[] | null>,
  ) => boolean | undefined = (refNodes) => {
    if (refNodes.current?.length !== nodes.length) {
      return true
    } else {
      const equalNodes = nodes.map(
        (node, index) => node.id === refNodes.current![index]?.id,
      )

      return equalNodes.includes(false)
    }
  }

  const handleCancelClick = () => {
    if (
      isNewDefinition ||
      (!isNewDefinition && newChangesExist(initialNodesRef))
    ) {
      setIsOpen(true)
    } else {
      initialNodesRef.current = null
      navigate("/workflow-definitions")
    }
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      collisionDetection={rectIntersection}
    >
      <Wrapper>
        <Header>
          <InformationBox>
            <IconButton size="small" onClick={handleCancelClick}>
              <ArrowBackIosNew fontSize="inherit" />
            </IconButton>
            {isNewDefinition ? (
              <Typography
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {t("newDefinition")}
              </Typography>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="regularMedium"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {data?.id}
                </Typography>
                <Typography
                  variant="extraSmall"
                  color="gray"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {data?.description}
                </Typography>
              </Box>
            )}
          </InformationBox>
          <TabsWrapper>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <CustomTab label={t("builder")} value="builder" />
            </Tabs>
          </TabsWrapper>
          <ButtonsBox>
            <Button size="small" variant="outlined" onClick={handleCancelClick}>
              {t("cancel")}
            </Button>
            <LoadingButton
              loading={isCreating}
              size="small"
              onClick={handleCreateOrUpdateClick}
            >
              {isNewDefinition ? t("create") : t("update")}
            </LoadingButton>
          </ButtonsBox>
        </Header>
        {activeTab === "builder" && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            height="100%"
          >
            <SideColumn overflow="scroll" paddingBottom="30px">
              <SideColumnContentBox>
                <Typography
                  variant="largeMedium"
                  alignSelf="flex-start"
                  marginTop="32px"
                >
                  {t("workflowBuilder")}
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={() => {}}
                />
              </SideColumnContentBox>
              <Box height="16px" />
              <Tabs value={activeMethodsTab} onChange={handleCardTabChange}>
                <CustomTab label={t("triggers")} value="triggers" />
                <CustomTab label={t("actions")} value="actions" />
                <CustomTab label={t("core")} value="core" />
              </Tabs>
              <Divider flexItem />
              <SideColumnContentBox>
                <CustomActionsTabPanel
                  value="triggers"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
                <CustomActionsTabPanel
                  value="actions"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
                <CustomActionsTabPanel
                  value="core"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
              </SideColumnContentBox>
            </SideColumn>
            {isLoading && data ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <CanvasMap
                selectedActionId={selectedActionId}
                nodeOptions={{ nodes, onNodesChange, setNodes }}
                edgeOptions={{ edges, onEdgesChange, setEdges }}
                onCardSelect={handleCardClick}
              >
                {selectedActionId && (
                  <ActionConfigPanel
                    nodes={nodes}
                    edges={edges}
                    selectedActionId={selectedActionId}
                    onClosePanel={() => handleCardClick(null)}
                    onUpdateAction={handleUpdateAction}
                    setIds={setIds}
                  />
                )}
              </CanvasMap>
            )}
          </Box>
        )}
      </Wrapper>
      <CreateDefinitionModal
        isOpen={isCreateDefinitionModalOpen}
        onClickSubmit={handleCreateNewDefinition}
        onClose={() => setIsCreateDefinitionModalOpen(false)}
      />
      <DiscardModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        onConfirm={() => {
          initialNodesRef.current = null
          setIsOpen(false)
          navigate("/workflow-definitions")
        }}
      />
    </DndContext>
  )
}
