import { type DragEventHandler, useCallback, useState, forwardRef } from "react"
import { type BoxProps, colors as muiColors } from "@mui/material"
import { colors } from "../../utils"
import { Container } from "./styled"

type TRef = BoxProps

type TProps = BoxProps & {
  onFilesDrop?: (files: File[]) => void
  disabled?: boolean
  error: boolean
}

export const Dropzone = forwardRef<TRef, TProps>((props, ref) => {
  const { onFilesDrop, disabled, children, error, ...rest } = props

  const [dragging, setDragging] = useState(false)

  const handleDragEnter: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!disabled) {
        setDragging(true)
      }
    },
    [disabled],
  )

  const handleDragLeave: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!disabled) {
        setDragging(false)
      }
    },
    [disabled],
  )

  const handleDragOver: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!disabled) {
        e.dataTransfer.dropEffect = "copy"
      }
    },
    [disabled],
  )

  const handleDrop: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!disabled) {
        setDragging(false)
        const files = Array.from(e.dataTransfer.files)
        onFilesDrop?.(files)
      }
    },
    [onFilesDrop],
  )

  return (
    <Container
      ref={ref}
      display="flex"
      flex={1}
      bgcolor={
        dragging ? colors.gray : error ? muiColors.red[50] : colors.secondary
      }
      border={`1px dashed ${
        dragging ? colors.primary : error ? muiColors.red[700] : colors.gray3
      }`}
      borderRadius="3px"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={
        (dragging ? "children-pointer-events-none dragging " : "") +
        (disabled ? "pointer-events-none opacity-40" : "")
      }
      {...rest}
    >
      {children}
    </Container>
  )
})
