import { TableRow, styled, TableCell, TableContainer } from "@mui/material"
import { colors } from "../../../../utils"

export const CustomRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderRight: `1px solid ${colors.gray4}`,
    borderBottom: "none",
    "&:nth-of-type(n+5)": {
      borderLeft: `1px solid ${colors.gray4}`,
    },
    "&:first-child": {
      borderLeft: `1px solid ${colors.gray4}`,
    },
    "&:last-child": {
      borderRight: `1px solid ${colors.gray4}`,
    },
  },
})

export const CustomTableCell = styled(TableCell)({
  width: "25%",
  borderRight: `1px solid ${colors.gray4}`,
  borderBottom: "none",
})

export const CustomCostTableCell = styled(TableCell)({
  width: "20%",
  borderRight: `1px solid ${colors.gray4}`,
  borderBottom: "none",
})

export const CustomTableContainer = styled(TableContainer)({
  maxHeight: "450px",
  overflow: "auto",
})
