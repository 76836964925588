import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import { ComparisonForm } from "../../../../../../../ClaimPage/components/ComparisonForm"
import { useForm } from "react-hook-form"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { camelCaseControlName, colors } from "../../../../../../../../utils"
import { getClaimTemplateAPI } from "../../../../../../../../services"
import { useQuery } from "@tanstack/react-query"
import { FormsContainer } from "./styled"

interface IProps {
  data?: IClaimDataChange
  open: boolean
  closeDialog: () => void
}

export const ClaimDifferencesDialog = (props: IProps) => {
  const { data, open, closeDialog } = props
  const { t } = useTranslation()

  const form1Ref = useRef<HTMLDivElement>(null)
  const form2Ref = useRef<HTMLDivElement>(null)

  const {
    data: claimTemplate,
    isLoading: isClaimTemplateLoading,
    isSuccess: isClaimTemplateLoadedSuccessfully,
    isError: getClaimTemplateFailed,
  } = useQuery<IClaimTemplate>({
    queryKey: ["claim-template", data?.groupId],
    queryFn: () => getClaimTemplateAPI({ groupId: data?.groupId! }),
    enabled: !!data,
  })

  const {
    control: prevClaimControl,
    reset: resetPrevClaim,
    watch: watchPrevClaim,
  } = useForm({
    values: data?.claimDetails,
  })

  const {
    control: newClaimControl,
    setValue: setNewClaimValues,
    reset: resetNewClaim,
    watch: watchNewClaim,
  } = useForm({
    values: data?.claimDetails,
  })

  const setNewClaimData = useCallback(() => {
    data?.comparisonDifferences?.forEach((difference) => {
      setNewClaimValues(camelCaseControlName(difference.key), difference.value)
    })
  }, [data])

  useEffect(() => {
    if (data) {
      resetPrevClaim(data.claimDetails)
      resetNewClaim(data.claimDetails)

      setNewClaimData()
    }
  }, [data])

  const handleScroll = useCallback(
    (
      sourceRef: React.RefObject<HTMLDivElement>,
      targetRef: React.RefObject<HTMLDivElement>,
    ) => {
      if (sourceRef.current && targetRef.current) {
        const { scrollTop, scrollLeft } = sourceRef.current
        targetRef.current.scrollTop = scrollTop
        targetRef.current.scrollLeft = scrollLeft
      }
    },
    [],
  )

  const conflictedSectionIds = useMemo(() => {
    const conflictedControlNames = data?.comparisonDifferences?.map((c) =>
      camelCaseControlName(c.key),
    )
    return claimTemplate?.steps
      ?.map((s) => s.sections)
      ?.flat()
      ?.map((s) => s.subSections)
      ?.flat()
      ?.filter(
        (s) =>
          s?.metadatas?.some(
            (c) => conflictedControlNames?.includes(c.controlName),
          ),
      )
      ?.map((s) => s.id)
  }, [data, claimTemplate])

  const handleForm1Scroll = useCallback(() => {
    handleScroll(form1Ref, form2Ref)
  }, [form1Ref, form2Ref])

  const handleForm2Scroll = useCallback(() => {
    handleScroll(form2Ref, form1Ref)
  }, [form1Ref, form2Ref])

  useEffect(() => {
    if (form1Ref.current && form2Ref.current) {
      form1Ref.current.addEventListener("scroll", handleForm1Scroll)
      form2Ref.current.addEventListener("scroll", handleForm2Scroll)
    }
    return () => {
      if (form1Ref.current && form2Ref.current) {
        form1Ref.current.removeEventListener("scroll", handleForm1Scroll)
        form2Ref.current.removeEventListener("scroll", handleForm2Scroll)
      }
    }
  }, [])

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="xl">
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography flex={1} variant="extraLargeMedium">
            {t("claimDataChanges")}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="120px"
        >
          {isClaimTemplateLoading && <CircularProgress />}
          {!!data &&
            !!claimTemplate &&
            !isClaimTemplateLoading &&
            isClaimTemplateLoadedSuccessfully && (
              <FormsContainer>
                <Box
                  ref={form1Ref}
                  paddingTop="24px"
                  margin="16px"
                  overflow="auto"
                  border={`2px dashed ${colors.gray}`}
                >
                  <ComparisonForm
                    step={claimTemplate?.steps[0]!}
                    formControl={prevClaimControl}
                    disabled
                    conflictedSectionIds={conflictedSectionIds}
                    collisionColor={colors.red2}
                    watch={watchPrevClaim}
                  />
                </Box>
                <Box
                  ref={form2Ref}
                  paddingTop="24px"
                  margin="16px"
                  overflow="auto"
                  border={`2px dashed ${colors.gray}`}
                >
                  <ComparisonForm
                    step={claimTemplate?.steps[0]!}
                    conflictedSectionIds={conflictedSectionIds}
                    formControl={newClaimControl}
                    disabled
                    collisionColor={colors.green2}
                    watch={watchNewClaim}
                  />
                </Box>
              </FormsContainer>
            )}
          {getClaimTemplateFailed && (
            <Typography>{t("failedToLoad")}</Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
