import { axiosWorkflow } from "../lib"

export const getGlobalInboxAPI = (
  params: IGetGlobalInboxRequestParams,
): Promise<IGetGlobalInboxResponse> =>
  axiosWorkflow.post("/Listener/globalInbox", params)

export const getGlobalInboxAttachmentsAPI = (
  params: IGetGlobalInboxAttachmentsRequestParams,
): Promise<IGetGlobalInboxAttachmentsResponse> =>
  axiosWorkflow.get("/Listener/attachment", { params })

export const getGlobalInboxDeletedAPI = (
  params: IGetGlobalInboxRequestParams,
): Promise<IGetGlobalInboxResponse> =>
  axiosWorkflow.post("/Listener/deleted", params)

export const getDocTypesAPI = (): Promise<IDocType[]> =>
  axiosWorkflow.get("/Listener/docTypes")
