import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CARD_HEIGHT, CARD_WIDTH } from "../../../../utils/constants"
import { getNodeIcon } from "../../../../../WorkflowConfigurator/utils"
import { getExecutionStatusColor } from "../../../../utils"
import {
  getExecutionPointerStatusColor,
  getExecutionStatusName,
} from "../../../utils"

interface IProps {
  id: string
  type: string
  action: string
  label: string
  icon: string
  status: number
}

export const CardInfo = (props: IProps) => {
  const { id, type, label, icon, status } = props
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      padding="19px"
      bgcolor="white"
      borderRadius="10px"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      border={`1px solid ${getExecutionStatusColor(status)}`}
      gap="10px"
      id={id}
    >
      <Box
        width="40px"
        height="40px"
        borderRadius="8px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={getExecutionStatusColor(status)}
      >
        {getNodeIcon(icon, type)}
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <Typography margin={0} variant="regular" color="gray">
          {t(type)}
        </Typography>
        <Typography margin={0} variant="largeMedium">
          {label}
        </Typography>
      </Box>
      <Typography
        variant="smallBold"
        fontStyle="oblique"
        margin="8px 12px"
        position="absolute"
        color={getExecutionPointerStatusColor(status)}
        top={0}
        right={0}
      >
        <u>{t(getExecutionStatusName(status))}</u>
      </Typography>
    </Box>
  )
}
