export const colors = {
  white: "#ffffff",
  white2: "#F8F8F8",

  black: "#1F2329",
  black2: "#424B5A",
  black3: "#505D68",
  black4: "##667080",

  primary: "#06BED9",
  secondary: "#EFF1F3",

  red: "#FC5640",
  red2: "#FC564080",
  red3: "#FFCCCC",
  red4: "#FFEBEB",

  lightgray1: "#E4E7EC80",

  gray: "#F3F6F9",
  gray2: "#C2D1D9",
  gray3: "#424B5AB0",
  gray4: "#424B5A1A",
  gray5: "#EBEBEB",
  gray6: "#EBEBEB60",
  gray7: "#DBE0E6",
  gray8: "#F9FAFB",
  gray9: "#475467",
  gray10: "#E1E5EA",
  gray11: "#e6e8eb",
  gray12: "#36414D",
  gray13: "#E4E7EC",

  green: "#54B435",
  green2: "#01AD404D",
  green3: "#DFF2E6",
  green4: "#ECFDF3",

  yellow: "#F7D468",
  yellow2: "#FFBB64",
  yellow3: "#F7F1DE",
  yellow4: "#FFFDF5",

  blue: "#EFF4FE",
  blue2: "#D0E0FE",
  blue3: "#1E345B",
  blue4: "#2E5291",
  blue5: "#D1EAEE",
  blue6: "#EDF6F7",
  blue7: "#AFE7ED",

  greenDot: "#01AD65",
  orangeDot: "#FF7000",
  blueDot: "#175CD3",
  grayDot: "#A9A9A9",
  redDot: "#FF3939BF",

  transparent: "transparent",
}
