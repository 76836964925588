import { useCallback, useEffect, useRef } from "react"
import { type AxiosError, type AxiosResponse } from "axios"
import { useTranslation } from "react-i18next"
import { axios, axiosWorkflow } from "../../lib"
import { useAppContext } from "../AppContext"
import { useToast } from "../ToastContext"
import { getWorkflowApiKey } from "../../utils"

interface IProps {
  children?: React.ReactNode
}

export const ServerProvider = (props: IProps) => {
  const { children } = props
  const { t } = useTranslation()
  const { refreshTokenMutation, logOut } = useAppContext()
  const toast = useToast()
  const isRefreshingRef = useRef(false)
  const failedQueueRef = useRef<
    Array<{
      resolve: (value?: string) => void
      reject: () => void
    }>
  >([])

  const processQueue = useCallback((token?: string) => {
    failedQueueRef.current.forEach(async (promise) => {
      token ? promise.resolve(token) : promise.reject()
    })

    failedQueueRef.current = []
  }, [])

  const onFulfilled = useCallback(
    (response: AxiosResponse<any>) => Promise.resolve(response.data),
    [],
  )

  const onRejected = useCallback(async (error: AxiosError) => {
    const request = error.config! as AxiosError["config"] & {
      _retry?: boolean
    }

    const refreshToken = localStorage.getItem("refreshToken")

    if (
      request.baseURL?.includes("workflow-runtime-engine") &&
      error?.response?.status === 403 &&
      !request._retry
    ) {
      request._retry = true

      try {
        const newKey = getWorkflowApiKey()
        request.headers.ApiKey = newKey

        const response = await axiosWorkflow(request)

        axiosWorkflow.defaults.headers.ApiKey = newKey

        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }

    if (refreshToken && error?.response?.status === 401 && !request._retry) {
      request._retry = true

      if (!isRefreshingRef.current) {
        try {
          isRefreshingRef.current = true
          const response = await refreshTokenMutation.mutateAsync()

          request.headers.Authorization = `Bearer ${response.accessToken}`
          processQueue(response.accessToken)
          return axios(request)
        } catch (error) {
          processQueue()
          toast.show(t("sessionExpired"))
          logOut()
          return Promise.reject(error)
        } finally {
          isRefreshingRef.current = false
        }
      }

      try {
        const token = await new Promise((resolve, reject) => {
          failedQueueRef.current = [
            ...failedQueueRef.current,
            { resolve, reject },
          ]
        })

        request.headers.Authorization = `Bearer ${token as string}`

        return Promise.resolve(axios(request))
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const errors = (error?.response?.data as any)?.errors

    const errorMessage =
      typeof errors?.[Object.keys(errors)?.[0]] === "string"
        ? errors[Object.keys(errors)[0]]
        : typeof (error?.response?.data as any)?.value === "string"
        ? (error?.response?.data as any)?.value
        : typeof error?.response?.data === "string"
        ? error?.response?.data
        : typeof error?.message === "string"
        ? error?.message
        : t("somethingWentWrong")

    toast.show((errorMessage || t("somethingWentWrong")) as string)

    return Promise.reject(error)
  }, [])

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(onFulfilled, onRejected)
    const interceptorWorkflow = axiosWorkflow.interceptors.response.use(
      onFulfilled,
      onRejected,
    )

    return () => {
      axios.interceptors.response.eject(interceptor)
      axiosWorkflow.interceptors.response.eject(interceptorWorkflow)
    }
  }, [onFulfilled, onRejected])

  return <>{children}</>
}
