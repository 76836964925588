import React from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"

interface DiscardModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const DiscardModal: React.FC<DiscardModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      component="form"
      open={isOpen}
      onSubmit={onConfirm}
      onClose={onCancel}
    >
      <DialogTitle>Warning</DialogTitle>
      <DialogContent sx={{ maxWidth: "480px" }}>
        <Box
          height="115px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              width: "100%",
              fontSize: 20,
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            All unsaved changes will be lost. <br /> Are you sure you want to
            continue?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">Continue</Button>
      </DialogActions>
    </Dialog>
  )
}
