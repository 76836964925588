import { styled } from "@mui/system"
import Table from "@mui/material/Table"
import { Autocomplete, Box, TableContainer, TextField } from "@mui/material"
import { colors } from "../../utils"

interface IProps {
  isEditMode: boolean
}

export const CustomContainer = styled(TableContainer)(() => ({
  backgroundColor: "transparent",
  border: "none",
  maxHeight: "450px",
  overflow: "auto",
}))

export const CustomTable = styled(Table)<IProps>(({ isEditMode }) => ({
  "& .MuiTableHead-root": {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "transparent",
    "& .MuiTableRow-root": {
      height: "30px",
      "& .MuiTableCell-root": {
        padding: "15px 20px",
        fontWeight: 400,
        fontSize: "14px",
        backgroundColor: colors.gray10,
        borderBottom: `1px solid ${colors.gray2}`,
        "&:first-of-type": {
          backgroundColor: colors.white,
        },
        "&:last-of-type": {
          borderRight: "none",
          backgroundColor: isEditMode ? colors.white : "",
        },
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      height: "30px",
      "& .MuiTableCell-root": {
        padding: isEditMode ? "10px" : "15px 20px",
        borderBottom: `1px solid ${colors.gray}`,
        verticalAlign: "middle",
        fontSize: "14px",
        fontWeight: 400,
        backgroundColor: colors.white,
        "&:first-of-type": {
          backgroundColor: colors.gray10,
        },
        "&:last-of-type": {
          borderRight: "none",
        },
      },
    },
  },
  "& .MuiTableFooter-root": {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    "& .MuiTableRow-root": {
      height: "30px",
      "& .MuiTableCell-root": {
        borderBottom: "none",
        padding: isEditMode ? "10px" : "15px 20px",
        borderTop: `1px solid ${colors.gray2}`,
        color: colors.white,
        fontWeight: 700,
        fontSize: "14px",
        backgroundColor: colors.gray12,
        "&:last-of-type": {
          borderRight: "none",
          textAlign: isEditMode ? "center" : "",
        },
        "& .MuiTypography-root": {
          color: colors.white,
        },
      },
    },
  },
}))

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  "& .MuiFilledInput-root": {
    paddingTop: 0,
  },
  "& .MuiInputBase-root": {
    height: "30px",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiAutocomplete-endAdornment": {
    paddingTop: "3px",
  },
}))

export const CellBox = styled(Box)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    fontSize: "12px",
  },
}))
