export const DROPZONE_WIDTH = 200
export const DROPZONE_HEIGHT = 100
export const CARD_WIDTH = 352
export const CARD_HEIGHT = 48

export const TRIGGER_METHODS: IMethodItem[] = [
  {
    type: "trigger",
    action: "IncomingEmail",
    label: "Incoming Email",
    icon: "incomingEmail",
  },
  {
    type: "trigger",
    action: "ClaimProperty",
    label: "Claim Property",
    icon: "incomingEmail",
  },
]
export const ACTION_METHODS: IMethodItem[] = [
  {
    type: "posteingang",
    action: "PosteingangStep",
    label: "Advoware General Inbox",
    icon: "inbox",
  },
  {
    type: "wiedervorlage",
    action: "WiedervorlageStep",
    label: "Advoware Resubmission",
    icon: "postAdd",
  },
  {
    type: "genericstep",
    action: "UpdateCostTblStep",
    label: "Update Cost Table",
    icon: "table",
  },
  {
    type: "postausgang",
    action: "PostausgangStep",
    label: "Advoware Outbox",
    icon: "outgoingEmail",
  },
  {
    type: "action",
    action: "GetDataStep",
    label: "Get Data",
    icon: "data",
  },
  {
    type: "genericstep",
    action: "TemplateBuilderStep",
    label: "Generate Document From Template",
    icon: "outgoingEmail",
  },
]
export const CORE_METHODS: IMethodItem[] = [
  {
    type: "core",
    action: "WaitFor",
    label: "WaitFor",
    icon: "pausePresentation",
  },
  {
    type: "core",
    action: "EmitLog",
    label: "Emit Log",
    icon: "terminal",
  },
  {
    type: "core",
    action: "If",
    label: "If Condition",
    icon: "splitArrows",
  },
  {
    type: "core",
    action: "EndWorkflow",
    label: "End Workflow",
    icon: "outlinedFlag",
  },
]
