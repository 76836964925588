import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  createRef,
} from "react"
import { CustomAutocomplete, EmailBodyButton } from "./styled"
import { ExpandMore, FilePresent } from "@mui/icons-material"
import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  type TextFieldProps,
} from "@mui/material"
import { EmailDetailsTooltip } from "../EmailDetailsTooltip"
import { useTranslation } from "react-i18next"
import { type IDocument } from "@cyntler/react-doc-viewer"
import { useQuery } from "@tanstack/react-query"
import {
  getSearchResultsAPI,
  getWorkflowDefinitionsAPI,
} from "../../../../../../../../../../services"
import { useAppContext } from "../../../../../../../../../../contexts"

interface IProps {
  rowDetails: IGlobalInboxItemDetail
  attachments: IGetGlobalInboxAttachmentsResponse
  documents: IDocument[]
  isLoading: boolean
  onUpdateDocuments: (documents: IDocument[]) => void
}

export const EmailBodyAndActions = (props: IProps) => {
  const { rowDetails, attachments, documents, isLoading, onUpdateDocuments } =
    props
  const { state: appState } = useAppContext()
  const { t } = useTranslation()

  const [assignCaseOpen, setAssignCaseOpen] = useState<boolean>(false)
  const [workflowOpen, setWorkflowOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [showMore, setShowMore] = useState<boolean>(false)

  const boxRef = useRef(createRef<HTMLDivElement>().current)

  useEffect(() => {
    const box = boxRef.current

    const handleScroll = () => {
      if (assignCaseOpen) {
        setAssignCaseOpen(false)
      }
      if (workflowOpen) {
        setWorkflowOpen(false)
      }
    }

    if (box) {
      box.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (box) {
        box.removeEventListener("scroll", handleScroll)
      }
    }
  }, [assignCaseOpen, workflowOpen])

  const { data: workflows, isPending: isWorkflowOptionsPending } = useQuery({
    queryKey: ["workflow-definitions"],
    queryFn: getWorkflowDefinitionsAPI,
  })

  const {
    data: claimResults,
    isLoading: isClaimLoading,
    isRefetching: isClaimsRefetching,
  } = useQuery<ISearchResults>({
    queryKey: ["global-search", searchQuery],
    queryFn: () =>
      getSearchResultsAPI({
        groupId: appState?.groupId!,
        search: searchQuery,
      }),
    enabled: !!searchQuery,
  })

  const handleAttachmentPreviewClick = useCallback(
    (doc: IGlobalInboxAttachment) => {
      const { url, name } = doc
      onUpdateDocuments([{ uri: url, fileName: name, fileType: "pdf" }])
    },
    [onUpdateDocuments],
  )

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 350),
    [],
  )

  const handleInputChange = (_: React.SyntheticEvent, value: string) => {
    debouncedSearch(value)
    setAssignCaseOpen(!!value)
  }

  const handleSelect = (
    _: React.SyntheticEvent,
    value: IClaimSearchData | null,
  ) => {
    if (value) {
      setAssignCaseOpen(false)
    }
  }

  const filteredOptions = (claimResults?.claims.claims || []).filter(
    (option) => option?.advoWareId !== null,
  )

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && filteredOptions.length === 1) {
      handleSelect(event, filteredOptions[0])
    }
  }

  const workflowOptions = useMemo<INameValue[] | undefined>(() => {
    return workflows?.map((definition) => ({
      name: definition.id + ` (${definition.version})`,
      value: definition.id,
    }))
  }, [workflows])

  return (
    <Box
      ref={boxRef}
      display="flex"
      flexDirection="column"
      paddingX="4px"
      overflow="auto"
      width="100%"
      className="contain-overscroll"
    >
      <Typography>{rowDetails.sender}</Typography>
      <Typography variant="small">
        {t("caseNumber")}:
        <Typography variant="smallBold">
          {" "}
          <Typography variant="smallBold">
            {rowDetails.caseNumber ?? "-"}
          </Typography>
        </Typography>
      </Typography>
      <EmailDetailsTooltip rowDetails={rowDetails} />
      <Divider />
      <Box
        margin="8px 0"
        height={showMore ? "auto" : "190px"}
        overflow={showMore ? "visible" : "hidden"}
        dangerouslySetInnerHTML={{ __html: rowDetails?.body }}
      />
      <EmailBodyButton
        size="small"
        variant="outlined"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? t("showLess") : t("showMore")}
      </EmailBodyButton>
      <Divider />
      <Typography variant="regularBold" marginTop="16px">
        {t("attachments")}
      </Typography>
      <Box display="flex" flexDirection="column" gap="8px" margin="8px 0">
        {rowDetails?.attachmentCount === 0 ? (
          <Typography marginLeft="8px" marginTop="8px" variant="small">
            {t("noAttachments")}
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("type")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? Array.from({ length: 2 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton disabled size="small">
                            <FilePresent fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : attachments?.inboxAttachments?.map((d, index) => (
                      <TableRow key={index}>
                        <TableCell>{d.name}</TableCell>
                        <TableCell>{d.docType}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            disabled={isLoading || d.url === documents[0]?.uri}
                            onClick={() => handleAttachmentPreviewClick(d)}
                            size="small"
                          >
                            <FilePresent fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Typography variant="regularBold" marginTop="16px">
        {t("assignProcessToCase")}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
        margin="8px 0"
      >
        <CustomAutocomplete
          options={filteredOptions}
          getOptionLabel={(option: any) => option?.advoWareId}
          filterOptions={(options) => options}
          loading={isClaimLoading || isClaimsRefetching}
          onInputChange={handleInputChange}
          onChange={(event, value) =>
            handleSelect(event, value as IClaimSearchData)
          }
          onKeyDown={handleKeyPress}
          open={assignCaseOpen}
          popupIcon={
            isClaimLoading || isClaimsRefetching ? (
              <CircularProgress size="24px" />
            ) : (
              <ExpandMore />
            )
          }
          renderInput={(params) => (
            <TextField
              {...(params as TextFieldProps)}
              placeholder={t("search")}
              fullWidth
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
        <Button size="small">{t("editClaim")}</Button>
      </Box>
      <Divider />
      <Typography variant="regularBold" marginTop="16px">
        {t("assignProcessToWorkflow")}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
        margin="8px 0"
      >
        <CustomAutocomplete
          options={workflowOptions ?? []}
          value={null}
          loading={isWorkflowOptionsPending}
          onChange={(_e, _data) => {}}
          getOptionLabel={(option) =>
            option ? (option as INameValue).name : ""
          }
          popupIcon={<ExpandMore />}
          onOpen={() => setWorkflowOpen(true)} // Set WorkflowAutocomplete open on click
          onClose={() => setWorkflowOpen(false)} // Set it to close when not focused
          open={workflowOpen}
          renderInput={(params) => (
            <TextField
              {...(params as TextFieldProps)}
              placeholder={t("workflow")}
              fullWidth
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
        <Button size="small">{t("workflow")}</Button>
      </Box>
    </Box>
  )
}
