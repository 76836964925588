import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { Wrapper } from "./styled"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  EmailStepConfig,
  GetDataConfig,
  IfElseStepConfig,
  ResubmissionStepConfig,
  WaitForConfig,
  TemplateConfig,
  TriggerConfig,
  NoOptionsConfig,
} from "./components"
import { useReactFlow, type Node, type Edge } from "reactflow"
import {
  getLayoutedElements,
  mapInputValue,
  mapNodeItem,
  parseInputValue,
} from "../../utils"
import {
  colors,
  IF_ELSE_CONFIG_INITIAL_DATA,
  parsePythonExpression,
} from "../../../../utils"
import { Close } from "@mui/icons-material"

interface IProps {
  nodes: Node[]
  edges: Edge[]
  selectedActionId?: string
  onClosePanel?: () => void
  onUpdateAction: (
    action: INodeItem,
    type: "input" | "output" | "selectNext" | "do",
  ) => void
  setIds: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

export const ActionConfigPanel = (props: IProps) => {
  const {
    nodes,
    edges,
    selectedActionId,
    onClosePanel,
    onUpdateAction,
    setIds,
  } = props
  const { getNode, setNodes, setEdges } = useReactFlow()

  const { t } = useTranslation()

  const selectedAction = useMemo(() => {
    const node = nodes?.find((n) => n.id === selectedActionId)

    if (node) {
      return mapNodeItem(node)
    }

    return undefined
  }, [selectedActionId, nodes])

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      deleted.forEach((node) => {
        reconnectNodes(node.id)
      })
    },
    [nodes, edges],
  )

  const deleteSuccessorNodes = (
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
  ) => {
    const nodesToDelete = new Set<string>()
    const edgesToDelete = new Set<string>()

    let upperEdges = edges.filter((edge) => edge.target === nodeId)
    const dropZoneNodes: Node[] = []

    upperEdges = upperEdges.map((e) => {
      dropZoneNodes.push({
        id: `${e.source}-dropzone`,
        type: "dropzoneCard",
        data: { id: `${e.source}-dropzone`, title: "step" },
        position: { x: 0, y: 0 },
      })
      return {
        id: `${e.source}-edge`,
        source: e.source,
        target: `${e.source}-dropzone`,
        type: "",
      }
    })

    const collectNodesAndEdges = (nodeId: string) => {
      const outgoingEdges = edges.filter((edge) => edge.source === nodeId)
      if (outgoingEdges.length === 0) return

      outgoingEdges.forEach((edge) => {
        nodesToDelete.add(edge.target)
        edgesToDelete.add(edge.id)

        collectNodesAndEdges(edge.target)
      })
    }

    collectNodesAndEdges(nodeId)
    const updatedNodes = nodes
      .filter((n) => !nodesToDelete.has(n.id))
      .concat(dropZoneNodes)
    const updatedEdges = edges
      .filter((e) => !edgesToDelete.has(e.id))
      .concat(upperEdges)
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      updatedNodes,
      updatedEdges,
    )
    return { nodes: layoutedNodes, edges: layoutedEdges }
  }

  const reconnectNodes = (nodeId: string) => {
    let newEdges = [...edges]
    let newNodes = [...nodes]

    const incomingEdges = edges.filter((edge) => edge?.target === nodeId)
    const outgoingEdges = edges.filter((edge) => edge?.source === nodeId)

    if (outgoingEdges.length === 0) return

    // If the only node in the graph was deleted, add a default dropzone
    if (incomingEdges.length === 0) {
      const defaultDropzone: Node[] = [
        {
          id: "step01-dropzone",
          type: "dropzoneCard",
          data: {
            id: "step01-dropzone",
            title: "trigger",
          },
          position: { x: 0, y: 0 },
        },
      ]
      setNodes(defaultDropzone)
      return
    }

    // If there are multiple outgoing edges, we are working with a branched node and need to delete all branch elements
    if (outgoingEdges.length > 1) {
      const { nodes: updatedNodes, edges: updatedEdges } = deleteSuccessorNodes(
        nodeId,
        newNodes,
        newEdges,
      )
      newNodes = updatedNodes
      newEdges = updatedEdges
    }

    if (outgoingEdges[0].target.includes("-dropzone")) {
      newNodes = newNodes.filter((node) => node.id !== outgoingEdges[0]?.target)
    }

    newNodes = newNodes.filter((node) => node.id !== outgoingEdges[0]?.source)

    incomingEdges.forEach((edge) => {
      const updatedEdge = outgoingEdges[0]?.target.includes("-dropzone")
        ? {
            ...edge,
            id: `${edge.source}-edge`,
            target: `${edge.source}-dropzone`,
            type: "",
          }
        : {
            ...edge,
            id: `e${edge.source}-${outgoingEdges[0]?.target}`,
            target: outgoingEdges[0].target,
            type: edge.source?.includes("If-") ? "" : "addButtonEdge",
          }

      newEdges = newEdges.map((e) => {
        if (e.id === edge.id) {
          return updatedEdge
        }
        return e
      })
    })

    newEdges = newEdges.filter((e) => e.id !== outgoingEdges[0]?.id)
    newEdges.forEach((edge) => {
      if (!newNodes.find((n) => n.id === edge.target)) {
        newNodes = newNodes.concat({
          id: `${edge.source}-dropzone`,
          type: "dropzoneCard",
          data: { id: `${edge.source}-dropzone`, title: "step" },
          position: { x: 0, y: 0 },
        })
      }
    })

    const { nodes: updatedNodes, edges: updatedEdges } = getLayoutedElements(
      newNodes,
      newEdges,
    )
    setNodes(updatedNodes)
    setEdges(updatedEdges)
    onClosePanel?.()
  }

  const methods = useForm<any>()

  const isVisible = selectedActionId

  const { control, setValue, reset, watch } = methods

  const updateNestedConfig = (
    inputs: any,
    key: string,
    input: string | Record<any, any>,
  ) => {
    const keys = key.split(".")
    const obj = { ...inputs }
    let current = obj

    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) {
        current[keys[i]] = {}
      }
      current = current[keys[i]]
    }

    current[keys[keys.length - 1]] = input
    return obj
  }

  const handleChange = (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any,
    key: string,
    changeFromController?: (...input: any[]) => void,
  ) => {
    changeFromController?.(input)

    const mappedInput = mapInputValue(input, selectedAction.action, key)

    if (type === "input") {
      const updatedConfig = updateNestedConfig(
        selectedAction.inputs,
        key,
        mappedInput,
      )
      const updatedAction = Object.assign({}, selectedAction, {
        inputs: updatedConfig,
      })

      onUpdateAction(updatedAction, "input")
    }
    if (type === "output") {
      const updatedConfig = updateNestedConfig(
        selectedAction.outputs,
        key,
        mappedInput,
      )
      const updatedAction = Object.assign({}, selectedAction, {
        outputs: updatedConfig,
      })

      onUpdateAction(updatedAction, "output")
    }
    if (type === "selectNext") {
      const updatedConfig = updateNestedConfig(
        selectedAction.selectNextStep,
        key,
        mappedInput,
      )
      const updatedAction = Object.assign({}, selectedAction, {
        selectNextStep: updatedConfig,
      })

      onUpdateAction(updatedAction, "selectNext")
    }
  }

  useEffect(() => {
    reset({
      ...(selectedAction?.action === "If" && {
        Condition: IF_ELSE_CONFIG_INITIAL_DATA,
      }),
    })

    const setValuesRecursively = (obj: any, parentKey = "") => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key]
        const combinedKey = parentKey ? `${parentKey}.${key}` : key

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          setValuesRecursively(value, combinedKey)
        } else {
          if (selectedAction?.action === "If") {
            if (combinedKey === "Condition") {
              reset({
                [combinedKey]: parsePythonExpression(
                  String(value).replace(/"/g, ""),
                ),
              })
            }
          } else {
            setValue(
              combinedKey,
              parseInputValue(String(value).replace(/"/g, "")),
            )
          }
        }
      })
    }

    setValuesRecursively(selectedAction?.inputs ?? {})
    setValuesRecursively(selectedAction?.outputs ?? {})
    setValuesRecursively(selectedAction?.selectNextStep ?? {})
  }, [selectedActionId])

  const showConfigItems = useCallback(() => {
    switch (selectedAction?.action) {
      case "EmitLog":
        return (
          <>
            <Typography variant="largeMedium">{t("inputs")}</Typography>
            <Controller
              control={control}
              name="Message"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t("message")}
                  fullWidth
                  multiline
                  value={value ?? ""}
                  onChange={(event) => {
                    handleChange(
                      selectedAction,
                      "input",
                      event.target.value,
                      "Message",
                      onChange,
                    )
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="Level"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t("level")}
                  fullWidth
                  multiline
                  value={value ?? ""}
                  onChange={(event) => {
                    handleChange(
                      selectedAction,
                      "input",
                      event.target.value,
                      "Level",
                      onChange,
                    )
                  }}
                />
              )}
            />
          </>
        )

      case "UpdateCostTblStep":
        return <NoOptionsConfig />
      case "WaitFor":
        return (
          <WaitForConfig
            watch={watch}
            control={control}
            selectedAction={selectedAction}
            handleChange={handleChange}
          />
        )
      case "PosteingangStep":
      case "PostausgangStep":
        return (
          <EmailStepConfig
            handleChange={handleChange}
            control={control}
            selectedAction={selectedAction}
          />
        )
      case "TemplateBuilderStep": {
        return (
          <TemplateConfig
            control={control}
            selectedAction={selectedAction}
            handleChange={handleChange}
            methods={methods}
            nodes={nodes}
          />
        )
      }
      case "WiedervorlageStep":
        return (
          <ResubmissionStepConfig
            handleChange={handleChange}
            control={control}
            selectedAction={selectedAction}
          />
        )
      case "If":
        return (
          <IfElseStepConfig
            methods={methods}
            selectedAction={selectedAction}
            handleChange={handleChange}
            nodes={nodes}
          />
        )
      case "ClaimProperty":
        return (
          <TriggerConfig
            triggerType="Interface"
            selectedAction={selectedAction}
            handleChange={handleChange}
          />
        )
      case "IncomingEmail":
        return (
          <TriggerConfig
            triggerType="Email"
            selectedAction={selectedAction}
            handleChange={handleChange}
          />
        )
      case "GetDataStep":
        return (
          <GetDataConfig
            methods={methods}
            selectedAction={selectedAction}
            handleChange={handleChange}
            setIds={setIds}
          />
        )
      default:
        return <></>
    }
  }, [t, selectedAction, methods, nodes])

  if (selectedAction) {
    return (
      <Dialog
        open={!!isVisible}
        onClose={onClosePanel}
        maxWidth="xl"
        fullWidth
        sx={{ height: "89%", alignSelf: "center" }}
      >
        <DialogTitle>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography flex={1} variant="extraLargeMedium">
              {selectedAction?.label}
            </Typography>
            <IconButton onClick={onClosePanel}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ height: "100vh", justifyContent: "center", display: "flex" }}
        >
          <Wrapper component="form" sx={{}}>
            <Typography variant="largeMedium">
              {t("step")} {t("id")}: {selectedAction?.id}
            </Typography>
            <Divider flexItem />
            {showConfigItems()}
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              const node = getNode(selectedActionId || "")
              if (selectedActionId && node) {
                onNodesDelete(node ? [node] : [])
              }
            }}
          >
            <Typography color={colors.white}>{t("deleteStep")}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return <></>
}
