import React, { useEffect, useState } from "react"
import { Typography, Box } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import CloseIcon from "@mui/icons-material/Close"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import { ReactComponent as Info } from "../../../../assets/icons/Info.svg"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { colors } from "../../../../utils"
import { getMalfunctionsAPI } from "../../../../services"
import {
  StyledBox,
  StyledSkeleton,
  StyledAlert,
  StyledIconButton,
} from "./styled"

interface IAlert {
  title: string
  message: string
  type: string
}

export const AlertNotification: React.FC = () => {
  const { t } = useTranslation()

  const [alerts, setAlerts] = useState<IAlert[]>([])

  const {
    data: malfunctions,
    isRefetching: areMalfunctionsRefetching,
    isLoading,
  } = useQuery({
    queryKey: ["malfunctions"],
    queryFn: () => getMalfunctionsAPI(),
    refetchOnMount: true,
  })

  useEffect(() => {
    const newAlerts: IAlert[] = []

    if (malfunctions) {
      const { advowareStatus, smsCreditsAvailable } = malfunctions

      if (!advowareStatus) {
        newAlerts.push({
          title: t("advowareDown"),
          message: t("advowareDownMessage"),
          type: "warning",
        })
      }

      if (smsCreditsAvailable.toLowerCase() === "low") {
        newAlerts.push({
          title: t("smsLow"),
          message: t("smsLowMessage"),
          type: "info",
        })
      } else if (smsCreditsAvailable.toLowerCase() === "none") {
        newAlerts.push({
          title: t("smsNone"),
          message: t("smsNoneMessage"),
          type: "info",
        })
      }
    }

    setAlerts(newAlerts)
  }, [malfunctions, t])

  const handleCloseAlert = (index: number) => {
    setAlerts((prevAlerts: IAlert[]) =>
      prevAlerts.filter((_, i) => i !== index),
    )
  }

  const getIcon = (type: string) => {
    const iconStyle = {
      backgroundColor:
        type === "info" ? `${colors.white}` : `${colors.yellow4}`,
      borderRadius: "5px",
      border: `1px solid ${
        type === "info" ? `${colors.gray4}` : `${colors.orangeDot}`
      }`,
      padding: "6px",
      width: "20px",
      height: "20px",
      marginTop: "8px",
    }

    switch (type) {
      case "info":
        return <Info style={iconStyle} />
      case "warning":
        return <ErrorOutlineOutlinedIcon style={iconStyle} color="warning" />
      default:
        return <InfoIcon style={iconStyle} />
    }
  }

  return (
    <>
      {isLoading || areMalfunctionsRefetching ? (
        <>
          {[...Array(2)].map((_, index) => (
            <StyledBox key={index}>
              <StyledSkeleton variant="rectangular" height={20} width="30%" />
              <StyledSkeleton variant="rectangular" height={20} width="70%" />
            </StyledBox>
          ))}
        </>
      ) : (
        alerts.map((alert, index) => (
          <Box key={index} marginTop="16px">
            <StyledAlert
              key={index}
              icon={getIcon(alert.type)}
              severity={alert.type as any}
              action={
                <StyledIconButton onClick={() => handleCloseAlert(index)}>
                  <CloseIcon fontSize="inherit" />
                </StyledIconButton>
              }
            >
              <Typography variant="largeBold">{alert.title}</Typography>
              <Box>
                <Typography variant="large">{alert.message}</Typography>
              </Box>
            </StyledAlert>
          </Box>
        ))
      )}
    </>
  )
}
