import React, {
  type Dispatch,
  type SetStateAction,
  useRef,
  useState,
} from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import {
  Add,
  ExpandMore,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  RemoveCircle,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { FHAutoComplete } from "./FullHeightAutoComplete"
import { colors } from "../../../../../../utils"
import { CustomTextField } from "./styled"
import { last } from "lodash"

interface DummyDataTableProps {
  templateFields?: Record<string, any>
  model: Record<string, any>
  fieldMap: Record<string, any>
  showPreview: boolean
  setShowPreview: Dispatch<SetStateAction<boolean>>
  handleChange: (newFieldMap: Record<string, any>) => void
}

export const DummyDataView: React.FC<DummyDataTableProps> = ({
  model,
  fieldMap,
  showPreview,
  setShowPreview,
  handleChange,
}: DummyDataTableProps) => {
  const { control, handleSubmit, setValue, getValues, unregister } = useForm({
    defaultValues: {
      ...Object.keys(fieldMap).reduce((acc: any, key) => {
        acc[key] = { type: "text" }
        return acc
      }, {}),
    },
  })

  const { t } = useTranslation()
  const [conditions, setConditions] = useState<Record<string, number[]>>({})
  const [focusedInput, setFocusedInput] = useState<string>()
  const [currentExapded, setCurrentExpanded] = useState<string | undefined>()
  const [showFields, setShowFields] = useState<boolean>(true)

  const keys = Object.keys(model)
  const scrollToRef = useRef<any>([])

  const executeScroll = (i: number) => {
    scrollToRef?.current?.[i]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    })
  }

  const handleKeyClick = (key: string) => {
    if (focusedInput) {
      const prevControlValue = getValues(focusedInput)
      setValue(
        focusedInput,
        prevControlValue ? `${prevControlValue}<${key}>` : `<${key}>`,
      )
    }
  }

  const onApplyConfigurations = (data: any, controlKey: string) => {
    const newFiledMap = {
      ...fieldMap,
      [controlKey]: data,
    }
    handleChange(newFiledMap)
  }

  const renderChildren = (type: string, key: string, index: number) => {
    switch (type) {
      case "text":
        return (
          <Box sx={{ padding: 1 }}>
            <Typography
              marginBottom="12px"
              marginLeft="5px"
              variant="regularBold"
            >
              Free text input
            </Typography>
            <Controller
              control={control}
              name={`${key}.freeText`}
              key={`${key}.freeText`}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={12}
                  fullWidth
                  onFocus={() => setFocusedInput(`${key}.freeText`)}
                  label="Please add your text which you would like to be presented instead of the selected placeholder"
                  sx={{
                    backgroundColor: colors.white,
                  }}
                />
              )}
            />
          </Box>
        )
      case "prop":
        return (
          <Box sx={{ padding: 1 }}>
            <Typography
              marginBottom="12px"
              marginLeft="5px"
              variant="regularBold"
            >
              Select your model field
            </Typography>

            <Controller
              control={control}
              name={`${key}.prop`}
              key={`${key}.prop`}
              render={({ field, fieldState: { error } }) => (
                <FHAutoComplete
                  {...field}
                  key={key + "-prop-" + index}
                  fieldKey={key}
                  model={model}
                  error={error}
                />
              )}
            />
          </Box>
        )
      case "config":
        return (
          <Box sx={{ padding: 1 }}>
            <Typography
              marginBottom="12px"
              marginLeft="5px"
              variant="regularBold"
            >
              Create your own configuration
            </Typography>
            <Box>
              <Box width="100%">
                {conditions[key]?.map((itKey, itIndex) => (
                  <Box
                    key={itKey}
                    sx={{
                      flexDirection: "column",
                      marginBottom: 2,
                      backgroundColor: colors.gray4,
                      borderRadius: 1,
                      padding: 1,
                    }}
                  >
                    {conditions[key].length > 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography marginBottom="12px" variant="regular">
                          Condition #{itIndex + 1}
                        </Typography>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setConditions((prevConditions) => {
                              unregister(`${key}.${itKey}.left`)
                              unregister(`${key}.${itKey}.right`)
                              unregister(`${key}.${itKey}.operator`)
                              unregister(`${key}.${itKey}.logicalOperator`)

                              return {
                                ...prevConditions,
                                [key]: [
                                  ...prevConditions[key].slice(0, itIndex),
                                  ...prevConditions[key].slice(itIndex + 1),
                                ],
                              }
                            })
                          }}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </Box>
                    )}
                    <Box display="flex" flexDirection="row">
                      <Controller
                        control={control}
                        name={`${key}.${itKey}.left`}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: 1,
                            }}
                          >
                            <Typography
                              marginBottom="12px"
                              marginLeft="5px"
                              variant="regularBold"
                            >
                              Field
                            </Typography>
                            <FHAutoComplete
                              key={key + "-exp-" + index}
                              fieldKey={key}
                              value={value}
                              model={model}
                              onChange={(event) => {
                                onChange(event)
                              }}
                              error={error}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`${key}.${itKey}.operator`}
                        render={({ field: { value, onChange } }) => (
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 1,
                              }}
                            >
                              <Typography
                                marginBottom="12px"
                                marginLeft="5px"
                                variant="regularBold"
                              >
                                Operator
                              </Typography>
                              <Select
                                value={value}
                                placeholder="Operator"
                                sx={{
                                  width: "200px",
                                  height: "30px",
                                }}
                                onChange={(event) => {
                                  onChange(event.target.value)
                                }}
                              >
                                <MenuItem value="exists">exists</MenuItem>
                                <MenuItem value="does_not_exist">
                                  does not exist
                                </MenuItem>
                                <MenuItem value="is_equal_to">
                                  is equal to
                                </MenuItem>
                                <MenuItem value="is_less_than">
                                  is less than
                                </MenuItem>
                                <MenuItem value="is_greater_than">
                                  is greater than
                                </MenuItem>
                              </Select>
                            </Box>
                            {value !== "exists" &&
                              value !== "does_not_exist" && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginRight: 1,
                                  }}
                                >
                                  <Typography
                                    marginBottom="12px"
                                    marginLeft="5px"
                                    variant="regularBold"
                                  >
                                    Field
                                  </Typography>
                                  <Controller
                                    control={control}
                                    name={`${key}.${itKey}.right`}
                                    render={({ field }) => (
                                      <CustomTextField
                                        {...field}
                                        sx={{
                                          backgroundColor: colors.white,
                                        }}
                                      />
                                    )}
                                  />
                                </Box>
                              )}
                          </Box>
                        )}
                      />

                      {conditions[key].length - 1 !== itIndex && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            marginBottom="12px"
                            marginLeft="5px"
                            variant="regularBold"
                          >
                            Logic
                          </Typography>
                          <Controller
                            control={control}
                            name={`${key}.${itKey}.logicalOperator`}
                            render={({ field: { value, onChange } }) => (
                              <Select
                                value={value}
                                sx={{ width: "150px", height: "30px" }}
                                onChange={(event) => {
                                  onChange(event.target.value)
                                }}
                              >
                                <MenuItem value="and">AND</MenuItem>
                                <MenuItem value="or">OR</MenuItem>
                              </Select>
                            )}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: 1,
                  }}
                >
                  <Button
                    size="small"
                    startIcon={<Add />}
                    sx={{}}
                    onClick={() => {
                      setConditions((prevConditions) => {
                        const lastElement = last(prevConditions[key]) || 0
                        return {
                          ...prevConditions,
                          [key]: [...prevConditions[key], lastElement + 1],
                        }
                      })
                    }}
                  >
                    Add condition
                  </Button>
                </Box>
              </Box>
              <Box>
                <Typography
                  marginBottom="12px"
                  marginLeft="5px"
                  variant="regularBold"
                >
                  True text
                </Typography>
                <Controller
                  control={control}
                  name={`${key}.true`}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      multiline
                      rows={4}
                      onFocus={() => setFocusedInput(`${key}.true`)}
                      label="In case condition is true"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      sx={{ marginTop: "5px", backgroundColor: colors.white }}
                    />
                  )}
                />
                <Typography
                  marginBottom="12px"
                  marginLeft="5px"
                  variant="regularBold"
                >
                  False text
                </Typography>
                <Controller
                  control={control}
                  name={`${key}.false`}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      multiline
                      rows={4}
                      onFocus={() => setFocusedInput(`${key}.false`)}
                      label="In case condition is false"
                      value={value}
                      fullWidth
                      onChange={onChange}
                      sx={{ marginTop: "5px", backgroundColor: colors.white }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        )

      default:
        break
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {showFields && (
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          maxHeight="70vh"
          padding={1}
          sx={{
            height: "-webkit-fill-available",
          }}
        >
          <Typography paddingRight="16px">FIELDS</Typography>
          {keys.map((_key) => (
            <Button
              key={_key}
              onClick={() => handleKeyClick(_key)}
              sx={{ marginBottom: 1 }}
            >
              {_key}
            </Button>
          ))}
        </Box>
      )}
      <Box
        overflow="auto"
        maxHeight="70vh"
        display="flex"
        flexDirection="column"
        flex="1"
        padding={1}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowFields((prev) => !prev)}
            startIcon={showFields ? <KeyboardDoubleArrowLeft /> : undefined}
            endIcon={!showFields ? <KeyboardDoubleArrowRight /> : undefined}
          >
            <Typography paddingRight="10px">FIELDS</Typography>
          </Button>
          <Typography paddingRight="16px">PLACEHOLDERS</Typography>
          <Button
            variant="outlined"
            onClick={() => setShowPreview((prev) => !prev)}
            startIcon={!showPreview ? <KeyboardDoubleArrowLeft /> : undefined}
            endIcon={showPreview ? <KeyboardDoubleArrowRight /> : undefined}
          >
            <Typography paddingRight="10px">PREVIEW</Typography>
          </Button>
        </Box>
        {Object.keys(fieldMap).map((key, index) => {
          return (
            <Accordion
              ref={(el) => {
                if (el) {
                  scrollToRef.current[index] = el
                }
              }}
              key={key}
              expanded={currentExapded === key}
              sx={{ marginBottom: 1 }}
              onChange={(_, expanded) => {
                setCurrentExpanded(expanded ? key : undefined)
                setTimeout(() => executeScroll(index), 500)
                if (expanded) {
                  setConditions((prevConditions) => ({
                    ...prevConditions,
                    [key]: prevConditions[key] ? prevConditions[key] : [1],
                  }))
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Box>{key}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Controller
                    control={control}
                    name={`${key}.type`}
                    render={({ field }) => (
                      <>
                        <ToggleButtonGroup
                          {...field}
                          color="primary"
                          exclusive
                          sx={{ height: "40px", width: "100%" }}
                          aria-label="FieldType"
                        >
                          <ToggleButton
                            sx={{
                              minWidth: "150px",
                              width: "33.33%",
                              fontWeight: "bold",
                            }}
                            value="text"
                          >
                            Text
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              minWidth: "150px",
                              width: "33.33%",
                              fontWeight: "bold",
                            }}
                            value="prop"
                          >
                            Fields
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              minWidth: "150px",
                              width: "33.33%",
                              fontWeight: "bold",
                            }}
                            value="config"
                          >
                            Configuration
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            backgroundColor: "rgba(6, 190, 217, 0.08)",
                            minHeight: "313px",
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderTopWidth: 0,
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            borderRadius: 2,
                            borderColor: colors.gray11,
                            borderStyle: "solid",
                            marginTop: 1,
                          }}
                        >
                          {currentExapded === key &&
                            renderChildren(field.value, key, index)}
                          <Box sx={{ display: "flex", flex: 1 }} />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: 1,
                            }}
                          >
                            <Button
                              size="small"
                              onClick={handleSubmit((data) => {
                                return onApplyConfigurations(data[key], key)
                              })}
                            >
                              {t("Apply")}
                            </Button>
                          </Box>
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Box>
  )
}
