import {
  TableCell,
  TableSortLabel,
  useMediaQuery,
  Grid,
  TableHead,
} from "@mui/material"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { CustomRow } from "./styled"

interface HeadCell {
  id: keyof IClaim
  label: string
  width: string
  subFields?: any
  align?: any
}

interface IProps {
  showClaimGroup?: boolean
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IClaim,
  ) => void
  order: TOrder
  orderBy: string
  rowCount: number
  onHeaderClick: () => void
}

export const EnhancedTableHead = (props: IProps) => {
  const { showClaimGroup, order, orderBy, onRequestSort, onHeaderClick } = props
  const { t } = useTranslation()
  const isGreaterThan1420 = useMediaQuery("(min-width: 1420px)")

  const createSortHandler = useCallback(
    (property: keyof IClaim) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    },
    [onRequestSort],
  )

  const headCells: readonly HeadCell[] = [
    {
      id: "caseNumber",
      label: t("caseNumber"),
      width: "12%",
      subFields: [
        { id: "caseNumber", label: t("caseNumber") },
        { id: "createdDate", label: t("createdDate") },
      ],
    },
    {
      id: "damagedParty",
      label: t("damagedParty"),
      width: "12%",
      subFields: [
        { id: "damagedParty", label: t("damagedParty") },
        {
          id: "officialMandantRegistrationNumber",
          label: t("officialRegistrationNumber"),
        },
      ],
    },
    {
      id: "opponentParty",
      label: t("opponentParty"),
      width: "12%",
      subFields: [
        { id: "opponentParty", label: t("opponentParty") },
        {
          id: "officialOpponentRegistrationNumber",
          label: t("officialRegistrationNumber"),
        },
      ],
    },
    {
      id: "accidentDate",
      label: t("accidentDate"),
      width: "12%",
      subFields: [
        { id: "accidentDate", label: t("accidentDate") },
        { id: "accidentPlace", label: t("accidentPlace") },
      ],
    },
    {
      id: "groupName",
      label: t("groupName"),
      width: "16%",
    },
    {
      id: "status",
      label: t("status"),
      width: "18%",
      align: "center",
    },
    {
      id: "liability",
      label: t("liability"),
      width: "18%",
      align: "center",
    },
  ]

  return (
    <TableHead onClick={onHeaderClick}>
      <CustomRow>
        {headCells?.map((headCell) => {
          if (!showClaimGroup && headCell.id === "groupName") {
            return null
          } else if (headCell.subFields) {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
                width={headCell.width}
                className={isGreaterThan1420 ? "word-break-all" : ""}
                style={{
                  minWidth: isGreaterThan1420 ? headCell.width : "200px",
                }}
              >
                <Grid container direction="column">
                  {headCell.subFields.map((subField: any) => (
                    <Grid item key={subField.id}>
                      <TableSortLabel
                        active={orderBy === subField.id}
                        direction={orderBy === subField.id ? order : "asc"}
                        onClick={createSortHandler(subField.id)}
                      >
                        {subField.label}
                      </TableSortLabel>
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            )
          } else {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                style={{
                  minWidth: isGreaterThan1420 ? headCell.width : "200px",
                  textAlign: headCell.align,
                }}
                padding="none"
                sortDirection={orderBy === headCell.id ? order : false}
                width={headCell.width}
                className={isGreaterThan1420 ? "word-break-all" : ""}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            )
          }
        })}
        <TableCell width="3%" />
      </CustomRow>
    </TableHead>
  )
}
