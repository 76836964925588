import DocViewer, {
  type IDocument,
  DocViewerRenderers,
} from "@cyntler/react-doc-viewer"
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import {
  generateDocumentAPI,
  generateDocumentExternalAPI,
  getClaimAPI,
  sendMagicLinkAPI,
  uploadClaimDocumentsAPI,
} from "../../services"
import { useCallback, useEffect, useMemo, useState } from "react"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors, decryptToken } from "../../utils"
import { useTranslation } from "react-i18next"

import { SignatureDialog } from "../../components"
import { useAppContext, useToast } from "../../contexts"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DrawIcon from "@mui/icons-material/Draw"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
// import SendToMobileIcon from "@mui/icons-material/SendToMobile"
// import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import SaveIcon from "@mui/icons-material/Save"
import { LoadingButton } from "@mui/lab"
import { SuccessButton, SuccessLoadingButton } from "./styled"
import { useWindowDimensions } from "../../hooks"
// import dayjs from "dayjs"

export const DocumentPreviewPage = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const {
    state: { authorization, groupId: stateGroupId },
  } = useAppContext()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { width, height } = useWindowDimensions()

  const isAuthorized = authorization === "authorized"

  const [signType, setSignType] = useState<"worker" | "client" | undefined>()

  const [isExternalSigned, setExternalSigned] = useState(false)

  const [magicLinkType, setMagicLinkType] = useState<
    TMagicLinkType | undefined
  >()

  const claimId = searchParams.get("claimId")?.replace(/ /g, "+")

  const documentType = searchParams.get("documentType") || undefined

  const externalToken = searchParams.get("externalToken")?.replace(/ /g, "+")

  const pluginTokenParam = searchParams.get("token")?.replace(/ /g, "+")

  const groupIdParam = searchParams.get("groupId")?.replace(/ /g, "+")

  const fromLink = searchParams.get("fromLink") === "true"

  const isFromLink = !!state?.isFromLink || fromLink

  const groupId = stateGroupId || groupIdParam

  const shouldPageBeScrollable = height < 650

  const config = useMemo(
    () => ({
      header: {
        disableHeader: true,
      },
      pdfVerticalScrollByDefault: !shouldPageBeScrollable,
      pdfZoom: {
        defaultZoom: width < 450 ? 1.4 : width < 600 ? 1.2 : 1,
        zoomJump: 0.1,
      },
    }),
    [],
  )

  const generateDocument = async (body: FormData) => {
    if (externalToken) {
      return generateDocumentExternalAPI({
        params: { token: externalToken, signedDocument: true },
        body,
      })
    }

    let pluginToken

    try {
      if (pluginTokenParam) {
        pluginToken = await decryptToken(pluginTokenParam)
      }

      return generateDocumentAPI({
        params: {
          claimId,
          documentType,
          signedDocument: true,
        },
        body,
        headers: pluginToken
          ? {
              Authorization: `Bearer ${pluginToken}`,
            }
          : undefined,
      })
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  }

  const isExternal = !!externalToken

  const { data: claim, isLoading: isClaimDetailsLoading } = useQuery({
    queryKey: ["claim-details", claimId],
    queryFn: async () => {
      let pluginToken
      try {
        if (pluginTokenParam) {
          pluginToken = await decryptToken(pluginTokenParam)
        }
        return getClaimAPI({
          id: claimId!,
          params: { groupId: groupId! },
          headers: pluginToken
            ? {
                Authorization: `Bearer ${pluginToken}`,
              }
            : undefined,
        })
      } catch (err) {
        throw new Error(JSON.stringify(err))
      }
    },
    // refetchOnMount: "always",
    enabled: !isExternal && !!claimId && !!groupId && isFromLink,
  })

  const hasEmailSent = useMemo(
    () =>
      !!claim?.magicLinkInformation?.some(
        (i) =>
          i.documentType === documentType &&
          !i.signedOn &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "Email",
      ),
    [claim],
  )

  const hasSMSSent = useMemo(
    () =>
      !!claim?.magicLinkInformation?.some(
        (i) =>
          i.documentType === documentType &&
          !i.signedOn &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "SMS",
      ),
    [claim],
  )

  const [isEmailSent, setEmailSent] = useState(false)

  const [isSMSSent, setSMSSent] = useState(false)

  useEffect(() => {
    if (isFromLink && !isExternal) {
      setEmailSent(hasEmailSent)
      setSMSSent(hasSMSSent)
    }
  }, [hasEmailSent, hasSMSSent])

  const {
    data: documentTemplate,
    isLoading,
    isRefetching,
    isError,
  } = useQuery({
    queryKey: ["document-preview", claimId, documentType],
    queryFn: () => generateDocument(new FormData()),
    refetchOnMount: "always",
    enabled: isExternal || (!!claimId && !!documentType),
  })

  const documentName = documentTemplate?.url?.split("/")?.pop()

  const {
    mutate: signDocument,
    isPending: isDocumentSigning,
    isSuccess: isSignedSuccessfully,
  } = useMutation({
    mutationFn: (body: FormData) => generateDocument(body),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["document-preview", claimId, documentType],
        response,
      )

      setSignType(undefined)

      if (isExternal) {
        setExternalSigned(true)
      } else {
        toast.show(t("documentSignedSuccessMessage"), "success")
      }
    },
  })

  const downloadFile = useCallback(
    async (documentData: IDocumentTemplate) => {
      try {
        const doc = await fetch(documentData.url)
        const blob = await doc.blob()
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(new Blob([blob]))
        link.download = documentName!
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (_) {}
    },
    [documentName],
  )

  // const { mutate: downloadDocument, isPending: isDocumentDownloading } =
  //   useMutation({
  //     mutationFn: () =>
  //       generateDocumentAPI({
  //         params: {
  //           claimId: claimId!,
  //           documentType: documentType!,
  //           signedDocument: true,
  //         },
  //         body: new FormData(),
  //         headers: token
  //           ? {
  //               Authorization: `Bearer ${token}`,
  //             }
  //           : undefined,
  //       }),
  //     onSuccess: async (response) => {
  //       void downloadFile(response)
  //     },
  //   })

  const {
    mutate: uploadDocument,
    isPending: isDocumentUploading,
    isSuccess: isDocumentUploadedSuccessfully,
  } = useMutation({
    mutationFn: (requestParams: IClaimDocumentUploadRequestParams) =>
      uploadClaimDocumentsAPI(requestParams),
    onSuccess: () => {
      toast.show(t("documentSavedSuccessfullyMessage"), "success")

      if (isAuthorized) {
        navigate(`../claims/${claimId}`, {
          state: { isEditModeEnabled: true, jumpToSubsection: documentType },
        })
      }
    },
  })

  const { mutate: sendMagicLink, isPending: isMagicLinkSending } = useMutation({
    mutationFn: async (type: TMagicLinkType) => {
      try {
        let pluginToken

        if (pluginTokenParam) {
          pluginToken = await decryptToken(pluginTokenParam)
        }

        return sendMagicLinkAPI({
          params: {
            claimId: claimId!,
            documentType: documentType!,
            communicationChannel: type,
          },
          headers: pluginToken
            ? {
                Authorization: `Bearer ${pluginToken}`,
              }
            : undefined,
        })
      } catch (err) {
        throw new Error(JSON.stringify(err))
      }
    },
    onMutate: (variables) => {
      setMagicLinkType(variables)
    },
    onSuccess: (_, variables) => {
      if (variables === "Email") {
        setEmailSent(true)
      } else {
        setSMSSent(true)
      }
      toast.show(t("magicLinkSentSuccessfullyMessage"), "success")
    },
    onError: (_, variables) => {
      if (variables === "Email") {
        setEmailSent(false)
      } else {
        setSMSSent(false)
      }
    },
  })

  const shouldShowSave = !isExternal

  const shouldDisableSave =
    (documentTemplate?.isSignableByEmployee &&
      !documentTemplate?.isSignedByEmployee) ||
    (documentTemplate?.isSignableByDamagedParty &&
      !documentTemplate?.isSignedByDamagedParty)

  const isNotSignedByEmployee =
    documentTemplate?.isSignableByEmployee &&
    !documentTemplate?.isSignedByEmployee

  const shouldDisableMagicLink =
    isNotSignedByEmployee || documentTemplate?.isSignedByDamagedParty

  const documents: IDocument[] | undefined = useMemo(() => {
    if (!documentTemplate) {
      return undefined
    }

    return [
      {
        fileName: documentTemplate.documentName,
        fileType: documentTemplate.documentType,
        uri: documentTemplate.url,
      },
    ]
  }, [documentTemplate])

  const onDownloadClick = useCallback(async () => {
    // downloadDocument()
    void downloadFile(documentTemplate!)
  }, [documentTemplate, downloadFile])

  const onWorkerSignClick = useCallback(() => setSignType("worker"), [])

  const onClientSignClick = useCallback(() => setSignType("client"), [])

  const onSignatureDialogClose = useCallback(() => setSignType(undefined), [])

  const onSign = useCallback(
    (blob: Blob) => {
      const formData = new FormData()

      formData.append(
        signType === "worker" ? "employeeSignature" : "damagedPartySignature",
        blob,
      )

      signDocument(formData)
    },
    [signType],
  )

  const onSaveClick = useCallback(async () => {
    try {
      if (claimId && documentType && documentTemplate?.url?.length) {
        const response = await fetch(documentTemplate?.url)
        const blob = await response.blob()

        const file = new File([blob], documentTemplate?.documentName, {
          type: documentTemplate?.documentType,
        })

        const formData = new FormData()
        formData.append("files", file)

        let pluginToken

        if (pluginTokenParam) {
          pluginToken = await decryptToken(pluginTokenParam)
        }

        uploadDocument({
          claimId,
          params: {
            documentType,
            isSignedDocument: true,
          },
          body: formData,
          headers: pluginToken
            ? {
                Authorization: `Bearer ${pluginToken}`,
              }
            : undefined,
        })
      }
    } catch (_) {}
  }, [claimId, documentType, documentTemplate, pluginTokenParam])

  const magicLinkDisabledTooltip = useMemo(() => {
    if (isNotSignedByEmployee) {
      return t("documentSignedByEmployeeValidationMessage")
    } else if (documentTemplate?.isSignedByDamagedParty) {
      return t("documentAlreadySignedByCustomerMessage")
    }

    return undefined
  }, [t, isNotSignedByEmployee, documentTemplate])

  if (isLoading || isRefetching || isClaimDetailsLoading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return (
      <Box className="flex-center">
        <Typography
          className="white-space-pre-line"
          sx={{ whiteSpace: "pre-line" }}
        >
          {t("failedToLoadDocument")}
        </Typography>
      </Box>
    )
  }

  if (!documents?.length) {
    return null
  }

  return (
    <Box display="flex" flex={1} justifyContent="center" className="scroll">
      <SignatureDialog
        isVisible={!!signType}
        loading={isDocumentSigning}
        onConfirm={onSign}
        onClose={onSignatureDialogClose}
      />
      <Box
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        flex={1}
        display="flex"
        flexDirection="column"
        padding="24px"
      >
        {isSignedSuccessfully && isExternalSigned && (
          <Box marginBottom="16px" alignSelf="center">
            <Alert severity="success">
              {t("documentSignedAndCloseMessage")}
            </Alert>
          </Box>
        )}
        <Box display="flex" marginBottom="24px">
          {isAuthorized && (
            <Box marginRight="16px">
              <IconButton
                onClick={() =>
                  navigate(`../claims/${claimId}`, {
                    state: {
                      isEditModeEnabled: true,
                      jumpToSubsection: documentType,
                    },
                  })
                }
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
          )}
          <Typography marginTop="4px" flex={1} variant="h5">
            {documentName}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginBottom="16px" gap="8px">
          <Box flex={1} display="flex" flexWrap="wrap" gap="8px">
            {/* <LoadingButton
            onClick={onDownloadClick}
            variant="outlined"
            disabled={isDocumentDownloading}
            loading={isDocumentDownloading}
          >
            {t("download")}
            <FileDownloadIcon sx={{ marginLeft: "6px" }} />
          </LoadingButton> */}

            {!!documentTemplate?.isSignableByEmployee && (
              <SuccessButton
                success={documentTemplate?.isSignedByEmployee}
                variant="outlined"
                onClick={onWorkerSignClick}
              >
                {!documentTemplate?.isSignableByDamagedParty
                  ? t("sign")
                  : t("clerk")}
                <DrawIcon sx={{ marginLeft: "6px" }} />
              </SuccessButton>
            )}
            {!!documentTemplate?.isSignableByDamagedParty && (
              <SuccessButton
                success={documentTemplate?.isSignedByDamagedParty}
                variant="outlined"
                onClick={onClientSignClick}
                disabled={isEmailSent || isSMSSent}
              >
                {isExternal && !documentTemplate?.isSignableByEmployee
                  ? t("sign")
                  : t("customer")}
                <DrawIcon sx={{ marginLeft: "6px" }} />
              </SuccessButton>
            )}
            {!!documentTemplate?.isEmailAvailable && (
              <Tooltip title={magicLinkDisabledTooltip}>
                <Box display="flex" component="span">
                  <SuccessLoadingButton
                    variant="outlined"
                    success={isEmailSent}
                    onClick={() => sendMagicLink("Email")}
                    loading={magicLinkType === "Email" && isMagicLinkSending}
                    disabled={
                      shouldDisableMagicLink ||
                      isMagicLinkSending ||
                      (isSMSSent && !isEmailSent)
                    }
                  >
                    {t("viaEmail")}
                    <DrawIcon sx={{ marginLeft: "6px" }} />
                  </SuccessLoadingButton>
                </Box>
              </Tooltip>
            )}
            {!!documentTemplate?.isSmsAvailable && (
              <Tooltip title={magicLinkDisabledTooltip}>
                <Box display="flex" component="span">
                  <SuccessLoadingButton
                    variant="outlined"
                    success={isSMSSent}
                    onClick={() => sendMagicLink("SMS")}
                    loading={magicLinkType === "SMS" && isMagicLinkSending}
                    disabled={
                      shouldDisableMagicLink ||
                      isMagicLinkSending ||
                      (isEmailSent && !isSMSSent)
                    }
                  >
                    {t("viaSMS")}
                    <DrawIcon sx={{ marginLeft: "6px" }} />
                  </SuccessLoadingButton>
                </Box>
              </Tooltip>
            )}
            <Button onClick={onDownloadClick} variant="outlined">
              {t("download")}
              <FileDownloadIcon sx={{ marginLeft: "6px" }} />
            </Button>
          </Box>
          {shouldShowSave && (
            <Tooltip
              title={shouldDisableSave ? t("saveSignatureTooltip") : undefined}
            >
              <Box display="flex" component="span">
                <LoadingButton
                  loading={isDocumentUploading}
                  disabled={
                    isDocumentUploading ||
                    isDocumentUploadedSuccessfully ||
                    shouldDisableSave
                  }
                  onClick={onSaveClick}
                >
                  {t("save")}
                  <SaveIcon sx={{ marginLeft: "6px" }} />
                </LoadingButton>
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box
          flex={1}
          display="grid"
          borderRadius="8px"
          border={`1px solid ${colors.primary}`}
          padding="4px"
          className={!shouldPageBeScrollable ? "scroll" : ""}
          bgcolor={colors.white}
          maxWidth="100%"
          sx={shouldPageBeScrollable ? { aspectRatio: 0.5 } : undefined}
        >
          <DocViewer
            key={documents[0].uri}
            documents={documents}
            pluginRenderers={DocViewerRenderers}
            config={config}
          />
        </Box>
      </Box>
    </Box>
  )
}
