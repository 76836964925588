import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getCostTableChangesAPI } from "../../../../../../services"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import {
  CustomCostTableCell,
  CustomRow,
  CustomTableContainer,
} from "../../styled"
import { type IFilters } from "../../../../DashboardPage"
import { colors, TYPE_OF_COSTS_TRANSLATIONS } from "../../../../../../utils"

const renderValue = (key: keyof ICostTableValue, value: any, t: any) => {
  if (value === null || value === undefined) return "N/A"
  if (key === "financialSupport" || key === "payment") {
    if (value === 0) return "N/A"
  }
  if (key === "damageType" && typeof value === "string") {
    return t(TYPE_OF_COSTS_TRANSLATIONS[value]) || value
  }
  if (key === "status") {
    return t(costStatusMapping[value]) || "N/A"
  }
  return String(value)
}

const isNotNA = (oldValue: any, newValue: any, key: any) => {
  if (key === "financialSupport" || key === "payment") {
    return !(oldValue === "N/A" && newValue === "N/A")
  }
  return !(oldValue === "N/A" && newValue === "N/A")
}

const costStatusMapping: Record<number, string> = {
  0: "Settled",
  1: "InProgress",
  2: "UnderNegotiation",
}

export const CostTableChanges: React.FC<{
  claimId: string
  filters?: IFilters
}> = ({ claimId, filters }) => {
  const { t } = useTranslation()
  const {
    data: costTableChanges,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["costTableChanges", claimId, filters?.from, filters?.to],
    queryFn: () =>
      getCostTableChangesAPI({ claimId, from: filters?.from, to: filters?.to }),
    refetchOnMount: true,
  })

  const keys: Array<keyof ICostTableValue> = [
    "damageType",
    "financialSupport",
    "payment",
    "byLegalSituation",
    "status",
  ]

  return (
    <CustomTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomCostTableCell>{t("property")}</CustomCostTableCell>
            <CustomCostTableCell>{t("oldValue")}</CustomCostTableCell>
            <CustomCostTableCell>{t("newValue")}</CustomCostTableCell>
            <CustomCostTableCell>{t("changesBy")}</CustomCostTableCell>
            <CustomCostTableCell>{t("date")}</CustomCostTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading || isRefetching ? (
            <>
              {Array.from({ length: 3 }, (_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : costTableChanges?.length ? (
            costTableChanges.map((change, index) => {
              const significantRows = keys.filter((key) => {
                const oldValue = renderValue(key, change.oldValue?.[key], t)
                const newValue = renderValue(key, change.newValue[key], t)
                return isNotNA(oldValue, newValue, key)
              })

              if (!significantRows.length) return null

              return significantRows.map((key, rowIndex) => {
                const oldValue = renderValue(key, change.oldValue?.[key], t)
                const newValue = renderValue(key, change.newValue[key], t)
                const isSameDamageType =
                  key === "damageType" && oldValue === newValue

                const isLastRow = rowIndex === significantRows.length - 1

                return (
                  <CustomRow
                    key={`${index}-${String(key)}`}
                    style={{
                      borderBottom: isLastRow
                        ? `4px solid ${colors.gray2}`
                        : "",
                    }}
                  >
                    <CustomCostTableCell>{t(key)}</CustomCostTableCell>
                    {isSameDamageType ? (
                      <CustomCostTableCell
                        colSpan={2}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="inherit">{oldValue}</Typography>
                      </CustomCostTableCell>
                    ) : (
                      <>
                        <CustomCostTableCell>
                          <Typography variant="inherit">{oldValue}</Typography>
                        </CustomCostTableCell>
                        <CustomCostTableCell>
                          <Typography variant="inherit">{newValue}</Typography>
                        </CustomCostTableCell>
                      </>
                    )}
                    {rowIndex === 0 && (
                      <>
                        <CustomCostTableCell rowSpan={significantRows.length}>
                          {change?.changesBy || "N/A"}
                        </CustomCostTableCell>
                        <CustomCostTableCell rowSpan={significantRows.length}>
                          {dayjs(change.modifiedAt).format("DD.MM.YYYY hh:mm")}
                        </CustomCostTableCell>
                      </>
                    )}
                  </CustomRow>
                )
              })
            })
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {t("noData")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  )
}
