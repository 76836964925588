import { useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import { StatisticWidget } from "../StatisticWidget"
import { calculateGrowthPercentage } from "../../../../utils"
import { type IFilters } from "../../DashboardPage"
import { createClaimAPI, getStatisticsAPI } from "../../../../services"
import { GetStartedButton } from "../../styled"
import { useAppContext } from "../../../../contexts"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { useNavigate } from "react-router-dom"

interface IProps {
  filters: IFilters
  selectedGroups: string[]
  widgetVisibility: DashboardWidgetVisibility | null
}

export const WidgetsGrid = (props: IProps) => {
  const { filters, selectedGroups, widgetVisibility } = props
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const groupIds =
    selectedGroups.length > 0 ? selectedGroups : appState?.groupId!

  const hasPermissionToViewTotalClaims = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) => p.action === "DASHBOARD_TOTAL_CLAIMS" && p.access !== "None",
      ),
    [user],
  )

  const hasPermissionToViewOpenClaims = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) => p.action === "DASHBOARD_OPEN_CLAIMS" && p.access !== "None",
      ),
    [user],
  )

  const hasPermissionToViewSubmittedClaims = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) => p.action === "DASHBOARD_SUBMITTED_CLAIMS" && p.access !== "None",
      ),
    [user],
  )

  const hasPermissionToViewTotalUsers = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) => p.action === "DASHBOARD_USERS" && p.access !== "None",
      ),
    [user],
  )

  const hasPermissionToViewNewUsers = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) => p.action === "USERS" && p.access !== "None",
      ),
    [user],
  )

  const hasPermissionToViewSomeWidget =
    hasPermissionToViewTotalClaims ||
    hasPermissionToViewOpenClaims ||
    hasPermissionToViewSubmittedClaims ||
    hasPermissionToViewTotalUsers

  const {
    data: statistics,
    isLoading: areStatisticsLoading,
    isRefetching: areStatisticsRefetching,
  } = useQuery<IStatistics>({
    queryKey: ["statistics", filters, groupIds],
    queryFn: () => getStatisticsAPI({ groupIds, ...filters }),
    refetchOnMount: true,
    enabled: hasPermissionToViewSomeWidget,
  })

  const { mutate: createClaim, isPending: isClaimCreating } = useMutation({
    mutationFn: () =>
      createClaimAPI({ body: {}, params: { groupId: appState?.groupId! } }),
    onSuccess: (response) => {
      queryClient.setQueryData(["claim-details", response.id], response)
      navigate(`/claims/${response.id}`, {
        state: { isEditModeEnabled: true, shouldSetDefaultValues: true },
      })
    },
  })

  return (
    <Grid container spacing="16px">
      {widgetVisibility?.isShowingTotalClaims &&
        hasPermissionToViewTotalClaims && (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <StatisticWidget
              label={t("totalClaims")}
              value={statistics?.claims?.total?.toString() ?? "-"}
              loading={areStatisticsLoading || areStatisticsRefetching}
              bottomContent={
                !statistics?.claims?.total ? (
                  <GetStartedButton
                    variant="outlined"
                    size="small"
                    disabled={isClaimCreating}
                    onClick={() => createClaim()}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                    {t("getStartedNow")}
                  </GetStartedButton>
                ) : undefined
              }
              growthPercentage={calculateGrowthPercentage(
                statistics?.claims?.previous,
                statistics?.claims?.total,
              )}
            />
          </Grid>
        )}
      {widgetVisibility?.isShowingOpenClaims &&
        hasPermissionToViewOpenClaims && (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <StatisticWidget
              label={t("openClaims")}
              value={statistics?.openClaims?.total?.toString() ?? "-"}
              growthPercentage={calculateGrowthPercentage(
                statistics?.openClaims?.previous,
                statistics?.openClaims?.total,
              )}
              loading={areStatisticsLoading || areStatisticsRefetching}
            />
          </Grid>
        )}
      {widgetVisibility?.isShowingSubmittedClaims &&
        hasPermissionToViewSubmittedClaims && (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <StatisticWidget
              label={t("submittedClaims")}
              value={statistics?.submittedClaims?.total?.toString() ?? "-"}
              growthPercentage={calculateGrowthPercentage(
                statistics?.submittedClaims?.previous,
                statistics?.submittedClaims?.total,
              )}
              loading={areStatisticsLoading || areStatisticsRefetching}
            />
          </Grid>
        )}
      {widgetVisibility?.isShowingTotalUsers &&
        hasPermissionToViewTotalUsers && (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <StatisticWidget
              label={t("totalUsers")}
              value={statistics?.users?.total?.toString() ?? "-"}
              growthPercentage={calculateGrowthPercentage(
                statistics?.users?.previous,
                statistics?.users?.total,
              )}
              loading={areStatisticsLoading || areStatisticsRefetching}
            />
          </Grid>
        )}
      {widgetVisibility?.isShowingNewUsers && hasPermissionToViewNewUsers && (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <StatisticWidget
            label={t("newUsers")}
            value={statistics?.newUsers?.total?.toString() ?? "-"}
            growthPercentage={calculateGrowthPercentage(
              statistics?.newUsers?.previous,
              statistics?.newUsers?.total,
            )}
            loading={areStatisticsLoading || areStatisticsRefetching}
          />
        </Grid>
      )}
    </Grid>
  )
}
