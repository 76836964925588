import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import {
  type UseFormReturn,
  type FieldValues,
  useFieldArray,
  Controller,
  useWatch,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  colors,
  generatePythonExpression,
  IF_ELSE_CONFIG_INITIAL_DATA,
} from "../../../../../../utils"
import AddIcon from "@mui/icons-material/Add"
import { useCallback, useEffect, useMemo } from "react"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { RemoveButton } from "./styled"
import { type Node } from "reactflow"
import { getGetDataStepData } from "../../utils"

interface IProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  methods: UseFormReturn<FieldValues, any, undefined>
  selectedAction: INodeItem
  nodes: Node[]
}

export const IfElseStepConfig = (props: IProps) => {
  const { handleChange, methods, selectedAction, nodes } = props
  const { t } = useTranslation()

  const { control } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "Condition.data",
  })

  const condition = useWatch({ control, name: "Condition" })

  const selectedFields: Record<string, boolean> = useMemo(() => {
    if (nodes?.length) {
      const getStepData = getGetDataStepData(nodes)
      let selFields = getStepData?.inputs?.SelectedFields
      selFields = Object.keys(selFields || {}).reduce(
        (acc: Record<string, boolean>, key: string) => {
          acc[key] = selFields[key] === "true" || selFields[key] === true
          return acc
        },
        {},
      )
      if (selFields) {
        return selFields
      } else {
        return undefined
      }
    }

    return []
  }, [nodes])

  useEffect(() => {
    const validConditions = condition?.data?.filter(
      (c: any) => !!c.prop && !!c.condition,
    )

    if (validConditions?.length) {
      const expression = generatePythonExpression(
        validConditions,
        condition.logic,
      )

      handleChange(selectedAction, "input", expression, "Condition")
    }
  }, [condition])

  const onAddClick = useCallback(
    () => append(IF_ELSE_CONFIG_INITIAL_DATA.data),
    [],
  )

  const onRemoveClick = useCallback((index: number) => remove(index), [])

  return (
    <Box display="flex" flexDirection="column">
      <Controller
        control={control}
        name="Condition.logic"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            row
            defaultValue="all"
            value={value ?? "all"}
            onChange={onChange}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label={t("matchAll")}
            />
            <FormControlLabel
              value="any"
              control={<Radio />}
              label={t("matchAny")}
            />
          </RadioGroup>
        )}
      />

      <Box marginTop="8px" display="flex" flexDirection="column" gap="16px">
        {fields?.map((field, index) => (
          <Box
            key={field.id}
            position="relative"
            display="flex"
            flexDirection="column"
            padding="8px"
            border={`1px solid ${colors.gray10}`}
            borderRadius="8px"
          >
            {fields.length > 1 && (
              <RemoveButton
                size="small"
                color="error"
                onClick={() => onRemoveClick(index)}
              >
                <DeleteOutlineIcon />
              </RemoveButton>
            )}
            <Typography marginBottom="-4px" variant="regularBold">
              {t("if")}
            </Typography>
            <Controller
              control={control}
              name={`Condition.data.${index}.prop`}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth margin="normal" size="medium">
                  <InputLabel>{t("prop")}</InputLabel>
                  <Select
                    value={value ?? ""}
                    onChange={onChange}
                    label={t("prop")}
                  >
                    {selectedFields &&
                      Object.keys(selectedFields).map(
                        (key) =>
                          selectedFields[key] && (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          ),
                      )}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name={`Condition.data.${index}.condition`}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth margin="normal" size="medium">
                  <InputLabel>{t("condition")}</InputLabel>
                  <Select
                    value={value ?? ""}
                    onChange={onChange}
                    label={t("condition")}
                  >
                    <MenuItem value="isEqual">{t("isEqual")}</MenuItem>
                    <MenuItem value="isGreater">{t("isGreater")}</MenuItem>
                    <MenuItem value="isLess">{t("isLess")}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Box marginTop="16px" />
            <Controller
              control={control}
              name={`Condition.data.${index}.value`}
              render={({ field: { value, onChange } }) => (
                <TextField
                  fullWidth
                  value={value ?? ""}
                  onChange={onChange}
                  label="Value"
                />
              )}
            />
          </Box>
        ))}
      </Box>
      <Box marginTop="16px" display="flex" justifyContent="flex-end">
        <IconButton onClick={onAddClick} color="primary">
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
