import { type Dispatch, type SetStateAction } from "react"
import ReactFlow, {
  Background,
  Controls,
  type Edge,
  MiniMap,
  type Node,
  type OnNodesChange,
  type OnEdgesChange,
} from "reactflow"
import "./index.css"
import {
  ActionCard,
  AddButtonEdge,
  BooleanCard,
  DoubleBranchCard,
  DropzoneCard,
  TripleBranchCard,
} from "./components"
import { mapNodeItem } from "../../utils"

interface IProps {
  onCardSelect: (item: INodeItem | null) => void
  children: any
  selectedActionId: string | undefined
  nodeOptions: {
    nodes: Node[]
    onNodesChange: OnNodesChange
    setNodes: Dispatch<SetStateAction<Array<Node<any, string | undefined>>>>
  }
  edgeOptions: {
    edges: Edge[]
    onEdgesChange: OnEdgesChange
    setEdges: Dispatch<SetStateAction<Array<Edge<any>>>>
  }
}

const nodeTypes = {
  actionCard: ActionCard,
  boolCard: BooleanCard,
  dropzoneCard: DropzoneCard,
  doubleBranchCard: DoubleBranchCard,
  tripleBranchCard: TripleBranchCard,
}

const edgeTypes = {
  addButtonEdge: AddButtonEdge,
}

export const CanvasMap = (props: IProps) => {
  const {
    onCardSelect,
    children,
    selectedActionId,
    nodeOptions: { nodes, onNodesChange },
    edgeOptions: { edges, onEdgesChange },
  } = props

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      deleteKeyCode={[]}
      onEdgesChange={onEdgesChange}
      onClick={(_ev) => {
        const className = (_ev.target as any)?.className || ""
        if (
          typeof className === "string" &&
          className.includes("react-flow__pane")
        ) {
          onCardSelect(null)
        }
      }}
      onNodeClick={(_event, node) =>
        node.type !== "dropzoneCard"
          ? onCardSelect(mapNodeItem(node))
          : onCardSelect(null)
      }
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={false}
      onlyRenderVisibleElements
      fitView
    >
      <MiniMap pannable zoomable />
      <Controls showInteractive={false} />
      <Background />
      {selectedActionId && children}
    </ReactFlow>
  )
}
