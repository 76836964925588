import React from "react"
import {
  Box,
  Typography,
  Switch,
  IconButton,
  Popover,
  Divider,
  Skeleton,
} from "@mui/material"
import { ReactComponent as EditIcon } from "../../../../assets/icons/EditWidgets.svg"
import { useTranslation } from "react-i18next"

interface DashboardWidgetVisibility {
  isShowingTotalClaims: boolean
  isShowingOpenClaims: boolean
  isShowingSubmittedClaims: boolean
  isShowingTotalUsers: boolean
  isShowingNewUsers: boolean
  isShowingActivitySummary: boolean
  isShowingOpenActivities: boolean
  isShowingClaimsOutOfSync: boolean
  isShowingMalfunctionsWidget: boolean
  isShowingNewsWidget: boolean
}

interface EditWidgetsPopupProps {
  toggles: DashboardWidgetVisibility | null
  onToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isUpdating: boolean
}

export const EditWidgetsPopup: React.FC<EditWidgetsPopupProps> = ({
  toggles,
  onToggleChange,
  isUpdating,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box padding="20px">
          <Typography variant="largeBold">{t("editWidgetsCard")}</Typography>
          <Divider />
          {toggles ? (
            Object.keys(toggles).map((key) => {
              return (
                <Box
                  key={key}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my="10px"
                >
                  <Typography>{t(key)}</Typography>
                  <Switch
                    checked={toggles[key as keyof DashboardWidgetVisibility]}
                    onChange={onToggleChange}
                    name={key}
                    disabled={isUpdating}
                  />
                </Box>
              )
            })
          ) : (
            <Box>
              {[...Array(10)].map((_, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my="10px"
                >
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="32px" height="24px" />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  )
}
