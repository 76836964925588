import React, { useCallback, useEffect } from "react"
import { TableCell, Collapse, Button, Typography, Box } from "@mui/material"
import dayjs from "dayjs"
import {
  CellWrapper,
  CollapsibleRow,
  CustomRow,
  DocPreviewWrapper,
} from "./styled"
import { useTranslation } from "react-i18next"
import {
  AttachEmail,
  CalendarMonthOutlined,
  Email,
  Plagiarism,
} from "@mui/icons-material"
import { colors, determineFileType } from "../../../../../../../../utils"
import { getGlobalInboxAttachmentsAPI } from "../../../../../../../../services"
import { useQuery } from "@tanstack/react-query"
import DocViewer, {
  DocViewerRenderers,
  type IDocument,
} from "@cyntler/react-doc-viewer"
import { EmailBodyAndActions } from "./components"

interface IProps {
  rowDetails: IGlobalInboxItemDetail
  emailDetails: IGlobalInboxItemEmail
  isExpanded: boolean
  isDisabled: boolean
  onRowClick: () => void
  onRowClose: () => void
}

export const EnhancedTableRow = (props: IProps) => {
  const {
    rowDetails,
    emailDetails,
    isExpanded,
    isDisabled,
    onRowClick,
    onRowClose,
  } = props
  const { t } = useTranslation()
  const { attachmentCount } = rowDetails
  const { folderPath } = emailDetails

  const {
    data: attachments,
    isPending,
    isRefetching,
    refetch: refetchAttachments,
  } = useQuery({
    queryKey: ["global-inbox-attachments"],
    queryFn: () => getGlobalInboxAttachmentsAPI({ folderPath }),
    enabled: false,
  })

  const handleAttachmentPreviewClick = useCallback(
    (doc: IGlobalInboxAttachment) => {
      const { url, name } = doc
      setDocuments([
        { uri: url, fileName: name, fileType: determineFileType(name) },
      ])
    },
    [],
  )

  const [documents, setDocuments] = React.useState<IDocument[]>([])

  useEffect(() => {
    if (isExpanded && attachmentCount > 0) {
      void refetchAttachments()
    }
  }, [isExpanded])

  useEffect(() => {
    if (
      isExpanded &&
      attachments &&
      attachments?.inboxAttachments?.length > 0
    ) {
      handleAttachmentPreviewClick(attachments.inboxAttachments[0])
    } else {
      setDocuments([])
    }
  }, [attachments, isExpanded])

  const handleUpdateDocuments = useCallback((documents: IDocument[]) => {
    setDocuments(documents)
  }, [])

  return (
    <React.Fragment>
      <CustomRow
        onClick={onRowClick}
        open={isExpanded}
        disabled={isDisabled}
        className={isDisabled ? "disabled-effect" : ""}
      >
        <TableCell className="word-break-all">
          <CellWrapper>
            {rowDetails.attachmentCount > 0 ? (
              <AttachEmail fontSize="small" htmlColor={colors.gray9} />
            ) : (
              <Email fontSize="small" htmlColor={colors.gray9} />
            )}
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginRight="4px"
            >
              {emailDetails.createdAt
                ? dayjs(emailDetails?.createdAt).format("DD.MM.YYYY")
                : "-"}
            </Typography>
            <CalendarMonthOutlined fontSize="small" />
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {emailDetails.createdAt
                ? dayjs(emailDetails?.createdAt).format("HH:mm:ss")
                : "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {rowDetails.sender ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {rowDetails.caseNumber?.trim() ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-2"
              color={colors.gray9}
              marginLeft="4px"
            >
              {emailDetails.rubrum ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {emailDetails.wegen ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell>
          <Typography
            variant="smallBold"
            className="line-clamp-1"
            color={colors.gray9}
            marginRight="24px"
            align="right"
          >
            M
          </Typography>
        </TableCell>
      </CustomRow>
      <CollapsibleRow open={isExpanded}>
        <TableCell colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box
              display="flex"
              justifyContent="space-between"
              height="75vh"
              gap="16px"
              marginBottom="12px"
            >
              <EmailBodyAndActions
                rowDetails={rowDetails}
                attachments={attachments!}
                documents={documents}
                isLoading={isPending || isRefetching}
                onUpdateDocuments={handleUpdateDocuments}
              />
              {attachmentCount > 0 && (
                <DocPreviewWrapper>
                  {documents.length > 0 ? (
                    <DocViewer
                      key={`${documents[0].uri}-${Math.random()
                        .toString(36)
                        .substr(2, 9)}`}
                      initialActiveDocument={documents[0] ?? undefined}
                      documents={documents}
                      pluginRenderers={DocViewerRenderers}
                      className="doc-preview-overscroll"
                      config={{
                        header: {
                          disableHeader: true,
                          retainURLParams: true,
                        },
                        pdfVerticalScrollByDefault: true,
                      }}
                    />
                  ) : (
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      gap="16px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Plagiarism htmlColor={colors.gray9} fontSize="large" />
                      {t("selectFileToPreview")}
                    </Box>
                  )}
                </DocPreviewWrapper>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              borderTop={`1px solid ${colors.gray5}`}
              paddingTop="8px"
            >
              <Button onClick={onRowClose} size="small">
                {t("close")}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </CollapsibleRow>
    </React.Fragment>
  )
}
