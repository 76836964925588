export const getFiltersCount = (filters: any) => {
  let count = 0

  if (filters.source) count++
  if (filters.startDate || filters.endDate) count++
  if (filters.from) count++
  if (filters.caseNumber) count++
  if (filters.description) count++
  if (filters.reasoning) count++
  if (filters.workflow) count++

  return count
}

export const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (orderBy === "liability") {
    const [statusA, progressA] = [a.liability, a.liabilityQuota]
    const [statusB, progressB] = [b.liability, b.liabilityQuota]

    const statusOrder: Record<string, number> = {
      Agreed: 0,
      Unknown: 1,
      Denied: 2,
    }

    const statusComparison = statusOrder[statusA] - statusOrder[statusB]

    if (statusComparison !== 0) {
      return statusComparison
    }

    return parseInt(progressB || "0") - parseInt(progressA || "0")
  } else {
    const valueA = a[orderBy]?.toLowerCase() ?? ""
    const valueB = b[orderBy]?.toLowerCase() ?? ""

    if (valueB < valueA) {
      return -1
    }
    if (valueB > valueA) {
      return 1
    }
    return 0
  }
}

export const getComparator = <Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key,
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

export const stableSort = <T>(
  array: readonly IGlobalInboxItemDetail[],
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis?.map((el) => el[0])
}
