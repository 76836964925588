import { Box, useMediaQuery, useTheme } from "@mui/material"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../../../../utils"
import { SideBar, CompactSideBar } from "./components"
import { useTranslation } from "react-i18next"
import PersonIcon from "@mui/icons-material/Person"
import KeyIcon from "@mui/icons-material/Key"
import ApartmentIcon from "@mui/icons-material/Apartment"
import SettingsIcon from "@mui/icons-material/Settings"
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined"
import { useMemo } from "react"

interface IProps {
  children: React.ReactNode
}

export const SettingsLayout = (props: IProps) => {
  const { children } = props
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isGreaterThanSM = useMediaQuery(breakpoints.up("sm"))

  const routes = useMemo(
    () => [
      {
        path: "my-profile",
        label: t("myProfile"),
        icon: <PersonIcon fontSize="small" />,
      },
      {
        path: "security",
        label: t("security"),
        icon: <KeyIcon fontSize="small" />,
      },
      {
        path: "company-details",
        label: t("companyDetails"),
        icon: <ApartmentIcon fontSize="small" />,
      },
      {
        path: "login-settings",
        label: t("loginSettings"),
        icon: <SettingsIcon fontSize="small" />,
      },
      {
        path: "signature",
        label: t("signatureSettings"),
        icon: <DriveFileRenameOutlineOutlinedIcon fontSize="small" />,
      },
    ],
    [t],
  )

  return (
    <Box
      className="scroll"
      display="flex"
      flex={1}
      bgcolor={colors.white}
      justifyContent="center"
      padding="24px"
    >
      <Box
        flex={1}
        display="flex"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        border={`1px solid ${colors.gray4}`}
      >
        {isGreaterThanSM ? (
          <SideBar routes={routes} />
        ) : (
          <CompactSideBar routes={routes} />
        )}
        {children}
      </Box>
    </Box>
  )
}
