import { Autocomplete, Button, styled } from "@mui/material"

export const CustomAutocomplete = styled(Autocomplete)`
  width: 200px;

  .MuiFilledInput-root {
    padding: 0;
  }

  .MuiInputBase-root {
    height: 40px;
    padding-left: 8px;
  }
`

export const EmailBodyButton = styled(Button)`
  max-width: fit-content;
  margin-bottom: 16px;
`
