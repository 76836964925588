import { useCallback } from "react"
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormWatch,
} from "react-hook-form"
import {
  Box,
  Checkbox,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import {
  EURO_SIGN,
  checkAllConditionsVisibility,
  colors,
  convertToRoman,
} from "../../../../utils"
import { SectionBox } from "./styled"
import {
  AutocompleteController,
  PhoneNumberController,
  PlaceController,
} from "../StepForm/components"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { getRules } from "../StepForm/utils"

interface IProps {
  step: IClaimTemplateStep
  formControl: Control<FieldValues, any>
  disabled: boolean
  collisionColor: string
  watch: UseFormWatch<FieldValues>
  conflictedSectionIds?: string[]
}

export const ComparisonForm = (props: IProps) => {
  const {
    step,
    disabled,
    formControl,
    collisionColor,
    watch,
    conflictedSectionIds,
  } = props
  const { t } = useTranslation()

  const renderField = useCallback(
    (field: IClaimTemplateMetadata) => {
      const rules = getRules(field, t, watch)
      const isDisabled = disabled

      const label = field.label

      switch (field.fieldType) {
        case "Text":
        case "Number":
        case "Email":
        case "TextArea":
        case "IBAN": {
          const isTextArea = field.fieldType === "TextArea"
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <Controller
                control={formControl}
                name={field.controlName}
                rules={rules}
                render={({ field: { value }, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    multiline={isTextArea}
                    rows={4}
                    value={value ?? ""}
                    type={
                      field.fieldType === "IBAN"
                        ? "text"
                        : field.fieldType?.toString()?.toLowerCase()
                    }
                    label={label}
                    disabled={isDisabled}
                    error={!!error?.message}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )
        }
        case "Checkbox": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <Controller
                control={formControl}
                name={field.controlName}
                rules={rules}
                render={({ field: { value }, fieldState: { error } }) => {
                  const options = field?.selectionOptions?.options?.filter(
                    (o) => {
                      if (o.conditionField) {
                        const val = watch(o.conditionField)
                        return val === o.conditionValue
                      }

                      return true
                    },
                  )

                  return (
                    <>
                      <Box
                        display="flex"
                        flexDirection={options?.length === 1 ? "row" : "column"}
                        alignItems={
                          options?.length === 1 ? "center" : "flex-start"
                        }
                      >
                        {(!!field.label || !!field.rules?.isRequired) &&
                          !!options?.length &&
                          options.length > 1 && (
                            <Typography
                              marginBottom="4px"
                              variant="smallMedium"
                            >
                              {`${field.label || t("selectAnOptionPrompt")}${
                                field.rules?.isRequired ? "*" : ""
                              }`}
                            </Typography>
                          )}
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          flexDirection="row"
                          gap="16px"
                        >
                          {options?.map((option, index) => {
                            const isSingleChoice =
                              field.selectionOptions
                                .numberOfAllowedSelections === 1

                            const isChecked = isSingleChoice
                              ? value === option.value
                              : value?.includes(option.value)

                            return (
                              <Box
                                key={index}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                              >
                                <Checkbox
                                  checked={isChecked}
                                  disabled={isDisabled}
                                />
                                <Typography
                                  marginLeft="8px"
                                  color={colors.black}
                                >
                                  {option.label}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                        {options?.length === 1 && (
                          <Typography marginLeft="8px" variant="small">
                            {field.label}
                          </Typography>
                        )}
                      </Box>
                      {!!error?.message && (
                        <FormHelperText className="Mui-error">
                          {error.message}
                        </FormHelperText>
                      )}
                    </>
                  )
                }}
              />
            </Grid>
          )
        }
        case "Date": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <Controller
                control={formControl}
                name={field.controlName}
                rules={rules}
                render={({ field: { value }, fieldState: { error } }) => (
                  <DatePicker
                    className="width-100"
                    label={label}
                    format="DD.MM.YYYY"
                    value={value ? dayjs(value) : null}
                    onClose={() => {}}
                    disabled={isDisabled}
                    slotProps={{
                      textField: {
                        error: !!error?.message,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )
        }
        case "Time": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <Controller
                control={formControl}
                name={field.controlName}
                rules={rules}
                render={({ field: { value }, fieldState: { error } }) => (
                  <TimePicker
                    className="width-100"
                    label={label}
                    ampm={false}
                    timeSteps={{ minutes: 1 }}
                    value={value ? dayjs(value) : null}
                    onClose={() => {}}
                    disabled={isDisabled}
                    slotProps={{
                      textField: {
                        error: !!error?.message,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )
        }
        case "Insurer": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                options={undefined}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "InsurerLegalExpenses": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                options={undefined}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "MotorVehicleExpert": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                options={undefined}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                onFieldFocus={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "Salutation": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                options={undefined}
                disabled={isDisabled}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "Manufacturer": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "LessorLeasing": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "LessorFunding": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <AutocompleteController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                areOptionsLoading={false}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "PhoneNumber": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <PhoneNumberController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "City": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <PlaceController
                formControl={formControl}
                field={{
                  ...field,
                  label,
                }}
                disabled={isDisabled}
                onFieldBlur={() => {}}
                rules={rules}
              />
            </Grid>
          )
        }
        case "Currency": {
          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={12}
              md={12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <Controller
                control={formControl}
                name={field.controlName}
                rules={rules}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    value={value ?? ""}
                    type="number"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="large">{EURO_SIGN}</Typography>
                        </InputAdornment>
                      ),
                    }}
                    label={label}
                    disabled={isDisabled}
                  />
                )}
              />
            </Grid>
          )
        }
        default: {
          return null
        }
      }
    },
    [disabled, step.stepType],
  )

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Typography variant="h5" color={colors.black} marginLeft="18px">
        {step.name}
      </Typography>
      {!!step.description && (
        <Typography marginLeft="18px">{step.description}</Typography>
      )}
      <Box marginTop="32px" />
      {step.sections.map((section, sectionIndex) => (
        <Box key={section.id} marginTop={sectionIndex > 0 ? "32px" : "0px"}>
          {!!section.name && (
            <Typography marginLeft="18px">
              {`${convertToRoman(
                +(section.section?.split(".")?.[0] || "0"),
              )}. ${section.name}`}
            </Typography>
          )}
          {section.subSections.map((subSection, subSectionIndex) => {
            return (
              <SectionBox
                key={subSectionIndex}
                sectionIndex={subSectionIndex}
                sectionHasConflicts={
                  !!conflictedSectionIds?.includes(subSection.id)
                }
                indicatingColor={collisionColor}
                padding="6px 18px"
              >
                {(!!subSection.name || !!subSection.description) && (
                  <Typography>
                    {!!subSection.name && (
                      <Typography marginLeft="4px" variant="regularSemiBold">
                        {`${subSection.section?.slice(2)}. ${subSection.name} `}
                      </Typography>
                    )}
                    {!!subSection.description && (
                      <Typography variant="small">
                        {subSection.description}
                      </Typography>
                    )}
                  </Typography>
                )}

                {!!subSection?.metadatas?.length && (
                  <Box display="flex" marginTop="8px">
                    <Grid container spacing="16px">
                      {subSection?.metadatas?.map((e) => {
                        const shouldBeVisible = checkAllConditionsVisibility(
                          e.conditions,
                          watch,
                        )

                        if (!shouldBeVisible) {
                          return null
                        }

                        return <>{renderField(e)}</>
                      })}
                    </Grid>
                  </Box>
                )}
              </SectionBox>
            )
          })}
        </Box>
      ))}
      <Box paddingBottom="16px" />
    </Box>
  )
}
