import { Button as ButtonMui, Typography, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IButtonProps {
  status: TCostPositionStatus
}

export const Button = styled(ButtonMui)<IButtonProps>`
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  padding: 0px !important;
  box-shadow: none;
  min-width: 0px !important;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  & .MuiTypography-root {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap !important;
    text-align: left;
    color: ${colors.black2};
  }

  &.Mui-disabled {
    background-color: transparent;
  }
`

export const QuotaTypography = styled(Typography)`
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 2px 4px;
`
