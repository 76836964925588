import { Box, Typography, CircularProgress } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { colors } from "../../../../../../utils"
import {
  getChannelConfigurationsAPI,
  getDocTypesAPI,
} from "../../../../../../services"
import { DocumentScannerSharp, Email, Link } from "@mui/icons-material"
import { useEffect } from "react"
import { Button, CardContainer, Header, Wrapper } from "./styled"

interface IProps {
  triggerType: TChannelTypeName
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  selectedAction: INodeItem
}

const CHANNEL_ID = "ChannelId"
const CHANNEL_TYPE = "ChannelType"
const DOC_TYPE = "DocType"

export const TriggerConfig = (props: IProps) => {
  const { triggerType, handleChange, selectedAction } = props

  const {
    data: channelConfigurations,
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: () => getChannelConfigurationsAPI(triggerType),
    refetchOnMount: true,
  })

  const {
    data: docTypes,
    isPending: isPendingDocTypes,
    isRefetching: isRefetchingDocTypes,
  } = useQuery({
    queryKey: ["doc-types"],
    queryFn: () => (triggerType === "Email" ? getDocTypesAPI() : []),
    refetchOnMount: true,
  })

  useEffect(() => {
    handleChange(selectedAction, "input", triggerType, CHANNEL_TYPE)
  }, [triggerType])

  const onSelectConfigurationHandler = (config: IChannelConfiguration) => {
    const isChannelSelected =
      selectedAction?.inputs?.[CHANNEL_ID] === `'${config.id}'`
    const configId = isChannelSelected ? "" : config.id
    handleChange(selectedAction, "input", configId, CHANNEL_ID)
  }

  const onSelectDocumentHandler = (document: IDocType) => {
    const isDocSelected =
      selectedAction.inputs?.[DOC_TYPE] === `'${document.docType}'`
    const docType = isDocSelected ? "" : document.docType
    handleChange(selectedAction, "input", docType, DOC_TYPE)
  }

  const TriggerIcon = () => {
    switch (triggerType) {
      case "Email":
        return <Email style={{ fontSize: "50px", color: colors.black }} />
      case "Interface":
        return <Link style={{ fontSize: "50px", color: colors.black }} />

      default:
        return <Link style={{ fontSize: "50px", color: colors.black }} />
    }
  }

  const isLoadingChannelConfigs =
    isPendingConfigurations || isRefetchingConfigurations

  const isLoadingDocTypes = isPendingDocTypes || isRefetchingDocTypes

  return (
    <Box>
      {isLoadingChannelConfigs ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Header>
            <Typography variant="largeMedium">Channels</Typography>
          </Header>
          <Wrapper>
            {channelConfigurations?.map((ch) => {
              const isChannelSelected =
                selectedAction?.inputs?.[CHANNEL_ID] === `'${ch.id}'`
              return (
                <Box key={ch.id}>
                  <Button
                    isSelected={isChannelSelected}
                    onClick={() => {
                      onSelectConfigurationHandler(ch)
                    }}
                  >
                    <CardContainer>
                      <Box>
                        <TriggerIcon />
                      </Box>
                      <Box>
                        <Typography variant="regular" sx={{ marginLeft: 2 }}>
                          {ch.channelName}
                        </Typography>
                      </Box>
                    </CardContainer>
                  </Button>
                </Box>
              )
            })}
          </Wrapper>
        </Box>
      )}
      {triggerType === "Email" && (
        <>
          {isLoadingDocTypes ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Header>
                <Typography variant="largeMedium">Document types</Typography>
              </Header>
              <Wrapper>
                {docTypes?.map((document, index) => {
                  const isDocSelected =
                    selectedAction?.inputs?.[DOC_TYPE] ===
                    `'${document.docType}'`
                  return (
                    <Box key={document.docType + "-" + index}>
                      <Button
                        onClick={() => onSelectDocumentHandler(document)}
                        isSelected={isDocSelected}
                      >
                        <CardContainer>
                          <Box>
                            <DocumentScannerSharp
                              style={{ fontSize: "50px", color: colors.black }}
                            />
                          </Box>
                          <Box>
                            <Typography variant="regular">
                              {document.description}
                            </Typography>
                          </Box>
                        </CardContainer>
                      </Button>
                    </Box>
                  )
                })}
              </Wrapper>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
