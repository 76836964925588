import { styled, Box, Stack, Button as ButtonMui } from "@mui/material"
import { getLiabilityDotColor, getStatusDotColor } from "../../../../utils"

interface IButtonProps {
  liability: TLiability
}

interface IStatusButtonProps {
  status: TStatus
}

export const CustomStack = styled(Stack)`
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 24px;
`

export const Item = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
`
export const LiabilityButton = styled(ButtonMui)<IButtonProps>`
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  padding: 0px 16px;

  &:hover {
    background-color: transparent;
  }

  & .MuiTypography-root {
    color: "#475467";
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap !important;
    text-align: left;
  }

  & .MuiTypography-root:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props: IButtonProps) =>
      getLiabilityDotColor(props.liability)};
    display: inline-block;
    margin-right: 8px;
  }

  &.Mui-disabled {
    background-color: transparent;
  }
`

export const StatusButton = styled(ButtonMui)<IStatusButtonProps>`
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  padding: 0px 16px;

  &:hover {
    background-color: transparent;
  }

  & .MuiTypography-root {
    color: "#475467";
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap !important;
    text-align: left;
  }

  & .MuiTypography-root:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props: IStatusButtonProps) =>
      getStatusDotColor(props.status)};
    display: inline-block;
    margin-right: 8px;
  }

  &.Mui-disabled {
    background-color: transparent;
  }
`
