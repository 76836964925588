import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Badge from "@mui/material/Badge"
import { colors } from "../../../utils"

interface StyledButtonProps {
  selected: boolean
}

export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ selected }) => ({
    color: selected ? colors.white : colors.black2,
    border: `1px solid ${selected ? colors.primary : colors.gray2}`,
    backgroundColor: `${selected ? colors.primary : colors.white} !important`,
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "12.17px",
    marginRight: "10px",
  }),
)

interface StyledTypographyProps {
  selected: boolean
}

export const StyledTypography = styled(Typography)<StyledTypographyProps>(
  ({ selected }) => ({
    marginRight: "5px",
    color: selected ? colors.white : colors.black2,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
)

interface StyledBadgeProps {
  selected: boolean
}

export const StyledBadge = styled(Badge)<StyledBadgeProps>(({ selected }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: selected ? colors.white : colors.primary,
    color: selected ? colors.primary : colors.white,
    fontSize: "8px",
  },
}))

export const TabChangesButton = styled(Button)<StyledButtonProps>`
  background-color: transparent;
  color: ${(props) => (props.selected ? colors.primary : colors.black2)};
  box-shadow: none !important;
  border: none;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${colors.primary}` : "2px solid transparent"};
  border-radius: 0;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: ${colors.primary};
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid ${colors.primary};
  }

  &:disabled {
    color: ${colors.gray10};
    border-bottom: none;
    cursor: not-allowed;
    pointer-events: none;
    background-color: transparent;
  }
`
