import {
  Box,
  FormControl,
  InputLabel,
  Select,
  styled,
  TextField,
} from "@mui/material"
import { colors } from "../../../../../../utils"

export const CustomFormControl = styled(FormControl)`
  label {
    margin-top: -6px;
    font-size: 14px;

    &.MuiFormLabel-root.MuiInputLabel-shrink {
      margin-top: 0;
    }
  }
`
export const SelectDropdown = styled(Select)`
  height: 38px;
  width: 180px;

  & .MuiOutlinedInput-input {
    padding: 0 12px;
    font-size: 14px;
  }
`

export const SelectAllDropdown = styled(SelectDropdown)`
  width: 100px;
`

export const CustomInputLabel = styled(InputLabel)`
  &.MuiInputLabel-outlined {
    top: 0 !important;
  }
`

export const SearchTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.gray2};
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.primary};
  }
`

export const ToggleWrapper = styled(Box)`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 650px) {
    flex-direction: column-reverse;
  }
`

export const FilteringWrapper = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: 1150px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`
