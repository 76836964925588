import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { type UseFormReturn, type FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { colors } from "../../../../../../utils"
import { getChannelConfigurationsAPI } from "../../../../../../services"
import { Button, CardContainer, Header, Wrapper } from "./styled"
import { Link } from "@mui/icons-material"
import { isArray } from "lodash"

interface IProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  methods: UseFormReturn<FieldValues, any, undefined>
  selectedAction: INodeItem
  setIds: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

const SELECTED_FIELDS = "SelectedFields"
const API_SETUP_ID = "APISetupId"
const API_SETUP_DETAILS_ID = "APISetupDetailsId"
const JSON_RESPONSE_SCHEMA = "JSONResponseSchema"

export const GetDataConfig = (props: IProps) => {
  const { handleChange, methods, selectedAction, setIds } = props
  const { t } = useTranslation()

  const { getValues } = methods

  const [selectedChannel, setSelectedChannel] =
    useState<IChannelConfiguration>()
  const [_, setSelectedDetails] = useState<IAPISetupDetails>()

  const [apiSetupDetailsId, setApiSetupDetailsId] = useState<string>()
  const [apiSetupId, setApiSetupId] = useState<string>()
  const [jsonData, setJsonData] = useState<Record<string, any>>({})
  const [selectedFields, setSelectedFields] = useState<Record<string, any>>({})
  const [channelConfigurations, setChannelConfigurations] =
    useState<IChannelConfiguration[]>()

  const {
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: async () => {
      const response = await getChannelConfigurationsAPI("Interface")
      setChannelConfigurations(response)

      const controlValues = getValues()

      setApiSetupId(controlValues[API_SETUP_ID])
      setApiSetupDetailsId(controlValues[API_SETUP_DETAILS_ID])

      if (controlValues[JSON_RESPONSE_SCHEMA] !== "null") {
        setJsonData(controlValues[JSON_RESPONSE_SCHEMA])
      }

      setSelectedFields(
        Object.keys(controlValues[SELECTED_FIELDS]).reduce(
          (acc: Record<string, boolean>, key: string) => {
            acc[key] =
              controlValues[SELECTED_FIELDS][key] === "true" ||
              controlValues[SELECTED_FIELDS][key] === true
            return acc
          },
          {},
        ),
      )
    },
    refetchOnMount: true,
  })

  const onSelectConfigurationHandler = (config: IChannelConfiguration) => {
    if (config.apiSetup?.id) {
      handleChange(
        selectedAction,
        "input",
        config.apiSetup?.id,
        API_SETUP_ID,
        (data) => {
          setSelectedChannel(config)
          setApiSetupId(data)
          methods.setValue(API_SETUP_ID, data)
        },
      )
    }
  }

  const onSelectDetailsHandler = (details: IAPISetupDetails) => {
    const json = JSON.parse(details.jsonResponseSchema)
    setJsonData(json)
    setSelectedDetails(details)
    handleChange(
      selectedAction,
      "input",
      json,
      JSON_RESPONSE_SCHEMA,
      (data) => {
        setJsonData(data)
        methods.setValue(JSON_RESPONSE_SCHEMA, data)
      },
    )

    const newNodeItem: INodeItem = {
      ...selectedAction,
      inputs: {
        ...selectedAction.inputs,
        [JSON_RESPONSE_SCHEMA]: json,
      },
    }
    handleChange(
      newNodeItem,
      "input",
      details.id,
      API_SETUP_DETAILS_ID,
      (data) => {
        setApiSetupDetailsId(data)
        methods.setValue(API_SETUP_DETAILS_ID, data)
      },
    )
  }

  const isLoadingChannelConfigs =
    isPendingConfigurations || isRefetchingConfigurations

  const handleCheckboxChange = (field: string) => {
    setSelectedFields((prev) => {
      const newFieldsData: any = { ...prev, [field]: !prev?.[field] }
      handleChange(
        selectedAction,
        "input",
        newFieldsData,
        SELECTED_FIELDS,
        (data) => {
          methods.setValue(SELECTED_FIELDS, data)
        },
      )
      return newFieldsData
    })
  }

  const renderFieldSelection = () => (
    <FormGroup sx={{ marginBottom: 5 }}>
      <Grid container spacing={3}>
        {Object.keys(jsonData).map((key) => (
          <Grid item xs={12} sm={4} md={4} key={key}>
            <Card
              sx={{
                height: "85px",
                paddingTop: 0,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "space-between",
                boxShadow: 3,
                borderRadius: 2,
                bgcolor: "background.paper",
                ":hover": {
                  boxShadow: 6,
                  cursor: "pointer",
                },
              }}
              onClick={() => handleCheckboxChange(key)}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "space-between",
                  flexDirection: "row",
                  pr: 0,
                }}
              >
                <FormControlLabel
                  onClick={() => handleCheckboxChange(key)}
                  control={
                    <Checkbox
                      sx={{ margin: 0, mr: 1 }}
                      checked={!!selectedFields?.[key]}
                      onChange={() => handleCheckboxChange(key)}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: "17.5px" }}>
                        {key}
                      </Typography>
                      <Typography
                        style={{ fontSize: "13px", color: "lightgrey" }}
                      >
                        ({isArray(jsonData[key]) ? "Array" : jsonData[key]})
                      </Typography>
                    </Box>
                  }
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    pr: 0,
                    mt: 1,
                    ml: -1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  )

  return (
    <Box>
      <Typography variant="largeMedium">{t("inputs")}</Typography>

      {isLoadingChannelConfigs ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Header>
            <Typography variant="largeMedium">Channels</Typography>
          </Header>
          <Wrapper>
            {channelConfigurations?.map((ch) => {
              const isChannelSelected = apiSetupId === ch.apiSetup?.id
              return (
                <Box key={ch.id}>
                  <Button
                    isSelected={isChannelSelected}
                    onClick={() => {
                      setIds({
                        ChannelId: `'${ch.id}'`,
                        ApiSetupId: `'${ch.apiSetup?.id}'`,
                        ApiSetupDetailsId: `'${ch.apiSetup?.apiSetupDetails[0].id}'`,
                      })
                      onSelectConfigurationHandler(ch)
                    }}
                  >
                    <CardContainer>
                      <Box>
                        <Link
                          style={{ fontSize: "50px", color: colors.black }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="regular" sx={{ marginLeft: 2 }}>
                          {ch.channelName}
                        </Typography>
                      </Box>
                    </CardContainer>
                  </Button>
                </Box>
              )
            })}
          </Wrapper>
          {apiSetupId && (
            <>
              <Header>
                <Typography variant="largeMedium">Setups</Typography>
              </Header>
              <Wrapper>
                {!selectedChannel?.apiSetup?.apiSetupDetails &&
                !channelConfigurations?.find(
                  (ch) => ch.apiSetup?.id === apiSetupId,
                ) ? (
                  <Typography variant="largeMedium">No details!</Typography>
                ) : (
                  channelConfigurations
                    ?.find((ch) => ch.apiSetup?.id === apiSetupId)
                    ?.apiSetup?.apiSetupDetails.map((details) => {
                      const isDetailsSelected = apiSetupDetailsId === details.id
                      return (
                        <Box key={details.id}>
                          <Button
                            isSelected={isDetailsSelected}
                            onClick={() => onSelectDetailsHandler(details)}
                          >
                            <CardContainer>
                              <Box>
                                <Link
                                  style={{
                                    fontSize: "50px",
                                    color: colors.black,
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  variant="regular"
                                  sx={{ marginLeft: 2 }}
                                >
                                  {details.method}
                                </Typography>
                              </Box>
                            </CardContainer>
                          </Button>
                        </Box>
                      )
                    })
                )}
              </Wrapper>
            </>
          )}
        </Box>
      )}
      {jsonData && Object.keys(jsonData).length > 0 && (
        <Box display="flex" flexDirection="column" gap="8px" marginTop="20px">
          <Typography
            style={{ marginBottom: "25px" }}
            textAlign="center"
            variant="regularSemiBold"
          >
            {t("selectFields")}:
          </Typography>
          {renderFieldSelection()}
        </Box>
      )}
    </Box>
  )
}
