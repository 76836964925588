import React, { useCallback, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Skeleton,
  IconButton,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getClaimChangesAPI } from "../../../../../../services"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { CustomTableCell, CustomTableContainer } from "../../styled"
import { type IFilters } from "../../../../DashboardPage"
import PreviewIcon from "@mui/icons-material/Preview"
import { ClaimDifferencesDialog } from "./components"

interface IProps {
  claimId: string
  filters?: IFilters
}

export const ClaimDataChanges: React.FC<IProps> = (props) => {
  const { claimId, filters } = props
  const { t } = useTranslation()
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["claim-data-changes", claimId, filters?.from, filters?.to],
    queryFn: () =>
      getClaimChangesAPI({ claimId, from: filters?.from, to: filters?.to }),
    refetchOnMount: true,
  })
  const [selectedRow, setSelectedRow] = useState<IClaimDataChange | undefined>()

  const onRowClick = useCallback(
    (row: IClaimDataChange) => setSelectedRow(row),
    [],
  )

  const onCloseDialog = useCallback(() => setSelectedRow(undefined), [])

  return (
    <Box display="flex" flexDirection="column" flex={1} width="100%">
      <ClaimDifferencesDialog
        data={selectedRow}
        open={!!selectedRow}
        closeDialog={onCloseDialog}
      />
      <CustomTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ width: "auto !important" }} width="35%">
                {t("changesBy")}
              </CustomTableCell>
              <TableCell width="25%">{t("changeDate")}</TableCell>
              <TableCell width="20%">{t("totalChanges")}</TableCell>
              <TableCell width="10%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isRefetching ? (
              <>
                {[...Array(3)].map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {data?.map((row, index) => (
                  <TableRow key={index}>
                    <CustomTableCell sx={{ width: "auto !important" }}>
                      {row.changedBy}
                    </CustomTableCell>
                    <TableCell>
                      {row.changedAt
                        ? dayjs(row.changedAt).format("DD.MM.YYYY HH:mm")
                        : "-"}
                    </TableCell>
                    <TableCell>{row.comparisonDifferences.length}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => onRowClick(row)}>
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {!data?.length && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {t("noData")}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </Box>
  )
}
