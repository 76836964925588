import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Controller, useForm, useFieldArray } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useReactToPrint } from "react-to-print"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import _ from "lodash"
import { useToast } from "../../contexts"
import {
  getClaimCostsAPI,
  postClaimAnkundAPI,
  postClaimAnspruchAPI,
  putClaimCostsAPI,
} from "../../services"
import {
  formatNumber,
  formatString,
  getDamagedParty,
  transformNumber,
} from "./utils/functions"
import { NumberFieldInput, UpdatePositionStatusPopper } from "./components"
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Edit, Print, SaveAlt } from "@mui/icons-material"
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined"
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined"
import {
  CellBox,
  CustomAutocomplete,
  CustomContainer,
  CustomTable,
  CustomTextField,
} from "./styled"
import { TYPE_OF_COSTS_TRANSLATIONS } from "../../utils"

interface IProps {
  claim: IClaimDetails
}

export const CostTable = (props: IProps) => {
  const { claim } = props
  const { breakpoints } = useTheme()
  const isSmallerThanSm = useMediaQuery(breakpoints.down("sm"))
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const isInitialMount = useRef(true)
  const [isEditMode, setIsEditMode] = useState(false)

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const {
    data: claimCosts,
    isLoading: claimCostsLoading,
    isRefetching: claimCostsRefetching,
  } = useQuery({
    queryKey: ["claim-costs" + claim.id],
    queryFn: () => getClaimCostsAPI(claim.id),
    refetchOnMount: true,
  })

  const { mutate, isPending: costsAreUpdating } = useMutation({
    mutationFn: (claimCosts: IClaimCostsRequestParams) =>
      putClaimCostsAPI(claimCosts),
    onSuccess: (response, variables) => {
      queryClient.setQueryData(["claim-costs" + claim.id], response)

      queryClient.setQueryData(
        ["claim-details", claim.id],
        (prev: IClaimDetails) => {
          if (prev) {
            return {
              ...prev,
              canProcessAnkund:
                prev.canProcessAnkund && !!variables?.body?.length
                  ? false
                  : !prev.canProcessAnkund &&
                    prev.canProcessAnspruch &&
                    !variables?.body?.length
                  ? true
                  : prev.canProcessAnkund,
              canProcessAnspruch:
                prev.canProcessAnspruch && !variables?.body?.length
                  ? false
                  : !prev.canProcessAnspruch &&
                    prev.canProcessAnkund &&
                    !!variables?.body?.length
                  ? true
                  : prev.canProcessAnspruch,
            }
          }
        },
      )

      toast.show(t("claimCostsUpdatedSuccessfully"), "success")
      setIsEditMode(false)
    },
  })

  const { mutate: mutateAnkund, isPending: isAnkundPending } = useMutation({
    mutationFn: (id: string) => postClaimAnkundAPI(id),
    onSuccess: (response: IPostAnkundResponse) => {
      queryClient.setQueryData(
        ["claim-details", claim.id],
        (prev: IClaimDetails) => {
          if (prev) {
            return {
              ...prev,
              status: response.currentStatus,
              availableStatuses: response.availableStatuses,
            }
          }
        },
      )
      toast.show(t("ankundSuccess"), "success")
    },
  })

  const { mutate: mutateAnspruch, isPending: isAnspruchPending } = useMutation({
    mutationFn: (id: string) => postClaimAnspruchAPI(id),
    onSuccess: (response: IPostAnkundResponse) => {
      queryClient.setQueryData(
        ["claim-details", claim.id],
        (prev: IClaimDetails) => {
          if (prev) {
            return {
              ...prev,
              status: response.currentStatus,
              availableStatuses: response.availableStatuses,
            }
          }
        },
      )
      toast.show(t("anspruchSuccess"), "success")
    },
  })

  const { watch, reset, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      claimCosts,
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: "claimCosts",
    control,
  })

  const fieldsNotEmpty = fields?.length > 0

  const hasPermissionToEditCostTable = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "COST_TABLE" && p.access === "Delete",
      ),
    [user],
  )

  const hasPermissionForAnkundAndAnspruch = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "COST_TABLE" && p.access === "Delete",
      ),
    [user],
  )

  const calculateLegalSituation = useCallback(
    (
      financialSupport: string,
      payment: string,
      liabilityQuota: number | null,
    ) => {
      if (liabilityQuota === null) {
        return null
      }

      const amountClaimed = transformNumber(financialSupport) || 0
      const amountPaid = transformNumber(payment) || 0

      return amountClaimed * (liabilityQuota / 100) - amountPaid
    },
    [],
  )

  const recalculateLegalSituation = useCallback(
    (index: number) => {
      const financialSupport = transformNumber(
        watch(`claimCosts.${index}.financialSupport`),
      )
      const payment = transformNumber(watch(`claimCosts.${index}.payment`))
      const liabilityQuota = claim.liabilityQuota

      if (!financialSupport || !payment) {
        return
      }

      const newByLegalSituation = calculateLegalSituation(
        financialSupport.toString(),
        payment.toString(),
        liabilityQuota,
      )

      setValue(
        `claimCosts.${index}.byLegalSituation`,
        newByLegalSituation !== null ? formatNumber(newByLegalSituation) : "",
      )
    },
    [claim.liabilityQuota, fields, watch],
  )

  useEffect(() => {
    if (claimCosts) {
      reset({
        claimCosts: claimCosts as any,
      })
    }
  }, [claimCosts])

  useEffect(() => {
    // logic for updating claim costs when liability quota changes, but only if the claim costs are already loaded
    if (isInitialMount.current) {
      sessionStorage.setItem("claimQuota", claim?.liabilityQuota?.toString())
      isInitialMount.current = false
      return
    }

    const quoteFromLocalStorage = sessionStorage.getItem("claimQuota")

    if (
      quoteFromLocalStorage === "undefined" &&
      claim.liabilityQuota === null
    ) {
      return
    }

    if (
      claim?.liabilityQuota?.toString() !== quoteFromLocalStorage &&
      claimCosts
    ) {
      handleUpdateClaimCosts(
        claimCosts.map((c) => {
          const legalSituation = calculateLegalSituation(
            c.financialSupport,
            c.payment,
            claim.liabilityQuota,
          )
          const legalSituationString = legalSituation
            ? formatNumber(legalSituation)
            : ""
          return {
            ...c,
            byLegalSituation:
              claim.liabilityQuota > 0 ? legalSituationString ?? null : null,
          }
        }),
      )
      sessionStorage.setItem("claimQuota", claim?.liabilityQuota?.toString())
    }
  }, [claim.liabilityQuota])

  const translatedCostTypes = useMemo(() => {
    return Object.fromEntries(
      Object.entries(TYPE_OF_COSTS_TRANSLATIONS).map(([key, value]) => [
        key,
        t(value),
      ]),
    )
  }, [t])

  const TRANSLATION_TO_GERMAN = useMemo(() => {
    return Object.fromEntries(
      Object.entries(translatedCostTypes).map(([key, value]) => [value, key]),
    )
  }, [translatedCostTypes])

  const handlePrintClick = useReactToPrint({
    documentTitle: t("claimCosts"),
    content: () => {
      const container = document.createElement("div")
      const claimCostsElement = document.getElementById(
        `claim-costs-${claim.id}`,
      )
      const caseNumberInfo = document.createElement("p")
      caseNumberInfo.textContent = `${t("caseNumber")}: ${
        claim.caseNumber ?? t("undefined")
      }`

      const damagedPartyInfo = document.createElement("p")
      damagedPartyInfo.textContent = `${t("damagedParty")}: ${
        claim.damagedParty ??
        getDamagedParty(claim?.claimDetails?.informationAboutInjury?.holder) ??
        t("undefined")
      }`

      container.appendChild(caseNumberInfo)
      container.appendChild(damagedPartyInfo)
      container.appendChild(claimCostsElement?.cloneNode(true)!)
      return container
    },
    onPrintError: () => {
      toast.show(t("failedToPrint"), "error")
    },
  })

  const handleSaveClick = useCallback(() => {
    const sums = {
      damageType: "Total",
      financialSupport:
        fields.reduce(
          (acc, curr) => acc + parseFloat(curr.financialSupport) || 0,
          0,
        ) + "€",
      payment:
        fields.reduce((acc, curr) => acc + parseFloat(curr.payment) || 0, 0) +
        "€",
      remainingBalance:
        fields.reduce(
          (acc, curr) =>
            acc +
              parseFloat(curr.financialSupport) -
              parseFloat(curr.payment) || 0,
          0,
        ) + "€",
    }

    const headersCSV = `${t("typeOfDamage")},${t("amountClaimed")}, ${t(
      "amountPaid",
    )}, ${t("remainingBalance")}`

    const sumsCSV = Object.values(sums).join(",")

    const csvContent = fields
      .map((row) =>
        [
          row.damageType,
          row.financialSupport + "€",
          row.payment + "€",
          (
            parseFloat(row.financialSupport) - parseFloat(row.payment)
          )?.toString() + "€",
        ].join(","),
      )
      .join("\n")

    const combinedCSV = headersCSV + "\n" + csvContent + "\n" + sumsCSV

    const blob = new Blob([combinedCSV], { type: "text/csv" })

    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `Claim_Costs${
      claim?.caseNumber ? "_" + claim.caseNumber : ""
    }.csv`

    link.click()
  }, [fields, t, claim])

  const handleEditModeClick = useCallback(() => setIsEditMode(true), [])

  const handleDiscardClick = useCallback(() => {
    reset()
    setIsEditMode(false)
  }, [])

  const handleUpdateClaimCosts = useCallback(
    (claimCosts: IClaimCosts[]) => {
      mutate({
        id: claim.id,
        body: claimCosts.map((cost) => {
          return {
            ...(cost.id && { id: cost.id }),
            damageType: cost.damageType,
            financialSupport: transformNumber(cost.financialSupport).toString(),
            payment: transformNumber(cost.payment).toString(),
            status: cost.status,
            byLegalSituation: cost.byLegalSituation
              ? transformNumber(cost?.byLegalSituation)?.toString()
              : null,
          }
        }),
      })
    },
    [claim],
  )

  const handleAnkund = useCallback((id: string) => {
    mutateAnkund(id)
  }, [])

  const handleAnspruch = useCallback((id: string) => {
    mutateAnspruch(id)
  }, [])

  const sums = useMemo(() => {
    const totalFinancialSupport = fields.reduce(
      (acc, _curr, index) =>
        acc + transformNumber(watch(`claimCosts.${index}.financialSupport`)) ||
        acc + 0,
      0,
    )
    const totalPayment = fields.reduce(
      (acc, _curr, index) =>
        acc + transformNumber(watch(`claimCosts.${index}.payment`)) || acc + 0,
      0,
    )
    const remainingBalance = totalFinancialSupport - totalPayment

    return {
      totalFinancialSupport,
      totalPayment,
      remainingBalance,
    }
  }, [
    fields.map((_, index) => watch(`claimCosts.${index}.financialSupport`)),
    fields.map((_, index) => watch(`claimCosts.${index}.payment`)),
  ])

  const isDataLoading = claimCostsLoading || claimCostsRefetching

  const areToolsDisabled =
    isEditMode || isDataLoading || isAnkundPending || isAnspruchPending

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <form
        onSubmit={handleSubmit(({ claimCosts }) =>
          handleUpdateClaimCosts(claimCosts!),
        )}
      >
        <Box
          display="flex"
          flexDirection="column"
          id={`claim-costs-${claim.id}`}
        >
          <CustomContainer>
            <CustomTable isEditMode={isEditMode}>
              <TableHead>
                <TableRow>
                  <TableCell width="30%"></TableCell>
                  <TableCell width="15%" align="right">
                    {t("amount")}
                  </TableCell>
                  <TableCell width="15%" align="right">
                    {t("payment")}
                  </TableCell>
                  <TableCell width="15%" align="right">
                    {t("balance")}
                  </TableCell>
                  <TableCell width="15%" align="right">
                    {t("legalSituation")}
                  </TableCell>
                  <TableCell width="10%" align="right">
                    {t("status")}
                  </TableCell>
                  {isEditMode && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {isDataLoading ? (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ) : fieldsNotEmpty ? (
                  fields.map((row, index) => {
                    const amountClaimed = watch(
                      `claimCosts.${index}.financialSupport`,
                    )
                    const amountPaid = watch(`claimCosts.${index}.payment`)

                    return (
                      <TableRow key={row.id || index?.toString()}>
                        <TableCell>
                          {!isEditMode ? (
                            t(TYPE_OF_COSTS_TRANSLATIONS[row.damageType])
                          ) : (
                            <Controller
                              control={control}
                              name={`claimCosts.${index}.damageType`}
                              rules={{ required: t("required") }}
                              render={({
                                field: { value, onChange },
                                formState: { errors },
                              }) => (
                                <CustomAutocomplete
                                  freeSolo
                                  size="small"
                                  openOnFocus
                                  options={Object.values(translatedCostTypes)}
                                  value={translatedCostTypes[value] || value}
                                  onInputChange={(_event, inputValue) => {
                                    onChange(
                                      TRANSLATION_TO_GERMAN[inputValue] ||
                                        inputValue,
                                    )
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option as string}</li>
                                  )}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...(params as any)}
                                      fullWidth
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      error={
                                        !!errors?.claimCosts?.[index]
                                          ?.damageType
                                      }
                                      helperText={
                                        errors?.claimCosts?.[index]?.damageType
                                          ?.message
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <CellBox>
                            {!isEditMode ? (
                              formatString(row.financialSupport)
                            ) : (
                              <Controller
                                control={control}
                                name={`claimCosts.${index}.financialSupport`}
                                render={({ field: { value, onChange } }) => (
                                  <NumberFieldInput
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e)
                                      recalculateLegalSituation(index)
                                    }}
                                  />
                                )}
                              />
                            )}
                            <Typography>€</Typography>
                          </CellBox>
                        </TableCell>
                        <TableCell align="right">
                          <CellBox>
                            {!isEditMode ? (
                              formatString(row.payment)
                            ) : (
                              <Controller
                                control={control}
                                name={`claimCosts.${index}.payment`}
                                render={({ field: { value, onChange } }) => (
                                  <NumberFieldInput
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e)
                                      recalculateLegalSituation(index)
                                    }}
                                  />
                                )}
                              />
                            )}
                            <Typography>€</Typography>
                          </CellBox>
                        </TableCell>
                        <TableCell align="right">
                          <Box margin={isEditMode ? "6px 6px 0 0" : undefined}>
                            {amountClaimed && amountPaid
                              ? formatNumber(
                                  transformNumber(amountClaimed) -
                                    transformNumber(amountPaid),
                                )
                              : 0}{" "}
                            €
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {!isEditMode ? (
                            claim.liabilityQuota > 0 &&
                            row.byLegalSituation !== null ? (
                              formatString(row.byLegalSituation) + " €"
                            ) : (
                              "-"
                            )
                          ) : (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                              gap="8px"
                            >
                              <Controller
                                control={control}
                                name={`claimCosts.${index}.byLegalSituation`}
                                render={({ field: { value, onChange } }) => (
                                  <NumberFieldInput
                                    allowNegative
                                    value={value!}
                                    onChange={onChange}
                                  />
                                )}
                              />
                              <Typography>€</Typography>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Controller
                            control={control}
                            name={`claimCosts.${index}.status`}
                            render={({ field: { value, onChange } }) => (
                              <UpdatePositionStatusPopper
                                status={value}
                                onUpdateClick={(v) => onChange(v)}
                                disabled={!isEditMode}
                              />
                            )}
                          />
                        </TableCell>
                        {isEditMode && (
                          <TableCell>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => {
                                remove(index)
                              }}
                            >
                              <RemoveCircleOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell>{t("noData")}</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography marginLeft="6px">{t("sum")}:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(sums.totalFinancialSupport)} €
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(sums.totalPayment)} €
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(
                      sums.totalFinancialSupport - sums.totalPayment,
                    )}{" "}
                    €
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  {isEditMode && (
                    <TableCell>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          append({
                            damageType: "",
                            financialSupport: "0",
                            payment: "0",
                            status: "InProgress",
                            byLegalSituation: "",
                          })
                        }}
                      >
                        <AddCircleOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableFooter>
            </CustomTable>
          </CustomContainer>
        </Box>
        <Box
          display="flex"
          marginTop="32px"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={isSmallerThanSm && isEditMode ? "column" : "row"}
          gap={isSmallerThanSm && isEditMode ? "16px" : "0"}
        >
          <Box display="flex" gap="8px" alignItems="center">
            <Tooltip title={t("print")}>
              <IconButton
                size="small"
                onClick={handlePrintClick}
                disabled={areToolsDisabled}
              >
                <Print fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("saveAsCSV")}>
              <IconButton
                size="small"
                onClick={handleSaveClick}
                disabled={areToolsDisabled}
              >
                <SaveAlt fontSize="small" />
              </IconButton>
            </Tooltip>
            {hasPermissionToEditCostTable && (
              <Tooltip title={t("edit")}>
                <IconButton
                  size="small"
                  onClick={handleEditModeClick}
                  disabled={areToolsDisabled}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box display="flex" gap="8px">
            {!isEditMode && hasPermissionForAnkundAndAnspruch && (
              <>
                {claim?.canProcessAnkund && (
                  <LoadingButton
                    onClick={() => handleAnkund(claim?.id)}
                    size="small"
                    disabled={isAnkundPending}
                    loading={isAnkundPending}
                  >
                    {t("ankund")}
                  </LoadingButton>
                )}

                {claim?.canProcessAnspruch && (
                  <LoadingButton
                    onClick={() => handleAnspruch(claim?.id)}
                    size="small"
                    disabled={isAnspruchPending}
                    loading={isAnspruchPending}
                  >
                    {t("anspruch")}
                  </LoadingButton>
                )}
              </>
            )}
            {isEditMode && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleDiscardClick}
                >
                  {t("discard")}
                </Button>
                <LoadingButton
                  type="submit"
                  size="small"
                  loading={costsAreUpdating}
                  disabled={costsAreUpdating}
                >
                  {t("update")}
                </LoadingButton>
              </>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  )
}
