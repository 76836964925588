import { Button, styled } from "@mui/material"
import { colors } from "../../../../../../../utils"

interface ISideBarItemProps {
  selected?: boolean
}

export const SideBarItem = styled(Button)<ISideBarItemProps>`
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  padding: 0px 12px;
  height: 34px;
  background-color: ${({ selected }) =>
    selected ? colors.primary : "transparent"};
  color: ${({ selected }) => (selected ? colors.white : colors.black)};
`
