import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Skeleton,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getLiabilityChangesAPI } from "../../../../../../services"
import { getLiabilityDotColor } from "../../../../../../utils"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { CustomRow, CustomTableCell, CustomTableContainer } from "../../styled"
import { type IFilters } from "../../../../DashboardPage"

const liabilityMapping: Record<number, TLiability> = {
  0: "Unknown",
  1: "Agreed",
  2: "Denied",
}

const formatLiabilityText = (
  liability: number,
  quote: number | null,
): string => {
  if (liability !== null && quote !== null) {
    return `${liabilityMapping[liability]} | Quote - ${quote}%`
  } else if (liability !== null) {
    return `${liabilityMapping[liability]}`
  } else if (quote !== null) {
    return `Quote - ${quote}%`
  }
  return ""
}

export const LiabilityChanges: React.FC<{
  claimId: string
  filters?: IFilters
}> = ({ claimId, filters }) => {
  const { t } = useTranslation()
  const {
    data: liabilityChanges,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["liabilityChanges", claimId, filters?.from, filters?.to],
    queryFn: () =>
      getLiabilityChangesAPI({ claimId, from: filters?.from, to: filters?.to }),
    refetchOnMount: true,
  })

  return (
    <CustomTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell>{t("oldValue")}</CustomTableCell>
            <CustomTableCell>{t("newValue")}</CustomTableCell>
            <CustomTableCell>{t("changesBy")}</CustomTableCell>
            <TableCell>{t("date")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading || isRefetching ? (
            <>
              {[...Array(3)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : liabilityChanges?.length ? (
            liabilityChanges.map((change, index) => (
              <CustomRow key={index}>
                <CustomTableCell>
                  <Box display="flex" alignItems="center">
                    <Box
                      width="8px"
                      height="8px"
                      bgcolor={getLiabilityDotColor(
                        liabilityMapping[change.oldValue.liability],
                      )}
                      borderRadius="50%"
                      mr="4px"
                    />
                    {formatLiabilityText(
                      change.oldValue.liability,
                      change.oldValue.liabilityQuota,
                    )}
                  </Box>
                </CustomTableCell>
                <CustomTableCell>
                  <Box display="flex" alignItems="center">
                    <Box
                      width="8px"
                      height="8px"
                      bgcolor={getLiabilityDotColor(
                        liabilityMapping[change.newValue.liability],
                      )}
                      borderRadius="50%"
                      mr="4px"
                    />
                    {formatLiabilityText(
                      change.newValue.liability,
                      change.newValue.liabilityQuota,
                    )}
                  </Box>
                </CustomTableCell>
                <CustomTableCell>{change.changesBy}</CustomTableCell>
                <TableCell>
                  {dayjs(change.modifiedAt).format("DD.MM.YYYY hh:mm")}
                </TableCell>
              </CustomRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {t("noData")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  )
}
