import React, { useMemo, useState } from "react"
import {
  TableCell,
  IconButton,
  Collapse,
  Skeleton,
  Button,
  Typography,
  Box,
} from "@mui/material"
import { ReactComponent as CostTableIcon } from "../../../../assets/icons/CostTableIcon.svg"
import { ReactComponent as CostTableIconClicked } from "../../../../assets/icons/CostTableClicked.svg"
import { ReactComponent as EditClaimIcon } from "../../../../assets/icons/Pen.svg"
import { ReactComponent as ClaimDetailsIcon } from "../../../../assets/icons/ClaimDetails.svg"
import { ReactComponent as ClaimDetailsClickedIcon } from "../../../../assets/icons/ClaimDetailsClicked.svg"
// import { ReactComponent as BurgerMenueList } from "../../../../assets/icons/BurgerMenueList.svg"
// import { ReactComponent as RapidRightLink } from "../../../../assets/icons/RapidRightLink.svg"
import { ReactComponent as AttachmentIcon } from "../../../../assets/icons/attachment.svg"
import { ReactComponent as AttachmentActiveIcon } from "../../../../assets/icons/attachment-active.svg"
// import { ReactComponent as GreenTic } from "../../../../assets/icons/GreenTic.svg"
// import { ReactComponent as FIcon } from "../../../../assets/icons/F-icon.svg"
// import { ReactComponent as TIcon } from "../../../../assets/icons/T-icon.svg"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined"
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined"
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined"

import dayjs from "dayjs"
import {
  UpdateLiabilityPopper,
  CostTable,
  ClaimOutOfSyncIcon,
  UpdateStatusPopper,
} from "../../../../components"
import { Link } from "react-router-dom"
import { CollapsibleRow, CustomRow } from "./styled"
import { ClaimSummary } from "../ClaimSummary"
import { useTranslation } from "react-i18next"
import { colors } from "../../../../utils"
import { DocumentsOverview } from "../DocumentsOverview/DocumentsOverview"
import { ClaimActivities } from "../ClaimActivities/ClaimActivities"

interface IProps {
  isDataLoading: boolean
  showClaimGroup?: boolean
  rowData: any
  isExpanded: boolean
  isDisabled: boolean
  onRowClick: () => void
  onRowClose: () => void
}

type TTab = "cost_table" | "summary" | "attachments" | "changes"

export const EnhancedTableRow = (props: IProps) => {
  const {
    isDataLoading,
    showClaimGroup,
    rowData,
    isExpanded,
    isDisabled,
    onRowClick,
    onRowClose,
  } = props
  const [selectedTab, setSelectedTab] = useState<TTab>("cost_table")
  const { t } = useTranslation()

  const claimHasConflicts = useMemo(() => {
    return rowData?.syncronizationStatus === "OutOfSyncVersionConflict"
  }, [rowData])

  const onTabClick = (tab: TTab) => setSelectedTab(tab)

  if (isDataLoading) {
    return (
      <CustomRow open={false} className="disabled">
        <TableCell>
          <Box padding="8px">
            <Skeleton />
          </Box>
          <Box padding="8px">
            <Skeleton />
          </Box>
        </TableCell>
        <TableCell>
          <Box padding="8px">
            <Skeleton />
          </Box>
          <Box padding="8px">
            <Skeleton />
          </Box>
        </TableCell>
        <TableCell>
          <Box padding="8px">
            <Skeleton />
          </Box>
          <Box padding="8px">
            <Skeleton />
          </Box>
        </TableCell>
        <TableCell>
          <Box padding="8px">
            <Skeleton />
          </Box>
          <Box padding="8px">
            <Skeleton />
          </Box>
        </TableCell>
        {showClaimGroup && (
          <TableCell>
            <Box padding="8px">
              <Skeleton />
            </Box>
          </TableCell>
        )}
        <TableCell>
          <Box padding="8px">
            <Skeleton />
          </Box>
        </TableCell>
        <TableCell>
          <Box padding="8px">
            <Skeleton />
          </Box>
        </TableCell>
        <TableCell />
      </CustomRow>
    )
  }

  return (
    <React.Fragment>
      <CustomRow
        onClick={onRowClick}
        open={isExpanded}
        disabled={isDisabled}
        className={isDisabled ? "disabled-effect" : ""}
      >
        <TableCell className="word-break-all">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX="8px"
            paddingY="6px"
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
              >
                {rowData.caseNumber?.trim() || "-"}
              </Typography>
              {claimHasConflicts && (
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  paddingLeft="4px"
                >
                  <ClaimOutOfSyncIcon />
                </Box>
              )}
            </Box>
            <Typography
              marginTop="4px"
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
            >
              {rowData.createdDate?.trim()
                ? dayjs(rowData.createdDate).format("DD.MM.YYYY")
                : "-"}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className="word-break-all">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX="8px"
            paddingY="6px"
          >
            <Box display="flex" alignItems="center">
              <PeopleOutlineOutlinedIcon fontSize="small" />
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {rowData.damagedParty?.trim() || "-"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="4px">
              <DirectionsCarFilledOutlinedIcon fontSize="small" />
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {rowData.officialMandantRegistrationNumber?.trim() || "-"}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell className="word-break-all">
          <Box
            display="flex"
            flexDirection="column"
            paddingX="8px"
            paddingY="6px"
          >
            <Box display="flex" alignItems="center">
              <PeopleOutlineOutlinedIcon fontSize="small" />
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {rowData.opponentParty?.trim() || "-"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="4px">
              <DirectionsCarFilledOutlinedIcon fontSize="small" />
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {rowData.officialOpponentRegistrationNumber?.trim() || "-"}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell className="word-break-all">
          <Box
            display="flex"
            flexDirection="column"
            paddingX="8px"
            paddingY="6px"
          >
            <Box display="flex" alignItems="center">
              <CalendarMonthOutlinedIcon fontSize="small" />
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {rowData.accidentDate?.trim()
                  ? dayjs(rowData.accidentDate).format("DD.MM.YYYY")
                  : "-"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="4px">
              <PlaceOutlinedIcon fontSize="small" />
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {rowData.accidentPlace?.trim() || "-"}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        {showClaimGroup && (
          <TableCell className="word-break-all">
            <Typography variant="small" color={colors.gray9} padding="8px">
              {rowData.groupName?.trim() || "-"}
            </Typography>
          </TableCell>
        )}
        <TableCell className="word-break-all">
          <Box paddingX="16px">
            <UpdateStatusPopper claim={rowData} disabled={isDisabled} />
          </Box>
        </TableCell>
        <TableCell className="word-break-all">
          <Box paddingX="16px">
            <UpdateLiabilityPopper claim={rowData} disabled={isDisabled} />
          </Box>
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={onRowClick} disabled={isDisabled}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </CustomRow>
      <CollapsibleRow open={isExpanded}>
        <TableCell colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box>
              <Box
                marginBottom="24px"
                paddingBottom="16px"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                borderBottom={`1px solid ${colors.gray4}`}
              >
                <Typography
                  flex={1}
                  paddingRight="24px"
                  variant="regularSemiBold"
                  color={colors.black4}
                >
                  {selectedTab === "attachments"
                    ? t("documents")
                    : selectedTab === "changes"
                    ? t("activitySummary")
                    : t("damageDetails")}
                </Typography>

                <Box display="flex" alignItems="center" gap="12px">
                  <IconButton
                    style={{ padding: "4px" }}
                    onClick={() => onTabClick("cost_table")}
                  >
                    {selectedTab === "cost_table" ? (
                      <CostTableIconClicked />
                    ) : (
                      <CostTableIcon />
                    )}
                  </IconButton>
                  <IconButton
                    style={{ padding: "4px" }}
                    onClick={() => onTabClick("summary")}
                  >
                    {selectedTab === "summary" ? (
                      <ClaimDetailsClickedIcon />
                    ) : (
                      <ClaimDetailsIcon />
                    )}
                  </IconButton>
                  <IconButton onClick={() => setSelectedTab("attachments")}>
                    {selectedTab === "attachments" ? (
                      <AttachmentActiveIcon />
                    ) : (
                      <AttachmentIcon />
                    )}
                  </IconButton>
                  <IconButton onClick={() => setSelectedTab("changes")}>
                    {selectedTab === "changes" ? (
                      <HistoryOutlinedIcon color="primary" />
                    ) : (
                      <HistoryOutlinedIcon
                        style={{ color: `${colors.gray9}` }}
                      />
                    )}
                  </IconButton>
                  <Link to={`/claims/${rowData.id}`}>
                    <IconButton>
                      <EditClaimIcon />
                    </IconButton>
                  </Link>
                </Box>
                <Box marginLeft="24px" />
                <Button onClick={onRowClose} size="small">
                  {t("close")}
                </Button>
              </Box>
              {selectedTab === "cost_table" && <CostTable claim={rowData} />}
              {selectedTab === "summary" && (
                <ClaimSummary claimId={rowData.id} />
              )}
              {selectedTab === "attachments" && (
                <DocumentsOverview claimId={rowData.id} />
              )}
              {selectedTab === "changes" && (
                <ClaimActivities claimId={rowData.id} />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </CollapsibleRow>
    </React.Fragment>
  )
}
