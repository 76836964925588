import styled from "@emotion/styled"
import { Box, Skeleton, IconButton, Alert } from "@mui/material"
import { colors } from "../../../../utils"

export const StyledBox = styled(Box)({
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "8px",
  border: `1px solid ${colors.gray4}`,
  padding: "16px",
})

export const StyledSkeleton = styled(Skeleton)({
  borderRadius: "4px",
})

export const StyledAlert = styled(Alert)({
  backgroundColor: `${colors.white}`,
  border: `1px solid ${colors.gray4}`,
})

export const StyledIconButton = styled(IconButton)({
  ariaLabel: "close",
  size: "small",
})
