import { type Edge, type Node, Position } from "reactflow"
import dagre from "@dagrejs/dagre"
import { colors, camelCaseStringToSentence } from "../../../utils"

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const nodeWidth = 500
  const nodeHeight = 150

  dagreGraph.setGraph({ rankdir: "LR" })

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Right
    node.sourcePosition = Position.Left

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }
  })

  return { nodes, edges }
}

export const getTransformedLayout = (nodes: Node[], edges: Edge[]) => {
  return getLayoutedElements(nodes, edges)
}

const typeMap: Record<number, string> = {
  0: "trigger",
  1: "action",
  2: "core",
  3: "dropzone",
  4: "posteingang",
  5: "postausgang",
  6: "wiedervorlage",
  7: "genericstep",
}

const createNodeDataObject = (step: IWorkflowStep, executionPointers: IExecutionPointer[] | undefined) => {
  const executionPointer = executionPointers?.find(
    (e) => e.stepDefinitionId === step.id,
  )
  return {
    type: typeMap[step.type],
    action: step.stepType,
    label: step.name ?? step.id,
    id: step.id,
    icon: step.icon,
    inputs: step?.inputs,
    outputs: step?.outputs,
    selectNextStep: step?.selectNextStep,
    do: step?.do,
    status: executionPointer?.status ?? 10
  }
}

export const transformStepToNode = (
  step: IWorkflowStep,
  executionPointers: IExecutionPointer[] | undefined,
): Node[] => {
  const nodes: Node[] = []

  if (step.stepType === "If") {
    if (step.do?.length > 0) {
      step.do[0].forEach((branch: IWorkflowStep) => {
        nodes.push(...transformStepToNode(branch, executionPointers))
      })
    }
  }

  if (step.userInteraction) {
    nodes.push({
      id: step.id,
      type: "interactionCard",
      className: "interactionCard",
      data: createNodeDataObject(step, executionPointers),
      position: { x: 0, y: 0 },
    })
  } else {
    nodes.push({
      id: step.id,
      type: "actionCard",
      selectable: false,
      data: createNodeDataObject(step, executionPointers),
      position: { x: 0, y: 0 },
    })
  }
  return nodes

  // if (step.stepType === "Decide") {
  //   return {
  //     id: step.id,
  //     type: "boolCard",
  //     selectable: false,
  //     data: {
  //       type: typeMap[step.type],
  //       action: step.stepType,
  //       label: step.name ?? step.id,
  //       id: step.id,
  //       icon: step.icon,
  //       inputs: step?.inputs,
  //       outputs: step?.outputs,
  //       selectNextStep: step?.selectNextStep,
  //       do: step?.do,
  //       status: executionPointer?.status ?? 10,
  //     },
  //     position: { x: 0, y: 0 },
  //   }
  // }
}
const createEdge = (
  parent: IWorkflowStep | undefined,
  step: IWorkflowStep
) => {
  let label

  if(/If-.*-B1$/.test(step?.id)){
    label = "True"
    
  } else if (/If-.*-B2$/.test(step?.id)) {
    label = "False"
  }

  const newEdge: Edge = {
    id: `e${parent?.id}-${step?.id}`,
    source: parent?.id!,
    target: step?.id,
    data: { parent: parent?.id },
    label,
    type: "default",
    style: { stroke: colors.gray3 },
  }

  return newEdge;
}

export const createEdges = (
  allSteps: IWorkflowStep[],
): Edge[] => {
  const edges: Edge[] = []
  allSteps.forEach((step) => {
    let regularParent: any;
    allSteps.forEach(s => {
      const nestedStep = s.do?.[0]?.[0];
      if (nestedStep?.nextStepId === step.id) {
        regularParent = nestedStep
      }
      
      if (s.nextStepId === step.id) {
        regularParent = s;
      }
    })

  // const decidedParent = allSteps.find((s) => {
  //   if (s.stepType === "Decide") {
  //     const nextStepId = Object.keys(s.selectNextStep)
  //     return nextStepId.includes(step.id)
  //   }

  //   return false
  // })!

  // if (decidedParent?.stepType === "Decide") {
  //   const nextStepId = Object.keys(decidedParent.selectNextStep)

  //   if (nextStepId.find((id) => id === step.id)) {
  //     return {
  //       id: `e${decidedParent?.id}-${step?.id}`,
  //       source: decidedParent?.id,
  //       target: step?.id,
  //       label: step.name ?? camelCaseStringToSentence(step?.id),
  //       data: { parent: decidedParent?.id },
  //       type: "",
  //       style: { stroke: colors.gray3 },
  //     }
  //   }
  // }

    if (step.stepType === "If") {
      const trueBranch = step.do[0]

      edges.push(createEdge(regularParent, step))
      edges.push(createEdge(step, trueBranch[0]))
    } else if (regularParent) {
      edges.push(createEdge(regularParent, step))
    } else {
      edges.push(createEdge(regularParent, step))
    }
  })

  return edges
}

export const getStatusBackgroundColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow3
    case 1:
      return colors.red3
    case 2:
      return colors.green3
    case 3:
      return colors.gray4
    default:
      return null
  }
}

export const getStatusBorderColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.yellow2
    case 1:
      return colors.red
    case 2:
      return colors.green
    case 3:
      return colors.gray3
    default:
      return null
  }
}

export const getExecutionStatusColor = (s: number) => {
  switch (s) {
    case 0:
      return colors.gray2
    case 1:
      return colors.yellow2
    case 2:
      return colors.primary
    case 3:
      return colors.green2
    case 4:
      return colors.blue2
    case 5:
      return colors.blue7
    case 6:
      return colors.red
    case 7:
      return colors.grayDot
    case 8:
      return colors.red2
    default:
      return colors.gray4
  }
}
