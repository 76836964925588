import { Box, TableRow, styled } from "@mui/material"
import { colors } from "../../../../../../../../utils"

interface IRowProps {
  open: boolean
  disabled?: boolean
}

export const CustomRow = styled(TableRow)<IRowProps>(({ open, disabled }) => ({
  borderRadius: "10px",
  transition: "all 0.2s ease-in-out",
  height: "64px !important",

  "& .MuiTableCell-root": {
    padding: "0px !important",

    transition: "all 0.2s ease-in-out",
    borderTop: `1px solid ${open ? colors.primary : colors.gray10}`,
    borderBottom: `1px solid ${colors.gray10} !important`,
    "&:first-of-type": {
      borderLeft: `1px solid ${open ? colors.primary : colors.gray10}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: open ? "0px" : "10px",
      paddingLeft: "6px !important",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${open ? colors.primary : colors.gray10}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: open ? "0px" : "10px",
    },

    "& .MuiTypography-root": {
      lineHeight: "24px",
    },
  },

  "&:hover": {
    "&.MuiTableRow-root": {
      transform: open || disabled ? "none" : "scale(1.01)",
    },
    "& .MuiTableCell-root": {
      cursor: disabled ? "auto" : "pointer",
      borderTop: `1px solid ${
        open ? colors.primary : disabled ? colors.gray10 : colors.primary
      }`,
      borderBottom: `1px solid ${
        open || disabled ? colors.gray10 : colors.primary
      } !important`,

      "&:first-of-type": {
        borderLeft: `1px solid ${
          open ? colors.primary : disabled ? colors.gray10 : colors.primary
        }`,
      },
      "&:last-of-type": {
        borderRight: `1px solid ${
          open ? colors.primary : disabled ? colors.gray10 : colors.primary
        }`,
      },
    },
  },
}))

export const CollapsibleRow = styled(TableRow)<IRowProps>(({ open }) => ({
  "& > .MuiTableCell-root": {
    borderBottom: open
      ? `1px solid ${colors.primary} !important`
      : "none !important",
    borderRight: `1px solid ${colors.primary}`,
    borderLeft: `1px solid ${colors.primary}`,
    borderTop: "none",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",

    padding: "0px !important",

    "& .MuiCollapse-root": {
      margin: "16px",
    },
  },
}))

export const CellWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 6px;
`

export const DocPreviewWrapper = styled(Box)`
  min-width: 50%;
  width: 50%;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colors.gray2};
`
