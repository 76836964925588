import {
  TableCell,
  TableSortLabel,
  useMediaQuery,
  TableHead,
} from "@mui/material"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { CustomRow } from "./styled"

interface HeadCell {
  id: keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail
  label: string
  width: string
  subFields?: any
  align?: any
  isBig?: boolean
}

interface IProps {
  order: TOrder
  orderBy: string
  onHeaderClick: () => void
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail,
  ) => void
}

export const EnhancedTableHead = (props: IProps) => {
  const { order, orderBy, onHeaderClick, onRequestSort } = props
  const { t } = useTranslation()
  const isGreaterThan1420 = useMediaQuery("(min-width: 1420px)")

  const createSortHandler = useCallback(
    (property: keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
      },
    [onRequestSort],
  )

  const headCells: readonly HeadCell[] = [
    {
      id: "attachmentCount",
      label: t("source"),
      width: "5%",
      isBig: false,
    },
    {
      id: "createdAt",
      label: t("date"),
      width: "9%",
      isBig: false,
    },
    {
      id: "date",
      label: t("time"),
      width: "6%",
      isBig: false,
    },
    {
      id: "sender",
      label: t("from"),
      width: "16%",
      isBig: true,
    },
    {
      id: "caseId",
      label: t("caseNo"),
      width: "10%",
      isBig: true,
    },
    {
      id: "rubrum",
      label: t("subject"),
      width: "23%",
      isBig: true,
    },
    {
      id: "wegen",
      label: t("reasoning"),
      width: "25%",
      isBig: true,
    },
    {
      id: "workFlowId",
      label: t("workflow"),
      width: "6%",
      isBig: false,
    },
  ]

  const hideSortLabel = useCallback((id: string) => {
    return id === "workFlowId" || id === "attachmentCount" || id === "date"
  }, [])

  return (
    <TableHead onClick={onHeaderClick}>
      <CustomRow>
        {headCells?.map((headCell, index) => {
          return (
            <TableCell
              key={headCell.id}
              align={index === headCells.length - 1 ? "right" : "left"}
              style={{
                minWidth: isGreaterThan1420
                  ? headCell.width
                  : headCell.isBig
                  ? "200px"
                  : "90px",
                textAlign: headCell.align,
              }}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width}
              className={isGreaterThan1420 ? "word-break-all" : ""}
            >
              {hideSortLabel(headCell.id) ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
      </CustomRow>
    </TableHead>
  )
}
