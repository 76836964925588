import { Table, TableContainer, TableRow, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const EnhacedTableContainer = styled(TableContainer)({
  borderTop: 0,
  borderBottom: 0,
  padding: "0px 24px",

  overflowY: "initial",
})

export const EnhancedTable = styled(Table)(() => ({
  borderCollapse: "separate",

  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      height: "auto",
    },
  },

  "& > .MuiTableFooter-root": {
    "& .MuiTableCell-root": {
      backgroundColor: colors.secondary,
      border: `1px solid ${colors.gray13}`,
      borderRadius: "10px",
    },
  },
}))

export const EmptyRow = styled(TableRow)({
  height: "16px",

  "& .MuiTableCell-root": {
    padding: "0px !important",
    borderBottom: "0px !important",
    borderTop: "0px !important",
  },
})

export const NoDataRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    padding: "16x !important",
    borderBottom: "0px !important",
    borderTop: "0px !important",

    paddingBottom: "24px !important",
  },
})
