import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { CustomDialog, CustomDialogContent } from "./styled"
import { Close } from "@mui/icons-material"
import { DummyDataView } from "./DummyDataView"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import { useToast } from "../../../../../../contexts"
import { useState } from "react"

interface IProps {
  open: boolean
  data: any
  onUpdateFields: () => void
  onClose: () => void
}

export const WordTemplateModal = (props: IProps) => {
  const { open, data, onUpdateFields, onClose } = props
  const { t } = useTranslation()
  const toast = useToast()

  const [showPreview, setShowPreview] = useState(true)

  const handleChange = (newFieldMap: Record<string, any>) => {
    data.setChangedFieldMap(newFieldMap)
  }

  return (
    <CustomDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {t("editTemplate")}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <CustomDialogContent>
        <Box
          sx={{
            width: showPreview ? "65%" : "100%",
            overflow: "auto",
            maxHeight: "100%",
          }}
        >
          <DummyDataView
            key={data.document.uri}
            templateFields={data.templateFields}
            model={data.dummyFields}
            fieldMap={data.changedFieldMap}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            handleChange={handleChange}
          />
        </Box>
        {showPreview && (
          <DocViewer
            className="doc-viewer-disable-scroll"
            style={{
              height: "-webkit-fill-available",
              width: "35%",
            }}
            documents={data.document}
            pluginRenderers={DocViewerRenderers}
          />
        )}
      </CustomDialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            onUpdateFields()
            toast.show(t("changesSaved"), "success")
            onClose()
          }}
        >
          {t("saveChanges")}
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}
